import {
  FormControl,
  TextField,
  DialogContent,
  DialogActions,
} from "@mui/material";

import axios from "../../axios-config";
import { useState } from "react";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import { string, z } from "zod";
import { useForm, Controller } from "react-hook-form";

const InviteUserForm = ({ onInvitationSent }) => {
  const { t, i18n } = useTranslation();

  const [isProcessing, setIsProcessing] = useState(false);
  const schema = z.object({
    inviteeEmail: string().email({ message: "Required" }),
  });

  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    formState: { isSubmitting, isDirty, isValid, errors },
  } = useForm({ resolver: zodResolver(schema) });

  const handleSendInvitation = async (data) => {
    try {
      setIsProcessing(true);
      const resp = await axios.put("/organizations/inviteUser", data);
      if (resp.status === 200 || resp.status === 201) {
        enqueueSnackbar("Invitation sent!", { variant: "success" });
        onInvitationSent();
      }
      setIsProcessing(false);
    } catch (err) {
      // Form Validation Error
      if (err.response?.data?.data) {
        if (err.response.data.data[0].msg) {
          enqueueSnackbar(err.response.data.data[0].msg, { variant: "error" });
        }
      }

      // Default message
      if (err.response?.data?.message) {
        enqueueSnackbar(err.response.data.message, { variant: "error" });
      }
      setIsProcessing(false);
    }
  };

  const { enqueueSnackbar } = useSnackbar();
  // const onInput = ({target:{value}}) => setValue(value),

  return (
    <>
      <form onSubmit={handleSubmit(handleSendInvitation)}>
        <DialogContent>
          <FormControl fullWidth margin="normal" variant="filled">
            <Controller
              name="inviteeEmail"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <TextField
                  {...field}
                  // value={register.email}
                  // onChange={onInput}
                  label="Email"
                  id="inviteeEmail"
                  variant="filled"
                  size="small"
                  type={"email"}
                  placeholder={"name@company.com"}
                  required
                  error={!!errors.email}
                  helperText={t(errors.email?.message)}
                />
              )}
            />
          </FormControl>
        </DialogContent>
        <DialogActions variant={"outlined"} sx={{ px: "24px", pb: "24px" }}>
          <LoadingButton
            fullWidth
            type="submit"
            loading={isProcessing}
            variant="contained"
          >
            <span>{t("Send")}</span>
          </LoadingButton>
        </DialogActions>
      </form>
    </>
  );
};

export default InviteUserForm;

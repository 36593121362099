import axios from "axios-config";
import ConsoleHelper from "utils/ConsoleHelper";

export const downloadAllInvoices = async ({ selectedInvoices }) => {
  if (!selectedInvoices || !selectedInvoices.length) {
    throw new Error("Missing required parameters");
  }

  const response = await axios.post(
    `/invoices/download/all`,
    { invoiceIds: selectedInvoices },
    { responseType: "blob" }
  );

  return response.data;
};

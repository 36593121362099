import * as React from "react";
import { useSnackbar } from "notistack";
import axios from "../../axios-config";

import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Checkbox,
  IconButton,
  Typography,
  Stack,
  Divider,
  Popover,
  Button,
  ButtonGroup,
  circularProgressClasses,
} from "@mui/material";
import { styled } from "@mui/system";
import BaseItemList from "./BaseItemList";
// import EditServiceDialog from './Dialogs/EditServiceDialog';
import { useSelector } from "react-redux";

//Icons
import PlusIcon from "@mui/icons-material/AddRounded";
import MinusIcon from "@mui/icons-material/RemoveRounded";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import HandymanOutlinedIcon from "@mui/icons-material/HandymanOutlined";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
import UTurnLeftIcon from "@mui/icons-material/UTurnLeft";

import { useTranslation } from "react-i18next";
import { useTheme } from "@emotion/react";
import { red } from "@mui/material/colors";
import { formatCurrency } from "../../utils/currency";

const ServiceItemListShort = ({
  service,
  onServiceDelete,
  onAccept,
  setServices,
}) => {
  let dayjs = require("dayjs");
  const currency = useSelector((state) => state.auth.currency);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [mouseHover, setMouseHover] = React.useState(false);
  const [editDialog, setEditDialog] = React.useState(false);

  const serviceUpdatedAt = dayjs(service.updatedAt).format("DD.MMM.YYYY HH:mm");

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const InStock = () => {
    if (service.stock === true) return <Box>👍 IN STOCK</Box>;
    if (service.stock === false) return <Box>❌ OUT OF STOCK</Box>;
    return null;
  };

  const handleAcceptService = async (event) => {
    event.stopPropagation();

    try {
      const response = await axios.patch(
        `/repair-orders/services/${service.id}/accept`
      );
      onAccept(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleDeclineService = async (event) => {
    event.stopPropagation();

    try {
      const response = await axios.patch(
        `/repair-orders/services/${service.id}/decline`
      );
      onAccept(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleRemoveAcceptance = async (event) => {
    event.stopPropagation();
    try {
      const response = await axios.patch(
        `/repair-orders/services/${service.id}/removeAcceptance`
      );
      onAccept(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  const ServiceAcceptOrDeclined = () => {
    if (service.isAccepted)
      return (
        <Box>
          {mouseHover ? (
            <IconButton
              onClick={handleRemoveAcceptance}
              size="small"
              color="red"
              aria-label="increase quanitity"
              component="span"
              sx={{ fontSize: "15px", m: 0 }}
            >
              <CloseOutlinedIcon sx={{ fontSize: "15px", m: 0 }} />
            </IconButton>
          ) : null}{" "}
          Accepted on {serviceUpdatedAt}
        </Box>
      );
    if (service.isAccepted === false)
      return (
        <Box>
          {" "}
          {mouseHover ? (
            <IconButton
              onClick={handleRemoveAcceptance}
              size="small"
              color="red"
              aria-label="increase quanitity"
              component="span"
              sx={{ fontSize: "15px", m: 0 }}
            >
              <CloseOutlinedIcon sx={{ fontSize: "15px", m: 0 }} />
            </IconButton>
          ) : null}{" "}
          Declined on {serviceUpdatedAt}
        </Box>
      );

    return (
      <Stack direction={"row"} spacing={1}>
        <Button
          size="small"
          color="green"
          variant="outlined"
          onClick={handleAcceptService}
        >
          Accept
        </Button>
        <Button
          size="small"
          color="red"
          variant="outlined"
          onClick={handleDeclineService}
        >
          {" "}
          Decline{" "}
        </Button>
      </Stack>
    );
  };

  const handleDelete = async (event) => {
    event.stopPropagation();
    try {
      const response = await axios.delete(
        `/repair-orders/services/${service.id}`
      );
      if (response.status == 202 || response.status == 200) {
        enqueueSnackbar(`Service order deleted succesfully!`, {
          variant: "success",
        });
        onServiceDelete(service);
      }
    } catch (err) {
      console.log(err);
      enqueueSnackbar(`Something went wrong!`, { variant: "error" });
    }
  };

  const serviceAcceptanceColor = () => {
    if (service.isAccepted === true) {
      return "lightGreen.main";
    }
    if (service.isAccepted === false) {
      return "lightRed.main";
    }
    return null;
  };

  const borderAcceptanceColor = () => {
    if (service.isAccepted === true) {
      return "#6DA085";
    }
    if (service.isAccepted === false) {
      return "#DF8989";
    }
    return "#a5b4c9";
  };
  const serviceAcceptanceIcon = () => {
    if (service.isAccepted === true) {
      return (
        <CheckCircleOutlineIcon sx={{ color: "#00a63c", fontSize: "18px" }} />
      );
    }
    if (service.isAccepted === false) {
      return <HighlightOffIcon sx={{ color: "red.main", fontSize: "18px" }} />;
    }
    return (
      <CircleOutlinedIcon sx={{ color: "darkGrey.main", fontSize: "18px" }} />
    );
  };

  const openServiceEditDialog = (event) => {
    event.stopPropagation();
    setEditDialog(true);
  };

  const closeServiceEditDialog = () => {
    setEditDialog(false);
  };

  const handleServiceEdit = (newServices) => {
    setServices(newServices);
  };

  if (service.refundedServiceId != null) {
    return (
      <RefundedServiceShort
        service={service}
        currency={currency}
        handleDelete={handleDelete}
      />
    );
  }

  return (
    <>
      <BaseItemList
        sx={{
          // maxWdith: '150px',
          // minWidth:'150px',
          // overflow:'hidden',
          // width:'99%',
          py: "5px",
          border: "solid 1px",
          mb: "5px",
          borderColor: borderAcceptanceColor(),
          "&:hover": {
            border: "solid 1px",
            borderColor: "black",
            cursor: "pointer",
          },
        }}
        onMouseEnter={() => setMouseHover(true)}
        onMouseLeave={() => setMouseHover(false)}
        onClick={openServiceEditDialog}
        // itemIcon={<HandymanOutlinedIcon color='darkGrey' sx={{ fontSize: '20px' }} />}
        backgroundColor={serviceAcceptanceColor()}
        mainStack={
          <>
            {serviceAcceptanceIcon()}
            <Box flexGrow={1} overflow={"hidden"} maxHeight={"50px"}>
              <Box sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                <Typography fontSize={"0.875rem"} noWrap>
                  {service.name}
                </Typography>
              </Box>
            </Box>
            {/* {service.discount > 0 ? <Typography
                    sx={{ cursor: 'default' }}
                    aria-owns={open ? 'mouse-over-popover' : undefined}
                    aria-haspopup="true"
                    onMouseEnter={handlePopoverOpen}
                    onMouseLeave={handlePopoverClose} color='darkGrey.main'>
                    {parseFloat(service.discount) > 0.00 ? '%' : ''}
                </Typography> : null} */}
            {/* <Typography fontSize={'0.8rem'} px={1}>x{service.quantity}</Typography> */}

            <Typography fontWeight={500} variant="body2">
              {formatCurrency(
                service.unitPrice * service.quantity - service.discount,
                currency
              )}
            </Typography>
          </>
        }
        footerStack={
          <>
            {/* <ServiceAcceptOrDeclined /> */}
            {/* <InStock /> */}
            {/* <Box>
                    {service.unitPrice * service.quantity - service.discount == service.unitPrice ? null : service.unitPrice}
                </Box>
                <Box>
                    {service.quantity > 1 ? `x${service.quantity}` : null}
                </Box>
                <Box>
                    {service.discount > 0 ? `-${service.discount}` : null}
                </Box> */}
          </>
        }
      />

      {/* <EditServiceDialog service={service} dialogOpen={editDialog} handleDialogClose={closeServiceEditDialog} onEdit={handleServiceEdit} /> */}
    </>
  );
};

const RefundedServiceShort = ({ service, currency, handleDelete }) => {
  const theme = useTheme();

  const { t } = useTranslation();
  // let dayjs = require('dayjs')
  // const currency = useSelector((state) => state.auth.currency);
  // const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [mouseHover, setMouseHover] = React.useState(false);
  // const [editDialog, setEditDialog] = React.useState(false);

  // const serviceUpdatedAt = dayjs(service.updatedAt).format('DD.MMM.YYYY HH:mm');

  const [anchorEl, setAnchorEl] = React.useState(null);

  // return <Box>test</Box>
  if (!service) {
    return null;
  }
  return (
    <BaseItemList
      sx={{
        py: "5px",
        border: "solid 1px",
        borderColor: theme.palette.red.main,
        backgroundColor: red[50],
        "&:hover": {
          border: "solid 1px",
          borderColor: "black",
          cursor: "pointer",
        },
      }}
      // onMouseEnter={() => setMouseHover(true)}
      // onMouseLeave={() => setMouseHover(false)}
      // onClick={openServiceEditDialog}
      itemIcon={
        <UTurnLeftIcon sx={{ transform: "rotate(-90deg)", fontSize: "20px" }} />
      }
      mainStack={
        <>
          <Box flexGrow={1} overflow={"hidden"} maxHeight={"50px"}>
            <Box sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>
              <Typography fontSize={"0.875rem"} noWrap>
                {service.name}
              </Typography>
            </Box>
          </Box>
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <Typography fontWeight={500} variant="body2" noWrap>
              {" "}
              {formatCurrency(
                service.unitPrice * service.quantity - service.discount,
                currency
              )}
            </Typography>
          </Stack>
        </>
      }
      footerStack={<>{/* <Box>refunded on</Box> */}</>}
    />
  );
};

export default ServiceItemListShort;

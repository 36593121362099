import * as React from "react";
import { Box, display } from "@mui/system";
import {
  Typography,
  Button,
  Stack,
  Grid,
  TextField,
  Container,
} from "@mui/material";
import axios from "../../axios-config";
import { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";

import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import LanguageButton from "../../components/LanguageButton/LanguageButton";
import { authActions } from "../../store/auth";
import { useDispatch } from "react-redux";

const UserSettingsPage = ({ changeColor }) => {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const [user, setUser] = useState(null);
  const [validated, setValidated] = useState(false);

  // const [editedUser, setEditedUser] = useState({ name: '' });
  let [language, setLanguage] = useState(i18n.language);
  // let [editLanguage, setEditLanguage] = useState('');

  const [loading, setLoading] = useState(true);

  document.body.style.backgroundColor = "white";
  useEffect(() => {
    let isMounted = true;
    setLoading(true);

    const fetchUser = async () => {
      try {
        const response = await axios.get(`/users`);
        const successfuly = response.status === 200 || response.status === 201;
        if (successfuly && isMounted) {
          setUser(response.data);
          dispatch(authActions.updateAuthUserName(response.data.name));
          localStorage.setItem("userName", response.data.name);
        }
        setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };

    fetchUser();

    return () => {
      isMounted = false;
    };
  }, []);

  const handleSave = async () => {
    try {
      if (i18n.language !== language) {
        i18n.changeLanguage(language);
      }
      let userData = { ...user };
      userData.language = language;

      const response = await axios.patch(`/users`, userData);
      if (response.status === 200 || response.status === 201) {
        dispatch(authActions.updateAuthUserName(userData.name));
        localStorage.setItem("userName", userData.name);
        enqueueSnackbar("Settings updated succesfully", { variant: "success" });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const hanldeUserName = (e) => {
    const name = e.target.value.substring(0, 25);
    setUser((prevState) => ({ ...prevState, name: name }));
  };
  const validate = () => {
    if (!user) {
      setValidated(false);
      return;
    }
    if (user.name.length <= 0) {
      setValidated(false);
      return;
    }
    if (!language) {
      setValidated(false);
      return;
    }
    setValidated(true);
  };
  useEffect(() => {
    validate();
  }, [user, language]);

  if (loading)
    return (
      <>
        <Box py={3}>
          <div className="headerPush"></div>
          <Typography>Loading...</Typography>
        </Box>
      </>
    );

  if (!loading && !user) {
    return (
      <>
        <Box py={3}>
          <div className="headerPush"></div>
          <Typography>{t("Something went wrong")}</Typography>
        </Box>
      </>
    );
  }

  return (
    <Container>
      <Grid container>
        <Grid item xs={12}>
          <Box py={3}>
            <div className="headerPush"></div>
            <Box px={2}>
              <Typography variant="h3" mb={2}>
                {t("User settings")}
              </Typography>
              {/* <Typography variant='h6' mb={2}>General</Typography> */}
              <Box mb={"15px"}>
                <Typography mb={2}>{t("App language")}</Typography>

                <LanguageButton language={language} setLanguage={setLanguage} />
              </Box>
              <Box mb={"15px"}>
                <Stack spacing={2} alignItems={"flex-start"}>
                  <TextField
                    label={t("Name")}
                    value={user.name}
                    onChange={hanldeUserName}
                  />
                  <TextField label="Email" disabled value={user.email} />
                  <Button
                    variant={"contained"}
                    disabled={!validated}
                    onClick={handleSave}
                  >
                    {t("Save")}
                  </Button>
                </Stack>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default UserSettingsPage;

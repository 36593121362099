import { FormControl, CardContent, TextField, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";

import axios from "../../../axios-config";
import { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import { string, z } from "zod";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

const AcceptOrganizationInvitation = (props) => {
  const { t, i18n } = useTranslation();

  const [isProcessing, setIsProcessing] = useState(false);
  const [accountCreatedSuccesfully, setAccountCreatedSuccesfully] =
    useState(false);
  const [emailCreated, setEmailCreated] = useState(null);
  const schema = z.object({
    password: string().min(5, { message: "At least 5 characters." }),
    name: string().min(1, { message: "Required" }),
  });

  const { token } = useParams(); // Get the token from the URL parameters
  const [tokenData, setTokenData] = useState(null);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    formState: { isSubmitting, isDirty, isValid, errors },
  } = useForm({ resolver: zodResolver(schema) });

  const { enqueueSnackbar } = useSnackbar();
  // const onInput = ({target:{value}}) => setValue(value),

  useEffect(() => {
    async function fetchData() {
      try {
        // Replace this URL with your API endpoint and adjust the fetch options if necessary
        const response = await axios.get(
          `organizations/get-token-data/${token}`
        );

        if (response.status === 200) {
          setTokenData(response.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, [token]);

  if (loading) {
    return <Typography>Loading...</Typography>;
  }
  if (!tokenData) {
    return <Typography py={5}>No invitation was found..</Typography>;
  }

  const handleRegister = async (data) => {
    try {
      setIsProcessing(true);
      const resp = await axios.put(`/auth/signup/${token}`, data);

      if (resp.status === 200 || resp.status === 201) {
        // setEmailCreated(data.email);
        // setAccountCreatedSuccesfully(true);
        enqueueSnackbar(t("Account created successfully!"), {
          variant: "success",
        });
        navigate(`/auth/login`);

        reset();
      }
      setIsProcessing(false);
    } catch (err) {
      // Form Validation Error
      if (err.response.data.data) {
        if (err.response.data.data[0].msg) {
          enqueueSnackbar(err.response.data.data[0].msg, { variant: "error" });
        }
      }

      // Default message
      if (err.response.data.message) {
        enqueueSnackbar(err.response.data.message, { variant: "error" });
      }
      setIsProcessing(false);
    }
  };

  return (
    <CardContent sx={{ minWidth: "xs", maxWidth: "xs" }}>
      {accountCreatedSuccesfully ? (
        <>
          <Stack alignItems={"center"}>
            <Typography align="center" variant="h5" mb={2}>
              <span>{t("Account created successfully!")}</span>
            </Typography>
            <Typography align="center" mb={2}>
              <span>{t("An email has been sent to")} </span>
              <span>
                <strong>{emailCreated}</strong>.{" "}
              </span>
              <span>
                {t("Confirm it and you'll be ready to start working.")}
              </span>
            </Typography>
            {/* <Typography align="center" mb={2}>
                Didn't see an email? Resend it!
              </Typography> */}
          </Stack>
        </>
      ) : (
        <>
          {/* <Typography variant="h3" >
            Your most productive work starts right here
          </Typography> */}

          <Typography variant="h6" mb={2}>
            {t("invitationMessage", {
              user: tokenData?.user?.name,
              orgName: tokenData?.organization?.name,
            })}
          </Typography>
          <Typography variant="body">
            {t(
              'To accept the invitation simply fill out the form below and click "Create My Account".'
            )}
          </Typography>
          <form onSubmit={handleSubmit(handleRegister)}>
            <FormControl fullWidth margin="normal" variant="filled">
              <Controller
                name="name"
                control={control}
                defaultValue={""}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={t("Name")}
                    id="name"
                    variant="filled"
                    size="small"
                    type={"name"}
                    required
                    error={!!errors.name}
                  />
                )}
              />
            </FormControl>

            <FormControl fullWidth margin="normal" variant="filled">
              <Controller
                name="password"
                control={control}
                defaultValue={""}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={t("Password")}
                    id="password"
                    variant="filled"
                    size="small"
                    type="password"
                    required
                    error={!!errors.password}
                    helperText={t(errors.password?.message)}
                  />
                )}
              />
            </FormControl>

            <LoadingButton
              fullWidth
              type="submit"
              loading={isProcessing}
              variant="contained"
            >
              <span>{t("Create my account")}</span>
            </LoadingButton>
          </form>
        </>
      )}
    </CardContent>
  );
};

export default AcceptOrganizationInvitation;

import { useEffect, useState, useCallback } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  InputAdornment,
  Stack,
} from "@mui/material";

// import debounce from "lodash/debounce";
import axios from "../../axios-config";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import { getCurrencySymbol } from "../../utils/currency";
import DeviceSearchField from "../../components/DeviceSearchField/DeviceSearchField";

function AddService({
  addServiceOpen,
  handleAddServiceClose,
  handleAddNewService,
  handleDeviceClick,
  handleDelete,
  deviceDataSelected,
}) {
  const [serviceName, setServiceName] = useState("");
  const [price, setPrice] = useState("");
  // const [stock, setStock] = useState("Not Required");
  // const [model, setModel] = useState("");
  // const [matchedResults, setMachedResults] = useState([]);
  // const [anchorEl, setAnchorEl] = useState(null);
  // const [deviceInput, setDeviceInput] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [deviceData, setDeviceData] = useState(null);

  // const debouncedSave = useCallback(
  //   debounce((nextValue) => setModel(nextValue), 350),
  //   [] // will be created only once initially
  // );
  const currency = useSelector((state) => state.auth.currency);

  // const handleDeviceChange = (val) => {
  //   const nextValue = val;
  //   setDeviceInput(nextValue);
  //   debouncedSave(nextValue);
  // };

  const handleSubmit = async () => {
    const service = {
      name: serviceName,
      price: price,
      deviceCatalogId: deviceData?.id,
    };

    try {
      const response = await axios.post("/service-catalogs", service);
      if (response.status !== 200) {
        throw response.status;
      }
      if (response.status === 200) {
        handleAddNewService(response.data);
        handleClose();
      }
    } catch (err) {
      enqueueSnackbar(err.response.data.message || `Something went wrong!`, {
        variant: "error",
      });
      console.log(err);
    }
  };
  const handlePriceChange = (e) => {
    let inputValue = e.target.value;
    // Check if the input is a valid number format
    const isValidNumber = /^[\d.]*$/.test(inputValue);
    if (!isValidNumber) return;

    // Check if the input has no more than two decimal places
    const isValidDecimal = /^(\d+(\.\d{0,2})?)?$/.test(inputValue);
    if (!isValidDecimal) return;

    inputValue = inputValue.replace(/,/g, "");
    setPrice(inputValue);
  };

  useEffect(() => {
    if (!deviceDataSelected) {
      setDeviceData(null);
      return;
    }
    setDeviceData(deviceDataSelected);
  }, [deviceDataSelected]);

  const handleDeviceSelect = (device) => {
    setDeviceData(device);
  };

  const handleClose = () => {
    setServiceName("");
    setPrice("");
    if (!deviceDataSelected) {
      setDeviceData(null);
    }
    handleAddServiceClose();
  };

  return (
    <Dialog
      open={addServiceOpen}
      onClose={(e) => {
        handleClose();
      }}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Add a new service</DialogTitle>
      <DialogContent>
        <Stack spacing={1}>
          <TextField
            multiline
            maxRows={4}
            minRows={2}
            size="small"
            variant="outlined"
            placeholder="Service Name"
            value={serviceName}
            onChange={(e) => setServiceName(e.target.value)}
            inputProps={{ maxLength: 100 }} // Limit to 50 characters, adjust as needed
            helperText={`${serviceName.length}/${100}`}
          />

          <TextField
            size="small"
            variant="outlined"
            placeholder="Price"
            value={price}
            onChange={handlePriceChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {getCurrencySymbol(currency)}
                </InputAdornment>
              ),
            }}
          />

          <DeviceSearchField
            onChange={handleDeviceSelect}
            selectedDeviceData={deviceDataSelected}
          />
        </Stack>
      </DialogContent>
      <DialogActions sx={{ bottom: "0" }}>
        <Button onClick={handleAddServiceClose}>Cancel</Button>
        <Button onClick={handleSubmit}>Add Service</Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddService;

import { createContext, useContext, useState } from "react";
import { useStore } from "react-redux";
const UserPermissionsContext = createContext();

const UserPermissionsContextProvider = ({ children }) => {
  const auth = useStore().getState().auth;
  const [userPermissions, setUserPermissions] = useState({
    invoice: false,
    softLockedUser:
      process.env.REACT_APP_SOFT_LOCK &&
      auth?.userId == process.env.REACT_APP_SOFT_LOCK_USER
        ? true
        : false,
  });
  //   const store = useStore();

  // useEffect(() => {
  //     const fetchUserPermissions = async () => {
  //         try {
  //             const response = await axios.get('/api/user/permissions', {
  //                 headers: {
  //                     Authorization: `Bearer ${token}`,
  //                 },
  //             });
  //             setUserPermissions(response.data);
  //         } catch (error) {
  //             console.error('Failed to fetch user permissions:', error);
  //         }
  //     };

  //     fetchUserPermissions();
  // }, [token]);

  return (
    <UserPermissionsContext.Provider value={userPermissions}>
      {children}
    </UserPermissionsContext.Provider>
  );
};

const useUserPermissions = () => {
  return useContext(UserPermissionsContext);
};

export { UserPermissionsContextProvider, useUserPermissions };

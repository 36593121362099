import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enTranslation from "locales/en";
import esTranslation from "locales/es";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)

const validation = {
  Required: "Campo requerido",
  "At least 5 characters.": "Al menos 5 caracteres.",
};
const loginPage = {
  "Log in": "Iniciar sesión",
  "Sign up": "Registrate",
  "Don't have an account?": "¿No tienes una cuenta?",
  "Already have an account?": "¿Ya tienes una cuenta?",
  "Confirm it and you'll be ready to start working.":
    "Confírmalo y estarás listo para empezar a trabajar.",
  "An email has been sent to": "Se ha enviado un correo electrónico a",
  "Verify your email": "Verifique su correo electrónico",
  "Create my account": "Crear mi cuenta",
  "Verification successfully!": "¡Verificación con éxito!",
  "Verification failed!": "¡Fallo en la verificación!",
  'To accept the invitation simply fill out the form below and click "Create My Account".':
    'Para aceptar la invitación, simplemente completa el siguiente formulario y haz clic en "Crear mi cuenta".',
  "Account created successfully!": "¡Cuenta creada con éxito!",
  "Invitation code": "Código de invitación",
  "I accept the": "Acepto los",
  "Terms and Conditions": "Términos y Condiciones",
  "Organization name": "Nombre de la organización",
  "or Register": "o Regístrate",
  "forgot password?": "¿olvidaste la contraseña?",
  "Account not found!": "¡Cuenta no encontrada!",

  "Password Recovery": "Recuperación de Contraseña",
  "Please enter your email address. We will send you a code to verify your identity.":
    "Por favor ingrese su dirección de correo electrónico. Le enviaremos un código para verificar su identidad.",
  "Security Check": "Verificación de seguridad",
  Continue: "Continuar",
  "Go back": "Volver atrás",

  "Please enter the verification code sent to:":
    "Por favor ingrese el código de verificación enviado a:",
  "Resend code": "Reenviar código",
  "Update Your Password": "Actualiza tu contraseña",
  "Please enter your new password. It should be at least 8 characters long.":
    "Por favor ingrese su nueva contraseña. Debe tener al menos 8 caracteres de largo.",
  "New Password": "Nueva contraseña",
  "Update Password": "Actualizar contraseña",
  "Password updated successfully!": "¡Contraseña actualizada con éxito!",
  "Password should be at least 8 characters long":
    "La contraseña debe tener al menos 8 caracteres de largo",
  "You can now log in with your new password.":
    "Ahora puede iniciar sesión con su nueva contraseña.",

  "If you don't see the email, please check your spam folder.":
    "Si no ve el correo electrónico, revise su carpeta de spam.",
};

const inventory = {
  Inventory: "Inventario",
  "Inventory Management": "Gestión de inventario",
  "All Products": "Todos los productos",
  "Warehouse Inventory": "Inventario de almacén",
  "Overview of all products": "Visión general de todos los productos",
  "Manage warehouse stock": "Gestionar el stock del almacén",
  Photo: "Foto",
  "Location in Warehouse": "Ubicación en el almacén",
  Barcode: "Código de barras",
  Available: "Disponible",
  "Edit quantity Available": "Editar cantidad disponible",
  "No products found": "No se encontraron productos",
  "Manage locations": "Gestionar ubicaciones",
  "New product": "Nuevo producto",
  Set: "Establecer",
  "Initial quantity in stock": "Cantidad inicial en stock",
  Location: "Ubicación",
  "Product image": "Imagen del producto",
  "Add new product": "Añadir nuevo producto",
  Warehouse: "Almacén",
  Cost: "Costo",
  Description: "Descripción",
  "Edit product": "Editar producto",
  "Product added successfully!": "¡Producto añadido con éxito!",
  "Product deleted successfully!": "¡Producto eliminado con éxito!",
  "Empty stock before deleting the product":
    "Vacíe el stock antes de eliminar el producto",
  "Delete product": "Eliminar producto",
  "Are you sure you want to delete this product?":
    "¿Estás seguro de que quieres eliminar este producto?",
};

const errorsAndAlerts = {
  "Too many requests! Try again later.":
    "¡Demasiadas solicitudes! Inténtalo de nuevo más tarde.",
  "The code you entered is expired or not valid. Please try again or resend the code.":
    "El código que ingresó ha caducado o no es válido. Inténtalo de nuevo o vuelve a enviar el código.",
  "A new code has been sent to your email.":
    "Se ha enviado un nuevo código a su correo electrónico.",
};
const statuses = {
  "In Queue": "En Cola",
  "In Repair": "En Reparacion",
  "Ready For Pick Up": "Listo Para Recoger",
  Request: "Solicitud",
  Diagnose: "Diagnostico",
  "Notify Customer": "Notifica El Cliente",
  "Waiting For Parts": "Esperando Piezas",
  Repaired: "Reparado",
  "In Delivery": "En Reparto",
  Declined: "Rechazado",
  Irreparable: "Irreparable",
  Irreprodicible: "Irreproducible",
  Warranty: "Garantía",
  "Received From Partner": "Recibido Del Socio",
  Delivered: "Entregado",
  "Delivered To Customer": "Entregado A Cliente",
  "Quality Assurance": "Prueba De Calidad",
  "On Hold": "En Espera",
  "Order Parts": "Pedir Piezas",
  "Show all": "Mostrar todo",
  "Show conditional": "Mostrar condicional",
  "Order outsourced to": "Pedido externalizado a",
  "and is pending approval.": "y está pendiente de aprobación",
  "Repair completed with the resolution":
    "Reparación completado con la resolución",
  "Delivery date": "Fecha de entrega",
  Done: "Hecho",
  "In Progress": "En Curso",
  New: "Nuevo",
  Waiting: "Esperando",
};

const dateTime = {
  hour: "hora",
  hours: "horas",
  day: "dia",
  days: "dias",
  "Last 90 days": "Ultimos 90 días",
  "Last 30 days": "Últimos 30 días",
  "This year": "Este año",
  "Last year": "El año pasado",
  "This month": "Este mes",
  "Last month": "El mes pasado",
  "All time": "Todo el tiempo",
  "Days until now": "Días hasta ahora",
};
const general = {
  Device: "Aparato",
  Resolution: "Resolución",
  "Order Details": "Detalles del Pedido",
  Malfunction: "Defecto",
  "Last Comment": "Ultimo Comentario",
  Status: "Estado",
  Statuses: "Estados",
  Services: "Servicios",
  Emergency: "Urgencia",
  "Entry date": "Fecha de entrada",
  "Last update": "Ultima actualization",
  daysAgo: "Hace {{count}} días",
  Back: "Atras",
  "New reminder": "Nuevo recordatorio",
  Reminder: "Recordatorio",
  "Reminder set successfully": "Recordatorio establecido con éxito",
  "Print ticket": "Imprimir ticket",
  "Copy order data": "Copiar pedido",
  "Copied to clipboard": "Copiado",
  Password: "Contraseña",
  password: "contraseña",
  note: "nota",
  malfunction: "defecto",
  Pattern: "Patrón",
  Note: "Nota",
  Customer: "Cliente",
  "Search or add a customer": "Buscar o añadir un cliente",
  "Edit customer": "Editar cliente",
  "Estimated services": "Estimación de servicios",
  "Outsource order": "Externalizar pedido",
  "Outsource to partner": "Externalizar al socio",
  "Select a partner": "Seleccione un socio",
  Comments: "Comentarios",
  General: "General",
  "View logs": "Ver registros",
  Private: "Privado",
  Public: "Público",
  "No comments": "No hay comentarios",
  "Leave a comment here...": "Deja un comentario aqui",
  Category: "Categoria",
  Model: "Modelo",
  Brand: "Marca",
  "New customer": "Nuevo cliente",
  Name: "Nombre",
  Phone: "Teléfono",
  "Additional actions": "Acciones adicionales",
  "Go to order": "Ir al pedido",
  "Add entry": "Añadir entrada",
  Close: "Cerrar",
  "If you want a partner to handle the order, you can outsource it to them.":
    "Si quieres externalizar y dejar el control a un socio. ",
  Sort: "Ordenar",
  "Status priority": "Prioridad de estados",
  Search: "Buscar",
  Partner: "Socio",
  Order: "Pedido",
  "Complete the repair by choosing a resolution":
    "Finalizar la reparación eligiendo una resolución",
  Date: "Fecha",
  Total: "Total",
  "Add Service": "Agregar servicio",
  Add: "Añadir",
  Cancel: "Cancelar",
  "Customer Edit": "Editar Cliente",
  "Filter partners": "Filtrar socios",
  Partners: "Socios",
  "Our organization": "Nuestra organización",
  "Set resolution": "Establecer resolución",
  "Resolution chosen": "Resolución elegida",
  "The resolutions are handled by partner.":
    "Las resoluciones son tramitadas por socio.",
  "Order outsourced to": "Pedido externalizado a",
  "A new version is available!": "¡Una nueva versión está disponible!",
  "Refresh to update it.": "Refrescar para actualizarlo",
  Refresh: "Refrescar",
  count: "contar",
  "Select all": "Selectar todo",
  Charts: "Gráficos",
  "orders selected": "pedidos seleccionados",
  "order selected": "pedido seleccionado",
  "Can't update multiple statuses": "No se pueden actualizar varios estados",
  "Change Status": "Cambiar Estado",
  "were updated": "fueron actualizados",
  orders: "pedidos",
  "No data matching your filters.":
    "No hay datos que coincidan con sus filtros.",
  Repairs: "Reparaciónes",
  Repair: "Reparación",
  "New Repair": "Nueva Reparación",
  Profit: "Beneficio",
  "My account": "Mi cuenta",
  "User settings": "Ajustes de usuario",
  "App language": "Idioma de la aplicación",
  "New Entry": "Nueva Entrada",
  Accept: "Acceptar",
  Decline: "Rechazar",
  "Legal name": "Nombre legal",
  "Dalvado's partner": "Socio de dalvado",
  "Organization plan": "Plan de organizacion",
  "Invoice history": "Historial de facturas",
  "No invoices": "Sin facturas",
  Invoice: "Factura",
  "Emergency updated!": "¡Urgencia actualizada!",
  "Create reminder": "Crear recordatorio",
  Hour: "Hora",
  "days left": "días restantes",
  Trial: "Período de prueba",
  Invoices: "Facturas",
  "Ticket Settings": "Ajustes de ticket",
  daysLeft: "quedan {{count}} días",
  Send: "Enviar",
  "Send Request": "Enviar Solicitud",
  "Confirm adding": "Confirmar añadiendo",
  confirmAddCategory: "¿Está seguro de que desea añadir la categoría {{name}}?",
  "Add a new category": "Añadir una nueva categoría",
  Support: "Centro de Soporte",
  "Service temporarily unavailable. Check back shortly.":
    "Servicio temporalmente no disponible. Vuelve en breve.",
  Delete: "Eliminar",
  "Warning!": "¡Atención!",
  "Adjust price": "Ajustar precio",
  Duplicate: "Duplicar",
  "Link to device": "Enlace al dispositivo",
  "Deselect services": "Deseleccionar servicios",
  Quote: "Presupuesto",
  "Quote from": "Presupuesto de",
  "Quote to": "Presupuesto para",
  Quotes: "Presupuestos",
  "Create quote": "Crear presupuesto",
  "No quotes": "Sin presupuestos",
  "It's a bit lonely here...": "Está un poco solitario aquí...",
  "Let's get to work! Add your first entry to fill this space.":
    "¡Vamos a trabajar! Añade tu primer entrada para llenar este espacio.",
};
const editRepair = {
  "What can my partner access and view?": "¿Qué puede ver y acceder mi socio?",
  "What will the partner access and view?": "¿A qué tendrá acceso el socio?",
  'When you outsource a repair to a partner organization, they gain temporary access to certain details. This access lasts until the order is marked as "Delivered" by your partner. Below is a detailed list of what your partner can do:':
    'Cuando externaliza una reparación a una organización asociada, esta gana acceso temporal a ciertos detalles. Este acceso dura hasta que el pedido sea marcado como "Entregado" por su socio. A continuación se muestra una lista detallada de lo que su socio puede hacer:',
  "Partner Access During Outsourcing":
    "Acceso del socio durante la externalización",
  "Estimate services": "Estimado de servicios",
  "Emergency level": "Nivel de urgencia",
  "My customer or any data of this": "Mi cliente o cualquier dato de este",
  "Services, Parts and Payment between me and my customer":
    "Servicios, piezas y pagos entre mi cliente y yo",
  "My private comments": "Mis comentarios privados",
  "New entry of the same problem or a possible warranty?":
    "¿Nueva entrada del mismo problema o una posible garantía?",
  "Follow up repair": "Seguimiento de reparación",
  "Repair parent": "Reparación principal",
  "Services received from Partner": "Servicios recibidos del socio",
  "Services offered to customer": "Servicios ofrecidos al cliente",
  "Here you manage the services offered by you to your customer. Your partner is not able to see or interact with these.":
    "Aquí administra los servicios que ofrece a su cliente. Su socio no puede ver ni interactuar con estos.",
  "Here you interact only with your parnter, accept service proposals as well as seeing how much you owe him.":
    "Aquí interactúa solo con su socio, acepta propuestas de servicio y ve cuánto le debe.",
  "Please note, your partner's access to these details terminates once the order is delivered. The only ability left is communication through comments and processing payments between you and your partner.":
    "Tenga en cuenta que el acceso de su socio a estos detalles termina una vez que se entrega el pedido. La única habilidad que queda es la comunicación a través de comentarios y el procesamiento de pagos entre usted y su socio.",
  "In the event of additional follow-up repairs (e.g. a warranty claim) or subsequent discussions, your partner will see the order as it was prior to delivery. Be sure to notify them of any changes that occur after delivery.":
    "En el caso de reparaciones de seguimiento adicionales (por ejemplo, una reclamación de garantía) o discusiones posteriores, su socio verá el pedido tal como estaba antes de la entrega. Asegúrese de notificarles cualquier cambio que ocurra después de la entrega.",
  "Logs before the repair being outsourced":
    "Registros antes de que la reparación sea externalizada",
  View: "Ver",
  Edit: "Editar",
  "Comments with partner": "Comentarios con el socio",
  "Private comments": "Comentarios privados",
  "level of attention": "nivel de atención",
  "device name": "nombre del dispositivo",
};

const services = {
  "Partner services": "Servicios del socio",
  "There are no services": "No hay servicios",
  "No services": "Sin servicios",
  service: "servicio",
  services: "servicios",
  selected: "seleccionado",
  "Add a new service to get started": "Añadir un nuevo servicio para empezar",
  "Service name": "Nombre del servicio",
  "Propose a service, will be calculated once accepted":
    "Proponer un servicio, se calculará una vez aceptado",
  Parts: "Piezas",
  Payments: "Pagamentos",
  "In stock": "En stock",
  Unkown: "Desconocido",
  "Out of stock": "Agotado",
  "Unit price": "Precio unitario",
  "Parts used on this order, will be calculated as an expense on charts":
    "Las piezas utilizadas en este pedido se calcularán como un gasto en los gráficos",
  "Add a payment received for this order":
    "Añadir un pago recibido por este pedido",
  Amount: "Cantidad",
  "Payment method": "Método de pago",
  Cash: "Efectivo",
  Card: "Tarjeta",
  Transfer: "Transferencia",
  Accept: "Acceptar",
  Decline: "Rechazar",
  "Total accepted": "Total acceptado",
  "Accepted services of delivered repairs":
    "Servicios aceptados de reparaciones entregadas",
  Emergencies: "Urgencias",
  "Unpaid services": "No pagados",
  "Due to partners": "Por socios",
  Service: "Servicio",
  Expenses: "Gastos",
  "Accepted from partner": "Aceptado del socio",
  Revenue: "Ingreso",
  "Avg. revenue": "Ingreso Promedio",
  "Pending payments": "Pagos Pendientes",
  Delivered: "Entregado",
  "Not Delivered": "No entregado",
  Delivery: "Entrega",
  Resolutions: "Resoluciones",
  "No resolution": "Sin resolución",
  "Load more": "Carga más",
  Filters: "Filtros",
  "There are no repairs": "No hay reparaciones",
  "Payment status": "Estado de pago",
  Partial: "Parcial",
  "Total services": "Servicios totales",
  Payment: "Pago",
  Blank: "Blanco",
  Overpaid: "Sobrepagado",
  "Part name": "Nombre pieza",
  Refund: "Devolución",
  Services: "Servicios",
  "Proposed services": "Servicios propuestos",
  "Partner proposed services": "Servicios propuestos por Socio",
  "Partner confirmed services": "Servicios confirmados por Socio",
  "Confirmed services": "Servicios confirmados",
  "Display Replacement": "Cambiar pantalla",
  "Filter devices": "Filtrar dispositivos",
  "Edit services": "Editar servicios",
  "Stop editing": "Dejar de editar",
  "Add services": "Añadir servicios",
  "Services catalog": "Catálogo de servicios",
  "Last update": "Última actualización",
  "Select a device": "Seleccionar un dispositivo",
  "Rows per page": "Filas por página",
  "Currently displaying services from": "Mostrando servicios de",
  "You can't edit this catalog.": "No puedes editar este catálogo.",
  "Catalog access": "Acceso al catálogo",
  "Select catalog": "Seleccionar catálogo",
  "Filter services": "Filtrar servicios",
  "Our Catalog": "Nuestro catálogo",
};
const serviceCatalog = {
  "Sharing permissions": "Permisos de compartición",
  "Operation type": "Tipo de operación",
  "Adjust by value +/-": "Ajustar por valor +/-",
  "Adjust by %": "Ajustar por %",
  "Add or substract from price.": "Añadir o restar del precio.",
  "Adjust procentually the price.": "Ajustar procentualmente el precio.",
  "Set the price for all the selected services.":
    "Establecer el precio para todos los servicios seleccionados.",
  "Set new value": "Establecer nuevo valor",
  "Round decimals.": "Redondear decimales.",
  "Duplicate services": "Duplicar servicios",
  duplicateServices_one:
    "¿Estas seguro de que quieres duplicar <strong>{{count}}</strong> servicio?",
  duplicateServices_other:
    "¿Estas seguro de que quieres duplicar  <strong>{{count}}</strong> servicios?",
  "Link services to device": "Enlazar servicios al dispositivo",
  linkServicesToDevice_one:
    "¿Estas seguro de que quieres enlazar <strong>{{count}} servicio </strong> al dispositivo?",
  linkServicesToDevice_other:
    "¿Estas seguro de que quieres enlazar <strong>{{count}} servicios </strong> al dispositivo?",
  deviceTooltip:
    "Los dispositivos proporcionados por <strong>dalvado</strong> son mantenidos por nuestro equipo. Los dispositivos pueden ser utilizados por su organización pero no pueden ser editados.",
  deviceProvidedByDalvado: "Dispositivo proporcionado por dalvado",
};

const notifications = {
  Notifications: "Notificaciones",
  "There are no notifications...": "No hay notificaciones...",
  "You wanted to be reminded about.": "Querías que te recordaran.",
  "Order reminder": "Recordatorio de pedido",
  "Service accepted": "Servicio aceptado",
  "Service declined": "Servicio rechazado",
  "accepted a service on": "ha acceptado un servicio de",
  "declined a service on": "ha rechazado un servicio de",
  "New comment from partner": "Nuevo comentario de socio",
  "New service": "Nuevo servicio",
  "added a new service": "agregó un nuevo servicio",
};

const invoices = {
  "Note: If the customer doesn't have tax information it will generate a simplified invoice.":
    "Nota: Si el cliente no tiene la indetification fiscal se va a generar una factura simplificada.",
  Generate: "Generar",
  "Mark as paid": "Marcar como pagado",
  "Mark unpaid": "Marcar como no pagado",
  Paid: "Pagado",
  Unpaid: "No es pagado",
  "Print selected invoice": "Imprimir factura seleccionada",
  "Delete invoice": "Borrar factura",
  "Simplified invoice": "Factura simplificada",
  Item: "Articulo",
  Tax: "Impuesto",
  Discount: "Descuento",
  "Add terms or note": "Agregar términos o nota",
  "Add payment info": "Agregar información de pago",
  "Bill to": "Facturar a",
  "Bill from": "Factura de",
  Price: "Precio",
  "Tax Included": "Impuesto Incluido",
  "Tax Excluded": "impuesto Excluido",
  "Total tax included": "Total impuesto incluido",
  Quantity: "Cantidad",
  Deposit: "Deposito",
  "Signature heading": "Titulo de firma",
  "Payment info": "Información de pago",
  "Terms or note": "Términos o nota",
  "Upload Logo": "Cargar Logo",
  "Quick invoice": "Factura rápida",
};
const printSettings = {
  "Print settings": "Ajustes de imprimir",
  "Edit the way you want to print your tickets":
    "Editar como quieres que imprimir el ticket",
  "80mm ticket": "Ticket de 80mm",
  "A4 split": "A4 dividido",
  "Customer data": "Datos de cliente",
  "Notes & Terms": "Terminos y notas",
  "Ticket note": " Nota del ticket",
  Title: "Titulo",
  Signature: "Firma",
  Estimated: "Estimado",
  "Save settings": "Guardar ajustes",
  "Thermal receipt": "Recibo térmico",
  "Full page": "Página completa",
};
const organization = {
  "Partner id": "ID de socio",
  "Invitation canceled.": "Invitación cancelada.",
  "Invitation sent successfully!": "¡Invitación enviada con éxito!",
  "Resend invitation": "Reenviar invitacíon",
  "Pending...": "Pendiente...",
  "Invite People": "invitar Personas",
  invitationMessage:
    "{{user}} te ha invitado a unirte a la organización {{orgName}}.",
  "Organization settings": "Ajustes de organización",
  Save: "Guardar",
  Members: "Miembros",
};

const resources = {
  en: {
    translation: {
      selectedService_one: "{{count}} service selected",
      selectedService_other: "{{count}} services selected",
      daysAgo: "{{count}} days ago",
      daysLeft: "{{count}} days left",
      invitationMessage:
        "{{user}} invited you to join the {{orgName}} organization.",
      repairParentConnectContext:
        "Connect this repair with a prior one to form a <0>Follow-Up Repair</0>. You'd usually do this from the original repair's page.  <1/>Just a heads-up: You can only issue refunds through a follow-up repair.",
      keyUpdatedSuccessfully: "{{field}} actualizado con éxito",
      confirmAddCategory: "Are you sure you want to add the category {{name}}?",
      yourSubscriptionHasExpired:
        "Your subscription has expired. Please go to <1>support</1> to get support.",
      ...enTranslation,
    },
  },
  es: {
    translation: {
      selectedService_one: "{{count}} servicio seleccionado",
      selectedService_other: "{{count}} servicios seleccionados",
      repairParentConnectContext:
        "Conecte esta reparación con una anterior para formar una <0>Reparación de Seguimiento</0>. Normalmente se hace esto desde la página de la reparación original. <1/>Recuerda: Solo puede emitir reembolsos a través de una reparación de seguimiento.",
      keyUpdatedSuccessfully: "{{field}} actualizado con éxito",
      confirmAddCategory:
        "¿Estás seguro de que quieres añadir la categoría {{name}}?",
      yourSubscriptionHasExpired:
        "Tu suscripción ha expirado. Por favor, ve a <1>soporte</1> para obtener ayuda.",
      "Subscription expired!": "¡Suscripción caducada!",

      // Statuses
      ...statuses,
      ...general,
      ...dateTime,
      ...invoices,
      ...services,
      ...printSettings,
      ...validation,
      ...loginPage,
      ...notifications,
      ...organization,
      ...editRepair,
      ...errorsAndAlerts,
      ...inventory,
      ...serviceCatalog,
      ...esTranslation,
    },
  },
};
  const getBrowserLanguage = () => {
    const language =
      (navigator.languages && navigator.languages[0]) || // Chrome / Firefox
      navigator.language || // All browsers
      navigator.userLanguage; // IE <= 10
    return language;
  };

  const language =
    localStorage.getItem("language") ||
    (getBrowserLanguage().startsWith("es") ? "es" : "en");

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .on("languageChanged", (lng) => {
    localStorage.setItem("language", lng);
  })
  .init({
    resources,
    lng: language, // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    fallbackLng: "en",
    debug: false,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;

// AUTOCOMPLETE
import axios from "../../axios-config";
import { forwardRef, useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import { connect } from "react-redux";
import {
  TextField,
  Typography,
  Chip,
  IconButton,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Button,
  ListItemButton,
  InputAdornment,
} from "@mui/material";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { updateNewEntryModalState } from "../../store/actions/newEntry";
import * as actions from "../../store/actions";
import { Box } from "@mui/system";

import { newEntryModalActions } from "../../store/new-entry-modal";
import { newCustomerModalActions } from "../../store/new-customer-modal";
import { useSelector, useDispatch } from "react-redux";
import NewClientModal from "../NewClientModal/NewClientModal";

//  ICONS
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import PersonIcon from "@mui/icons-material/Person";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import MailIcon from "@mui/icons-material/Mail";
import { useTranslation } from "react-i18next";
import { set } from "lodash";
import { AddOutlined, Circle, GroupOutlined } from "@mui/icons-material";
import ConsoleHelper from "utils/ConsoleHelper";

// AUTOCOMPLETE
const CustomersSearch = ({
  required,
  state,
  onOptionSelect,
  sx,
  includePartners = false,
  addNewVisible = true,
}) => {
  const { t } = useTranslation();
  const [lastInputValue, setLastInputValue] = useState("");
  const [customerDialogOpen, setCustomerDialogOpen] = useState(false);
  const dispatch = useDispatch();

  const handleOpenModal = () => {
    setCustomerDialogOpen(true);
  };

  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);

  const loading = open && options.length === 0;

  useEffect(() => {
    if (!open) {
      setLastInputValue("");
    }
  }, [open]);

  useEffect(async () => {
    try {
      let active = true;
      if (!loading) {
        return undefined;
      }
      const response = await axios.get(`/customers`, {
        params: { includePartners },
      });

      const data = response.data;
      if (active) {
        setOptions([...data]);
      }
      return () => {
        active = false;
      };
    } catch (err) {
      console.log(err);
    }
  }, [loading]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const filter = createFilterOptions({
    stringify: (option) => option.name + option.phone + option.email,
  });

  const handleOptionSelect = (option) => {
    onOptionSelect(option);
    setOpen(false);
  };

  const SearchPopover = ({ children, ...other }) => (
    <Paper
      {...other}
      sx={{ height: "250px", p: "15px" }}
      onMouseDown={(event) => event.preventDefault()}
    >
      <Stack spacing={2}>
        {addNewVisible && (
          <Stack direction={"row"}>
            <Button
              variant="outlined"
              onClick={handleOpenModal}
              startIcon={<AddOutlined />}
              size="small"
              color="black"
            >
              {t("New customer")}
            </Button>
          </Stack>
        )}

        <Stack spacing={1}>
          <Typography variant="caption">{t("Customer")}</Typography>
          {children}
        </Stack>
      </Stack>
    </Paper>
  );

  const ForwardedList = forwardRef((props, ref) => (
    <List ref={ref} sx={{ overflow: "auto", maxHeight: "500px" }} {...props} />
  ));

  return (
    <>
      <Autocomplete
        sx={{ ...sx }}
        filterOptions={filter}
        id="customers"
        value={state}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        onInputChange={(event, newValue) => {
          if (newValue === "") {
            event?.stopPropagation();
            setLastInputValue("");
            onOptionSelect(null);
            setOpen(false);
          }
        }}
        disableCloseOnSelect={true}
        onMouseDown={(event) => event.preventDefault()}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => {
          if (option.name) {
            return option.name;
          } else return "";
        }}
        options={options}
        loading={loading}
        PaperComponent={SearchPopover}
        noOptionsText={"There are no customers."}
        ListboxComponent={ForwardedList}
        ListboxProps={{ sx: { overflow: "hidden", maxHeight: "720px" } }}
        renderOption={(props, option, { selected }) => {
          if (props["data-option-index"] < 10) {
            return (
              <OptionList
                {...props}
                key={option.id}
                option={option}
                handleOptionSelect={handleOptionSelect}
              />
            );
          } else {
            return null;
          }
        }}
        fullWidth
        renderInput={(params) => (
          <TextField
            {...params}
            required={required}
            label={t("Customer")}
            placeholder={t("Search or add a customer")}
            variant="filled"
            onChange={(e) => setLastInputValue(e.target.value)}
            onBlur={(e) => setLastInputValue(e.target.value)}
            onClick={(event) => {
              if (!open) {
                setOpen(true);
              }
            }}
            // size="small"
            InputProps={{
              ...params.InputProps,
              // The -3px is to align the icon with the text
              // It's just a workaround, not the best solution
              // TODO: Find a better solution
              // startAdornment: <InputAdornment position="start" style={{ marginTop: '-3px' }}>
              //   <LocalShippingOutlined />
              // </InputAdornment>
            }}
          />
        )}
      />
      <NewClientModal
        open={customerDialogOpen}
        onClose={() => setCustomerDialogOpen(false)}
        onNewClientAdd={onOptionSelect}
        inputValue={lastInputValue}
      />
    </>
  );
};
// const mapStateToProps = (state) => {
//   return {
// entryData:   state.newEntry.entryData,

//   };
// };

const OptionList = ({ option, handleOptionSelect }) => {
  const handleOnClick = () => {
    handleOptionSelect(option);
  };

  return (
    <ListItemButton onClick={handleOnClick} sx={{ minHeight: "72px" }}>
      <ListItemIcon sx={{ minWidth: "26px" }}>
        {option.isPartner ? (
          <GroupOutlined />
        ) : (
          <Circle sx={{ fontSize: "8px" }} />
        )}
      </ListItemIcon>
      <ListItemText
        primary={option.name}
        secondary={[
          option.phone
            ? option.phone.length > 20
              ? option.phone.slice(0, 20) + "..."
              : option.phone
            : null,
          option.email
            ? option.email.length > 20
              ? option.email.slice(0, 20) + "..."
              : option.email
            : null,
        ]
          .filter(Boolean)
          .join(", ")}
        primaryTypographyProps={{ component: "div" }}
        secondaryTypographyProps={{ component: "div" }}
      />
    </ListItemButton>
  );
};

export default CustomersSearch;

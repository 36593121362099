import * as React from "react";
import { useEffect, useState } from "react";

import { useTheme, styled } from "@mui/material/styles";
import {
  Box,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  OutlinedInput,
  Grid,
  TextField,
  Divider,
  Stack,
  IconButton,
  InputAdornment,
  ToggleButton,
  Popover,
} from "@mui/material";

import {
  organizationToInvoiceData,
  customerToInvoiceData,
} from "../../utils/InvoiceHelpers";
//ICONS
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

// import Preview from "./Preview";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";

//Date Picker
import { Calendar, defaultInputRanges } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { subDays, format } from "date-fns";

//OUR COMPONENTS
import StyledToggleButtonGroup from "../../components/StyledToggleButtonGroup/StyledToggleButtonGroup";
import LogoButton from "../../components/Buttons/LogoButton";
import { useTranslation } from "react-i18next";
import { formatCurrency, getCurrencySymbol } from "../../utils/currency";

const A4Creator = ({
  invoiceTo,
  customer,
  lastInvoiceData,
  invoiceLogo,
  setMediaDialogOpen,
  checkedOrders,
  billingTo,
  setBillingTo,
  billingFrom,
  setBillingFrom,
  refId,
  setRefId,
  setPaymentInfo,
  paymentInfo,
  terms,
  setTerms,
  calendarDate,
  setCalendarDate,
  setTaxIncluded,
  taxIncluded,
}) => {
  const currency = useSelector((state) => state.auth.currency);
  const { t } = useTranslation();

  const theme = useTheme();
  const [customItems, setCustomItems] = useState([]); // the ones you add manually
  const [items, setItems] = useState([]); // checkedOrders + customItems
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const refIdFieldRef = React.useRef();

  //Calendar Popover

  const [anchorEl, setAnchorEl] = React.useState(null);
  const calendarOpen = Boolean(anchorEl);
  const calendarId = calendarOpen ? "calendar-popover" : undefined;

  // Taxes
  const [percentTax, setPercentTax] = React.useState(false);
  // const [taxIncluded, setTaxIncluded] = React.useState(true);
  const [taxField, setTaxField] = React.useState(21);

  const dayjs = require("dayjs");

  const handleCalendarOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCalendarClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (lastInvoiceData && lastInvoiceData.refId) {
      setRefId(lastInvoiceData.refId + 1);
      // setBillingFrom(lastInvoiceData.billingFrom);
      setPaymentInfo(lastInvoiceData.paymentInfo);
      setTerms(lastInvoiceData.terms);
    }

    // setBillingFrom(lastInvoiceData.billingFrom);
  }, [lastInvoiceData]);

  useEffect(() => {
    if (invoiceTo === "customer") {
      const data = customerToInvoiceData(customer);
      setBillingTo(data);
    }
    if (invoiceTo === "partner") {
      // if (!customer.legalName || !customer.taxIdentifier) {
      //     enqueueSnackbar("Partner doesn't have tax identification added.")
      //     return;
      // }
      const data = organizationToInvoiceData(customer);
      setBillingTo(data);
    }
  }, [customer]);

  const ticketLogo = () => {
    if (invoiceLogo && invoiceLogo.fileName) {
      return (
        <Box
          className="editableRow clickable"
          height={"100px"}
          p={"10px"}
          borderRadius={"10px"}
          width={"190px"}
          display={"block"}
          sx={{ border: "2px dashed darkGrey" }}
          textAlign={"center"}
          onClick={() => setMediaDialogOpen(true)}
        >
          <img
            className="logo"
            src={`${process.env.REACT_APP_IMAGES_URL}${invoiceLogo.fileName}`}
            alt="logo"
          />
        </Box>
      );
    }
    return <LogoButton onClick={() => setMediaDialogOpen(true)} />;
  };

  let rowsCounter = 0;

  const handleAddRows = () => {
    setCustomItems([...customItems, { name: "", quantity: 1 }]);
  };
  const deleteCurrentRow = (index) => {
    const currentArray = customItems;
    currentArray.splice(index, 1);
    setCustomItems([...currentArray]);
  };

  const handleServiceNameChange = (value, index) => {
    let items = [...customItems];
    let item = { ...customItems[index] };
    item.name = value;
    items[index] = item;
    setCustomItems(items);
  };

  const handleQuantityChange = (value, index) => {
    if (isNaN(value)) {
      return;
    }
    if (value.length > 0) {
      value = value.replace(/[^0-9]/g, "");
    }
    let items = [...customItems];
    let item = { ...items[index] };
    item.quantity = value;
    items[index] = item;
    setCustomItems(items);
  };

  const handlePriceChange = (value, index) => {
    if (isNaN(value)) {
      return;
    }

    let items = [...customItems];
    let item = { ...items[index] };
    item.unitPrice = value;
    items[index] = item;
    setCustomItems(items);
  };

  const handleTaxField = (e) => {
    setTaxField(e.target.value.replace(/\D/g, ""));
  };
  const handleRefIdFiled = (e) => {
    setRefId(e.target.value.replace(/\D/g, ""));
  };

  const handleDiscountChange = (value, index) => {
    if (isNaN(value)) {
      return;
    }

    let items = [...customItems];
    let item = { ...items[index] };
    item.discount = value;
    items[index] = item;
    setCustomItems(items);
  };

  const handleCalendarSelect = (e) => {
    setCalendarDate(e);
  };
  const handleBillingTo = (e) => {
    setBillingTo(e.target.value);
  };

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      // backgroundColor: theme.palette.divGrey.main,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      // border: 0,
    },
  }));

  const validateQuantity = (value, index) => {
    if (value === "" || value <= 0) {
      handleQuantityChange(1, index);
    }
  };

  let invoiceSubTotal = 0;
  let invoiceTax = 0;
  let invoiceTotal = 0;

  return (
    <Paper sx={{ width: "210mm" }}>
      <Stack
        direction={"row"}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: "25px",
        }}
      >
        <Stack direction={"row"} alignItems={"center"} spacing={2}>
          {ticketLogo()}
        </Stack>
        <Stack spacing={2}>
          <Typography variant="h2" textAlign={"right"}>
            {t("Invoice")}
          </Typography>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"end"}
            spacing={2}
          >
            <TextField
              variant="outlined"
              size="small"
              inputRef={refIdFieldRef}
              value={refId}
              onChange={handleRefIdFiled}
              sx={{
                backgroundColor: theme.palette.lightBlue.main,
                width: "150px",
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">#</InputAdornment>
                ),
              }}
            />
          </Stack>
          <Stack direction={"row"} alignItems={"center"} spacing={2}>
            <Popover
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              anchorEl={anchorEl}
              open={calendarOpen}
              id={calendarId}
              onClose={handleCalendarClose}
            >
              <Calendar date={calendarDate} onChange={handleCalendarSelect} />
            </Popover>

            <Stack
              direction={"row"}
              px={"5px"}
              className={"editableRow clickable"}
              onClick={handleCalendarOpen}
            >
              <Typography>
                {t("Date")}: {dayjs(calendarDate).format("DD/MM/YYYY")}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>

      <Divider />

      <Stack
        direction={"row"}
        spacing={5}
        flex
        padding="30px"
        divider={
          <Divider
            sx={{ borderColor: "#8B98AB" }}
            orientation="vertical"
            flexItem
          />
        }
      >
        <Stack>
          <Typography gutterBottom component="div" variant="h3">
            {t("Bill from")}:
          </Typography>
          <TextField
            multiline
            maxRows={10}
            variant={"filled"}
            value={billingFrom}
            onChange={(e) => setBillingFrom(e.target.value)}
            hiddenLabel
          />
        </Stack>
        <Stack
          direction={"column"}
          spacing={2}
          alignItems={"start"}
          justifyContent={"start"}
        >
          <Box>
            <Box>
              <Typography gutterBottom component="div" variant="h3">
                {t("Bill to")}:
              </Typography>
            </Box>
            <TextField
              width={"200px"}
              multiline
              maxRows={10}
              variant={"filled"}
              value={billingTo}
              onChange={handleBillingTo}
              hiddenLabel
            />
          </Box>
        </Stack>
      </Stack>

      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <StyledTableRow>
              {/* <TableCell>#</TableCell> */}
              <TableCell>{t("Item")}</TableCell>
              <TableCell>
                {t("Price")} ({getCurrencySymbol(currency)})
              </TableCell>
              <TableCell>{t("Quantity")}</TableCell>
              <TableCell>
                {t("Discount")} ({getCurrencySymbol(currency)})
              </TableCell>
              <TableCell>{t("Tax")} %</TableCell>
              <TableCell>
                {t("Total")} ({getCurrencySymbol(currency)})
              </TableCell>
              <TableCell></TableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {checkedOrders.map((order, index) => {
              return order.services.map((service) => {
                const taxFieldValue =
                  parseFloat(taxField) >= 0 ? parseFloat(taxField) : 0;
                let taxPercentage = 1.0 + taxFieldValue / 100;

                // Tax included or excluded?

                let discount = service.discount
                  ? parseFloat(service.discount)
                  : 0;

                let unitPrice = service.unitPrice
                  ? parseFloat(service.unitPrice)
                  : 0;
                const quantity = service.quantity
                  ? parseInt(service.quantity)
                  : 1;
                let subTotal = 0;
                let tax = 0;
                let total = 0;
                if (!taxIncluded) {
                  subTotal = unitPrice * quantity - discount;
                  tax = subTotal * taxPercentage - subTotal;
                  total = subTotal + tax;
                } else {
                  subTotal = (unitPrice * quantity - discount) / taxPercentage;
                  total = unitPrice * quantity - discount;
                  unitPrice = unitPrice / taxPercentage;
                  discount = discount / taxPercentage;
                  tax = total - total / taxPercentage;
                }

                invoiceSubTotal += subTotal;
                invoiceTax += tax;
                invoiceTotal += total;
                rowsCounter++;

                return (
                  <TableRow key={rowsCounter}>
                    {/* <TableCell>
            {rowsCounter}
        </TableCell> */}
                    <TableCell>
                      <Box>
                        {order.brand} {order.model} - {service.name}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box>{unitPrice.toFixed(2)}</Box>
                    </TableCell>
                    <TableCell>
                      <Box>x{quantity}</Box>
                    </TableCell>
                    <TableCell>
                      <Box>{discount.toFixed(2)}</Box>
                    </TableCell>
                    <TableCell>
                      <Box>{tax.toFixed(2)}</Box>
                    </TableCell>
                    <TableCell>
                      {!taxIncluded ? (
                        <Box>{total.toFixed(2)}</Box>
                      ) : (
                        <Box
                          sx={{
                            backgroundColor: theme.palette.secondary.main,
                            p: "5px",
                            borderRadius: "5px",
                          }}
                        >
                          {total.toFixed(2)}
                        </Box>
                      )}
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                );
              });
            })}
            {customItems.map((row, index) => {
              rowsCounter++;

              const discount = row.discount ? row.discount : 0;
              const unitPrice = row.unitPrice ? row.unitPrice : 0;
              const quantity = row.quantity ? row.quantity : 1;
              const subTotal = unitPrice * quantity - discount;

              const tax = subTotal * 1.21 - subTotal;
              const total = subTotal + tax;
              invoiceSubTotal += subTotal;
              invoiceTax += tax;
              invoiceTotal += total;
              return (
                <TableRow key={rowsCounter}>
                  {/* <TableCell>
        {rowsCounter}
    </TableCell> */}
                  <TableCell>
                    <TextField
                      hiddenLabel
                      fullWidth
                      id="Item Description"
                      placeholder="Some long item description"
                      value={row.name}
                      sx={{ backgroundColor: theme.palette.lightBlue.main }}
                      onChange={(e) =>
                        handleServiceNameChange(e.target.value, index)
                      }
                      size="small"
                      multiline
                      maxRows={3}
                      inputProps={{ style: { fontSize: "14px" } }}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      hiddenLabel
                      id="unitPrice"
                      value={row.unitPrice}
                      sx={{
                        backgroundColor: theme.palette.lightBlue.main,
                        width: "80px",
                      }}
                      onChange={(e) => handlePriceChange(e.target.value, index)}
                      size="small"
                      inputProps={{ style: { fontSize: "14px" } }}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      hiddenLabel
                      id="quantity"
                      value={row.quantity}
                      onChange={(e) =>
                        handleQuantityChange(e.target.value, index)
                      }
                      sx={{
                        backgroundColor: theme.palette.lightBlue.main,
                        width: "40px",
                      }}
                      size="small"
                      onBlur={(e) => validateQuantity(e.target.value, index)}
                      inputProps={{ style: { fontSize: "14px" } }}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      hiddenLabel
                      id="discount"
                      value={row.discount}
                      sx={{
                        backgroundColor: theme.palette.lightBlue.main,
                        width: "110px",
                      }}
                      onChange={(e) =>
                        handleDiscountChange(e.target.value, index)
                      }
                      size="small"
                      inputProps={{ style: { fontSize: "14px" } }}
                    />
                  </TableCell>
                  <TableCell id={"tax"}>
                    <Typography width={"90px"} fontSize={"14px"}>
                      {formatCurrency(tax, currency)}
                    </Typography>
                  </TableCell>
                  <TableCell id={"total"}>
                    <Typography width={"70px"} fontSize={"14px"}>
                      {formatCurrency(total, currency)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Box>
                      <IconButton
                        size="small"
                        color="error"
                        onClick={(e) => deleteCurrentRow(index)}
                      >
                        <DeleteOutlineOutlinedIcon />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Stack alignItems={"center"} my={2}>
        {/* <Button
                variant="outlined"
                startIcon={<AddCircleOutlineIcon />}
                onClick={handleAddRows}
                align="center"
            >
                {t('Add Service')}
            </Button> */}
      </Stack>

      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: "30px",
        }}
      >
        <Grid item xs={4}>
          <Grid>
            <OutlinedInput
              multiline
              minRows={2}
              placeholder={t("Payment info")}
              variant="outlined"
              value={paymentInfo || ""}
              sx={{ backgroundColor: theme.palette.lightBlue.main }}
              onChange={(e) => setPaymentInfo(e.target.value)}
            />
          </Grid>
          <Grid sx={{ paddingTop: "14px" }}>
            <OutlinedInput
              multiline
              minRows={2}
              placeholder={t("Terms or note")}
              variant="outlined"
              value={terms || ""}
              onChange={(e) => setTerms(e.target.value)}
              sx={{ backgroundColor: theme.palette.lightBlue.main }}
            />
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Stack spacing={2}>
            <Stack direction={"row"} justifyContent={"space-between"}>
              <Typography>{t("Base")}:</Typography>
              <Typography>
                {formatCurrency(invoiceSubTotal, currency)}
              </Typography>
            </Stack>
            <Stack alignItems={"center"}>
              <Box width={"350px"}>
                <StyledToggleButtonGroup
                  size="small"
                  value={taxIncluded}
                  exclusive
                  onChange={(e) => {
                    // console.log(e.target.value, 'target')
                    setTaxIncluded(!taxIncluded);
                  }}
                  aria-label="text alignment"
                  sx={{ backgroundColor: "#F1F9FD" }}
                >
                  <ToggleButton value={true}>{t("Tax Included")}</ToggleButton>
                  <ToggleButton value={false}>{t("Tax Excluded")}</ToggleButton>
                </StyledToggleButtonGroup>
              </Box>
            </Stack>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography>{t("Tax")}:</Typography>
              <Stack direction={"row"}>
                <Box width={"80px"}>
                  <TextField
                    size="small"
                    value={taxField}
                    onChange={handleTaxField}
                  />
                </Box>
                {/* <TaxPercentageSwitch onChange={() => setPercentTax(!percentTax)} checked={percentTax} /> */}
              </Stack>
            </Stack>
            <Divider />
            <Grid item sx={{ paddingTop: "10px" }}>
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography sx={{ fontWeight: "bold" }}>
                  {t("Total tax included")}:
                </Typography>
                <Typography sx={{ fontWeight: "bold" }}>
                  {formatCurrency(invoiceTotal, currency)}
                </Typography>
              </Stack>
            </Grid>
          </Stack>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default A4Creator;

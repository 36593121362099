import {
  Typography,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { NavigateNext } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import axios from "../../axios-config";
import { LoadingButton } from "@mui/lab";
import DeviceSearchField from "../../components/DeviceSearchField/DeviceSearchField";

const LinkDeviceDialog = ({
  dialogOpen,
  handleDialogClose,
  selectedServices,
  setSelectedServices,
  onDelete,
  services,
  setServices,
  deviceDataSelected,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [isProcessing, setIsProcessing] = useState(false);
  const [deviceData, setDeviceData] = useState(null);

  useEffect(() => {
    if (!deviceDataSelected) {
      setDeviceData(null);
      return;
    }
    setDeviceData(deviceDataSelected);
  }, [deviceDataSelected]);

  const handleDeviceSelect = (device) => {
    setDeviceData(device);
  };

  const handleAccept = async () => {
    try {
      setIsProcessing(true);

      const response = await axios.patch(
        `/service-catalogs/link-services-to-device`,
        { serviceIds: selectedServices, deviceCatalogId: deviceData?.id }
      );
      setIsProcessing(false);
      if (response.status === 200) {
        const updatedServices = response.data;
        const newServices = services.map(
          (service) =>
            updatedServices.find(
              (updatedService) => updatedService.id === service.id
            ) || service
        );
        setServices(newServices);
        handleDialogClose();
        enqueueSnackbar(t("Services linked successfully"), {
          variant: "success",
        });
      }
    } catch (err) {
      console.log(err);
      setIsProcessing(false);
      enqueueSnackbar(`Something went wrong!`, { variant: "error" });
    }
  };

  return (
    <Dialog
      open={dialogOpen}
      onClose={handleDialogClose}
      fullWidth
      maxWidth={"xs"}
    >
      <DialogTitle variant="h4">{t("Link services to device")}</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Typography variant="body" color="textSecondary">
            <Trans
              i18nKey="linkServicesToDevice"
              count={selectedServices.length}
            >
              <strong>{{ count: selectedServices.length }} services</strong>{" "}
              will be linked to device
            </Trans>
          </Typography>
          <DeviceSearchField
            onChange={handleDeviceSelect}
            selectedDeviceData={deviceDataSelected}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialogClose} color="black">
          {t("Cancel")}
        </Button>
        <LoadingButton
          loading={isProcessing}
          onClick={handleAccept}
          loadingPosition="end"
          variant="contained"
          color={"primary"}
          endIcon={<NavigateNext />}
        >
          {t("Link to device")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default LinkDeviceDialog;

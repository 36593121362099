import { useState, useRef, useEffect } from "react";
import {
  Paper,
  Button,
  Select,
  MenuItem,
  InputLabel,
  Popover,
  Stack,
  OutlinedInput,
  Box,
} from "@mui/material";
import {
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  Checkbox,
} from "@mui/material";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  IconButton,
} from "@mui/material";

import { useSelector, useDispatch } from "react-redux";
import { dataTableActions } from "../../store/data-table";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import SortIcon from "@mui/icons-material/Sort";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import WhatshotOutlinedIcon from "@mui/icons-material/WhatshotOutlined";

import { useTranslation } from "react-i18next";
import PartnersFilter from "./PartnersFilter";
import SearchBar from "./SearchBar";
import DueToPartner from "../HeaderWidgets/DueToPartners";
import DueFromServices from "../HeaderWidgets/DueFromServices";
import EmergencyIconFilled from "../Icons/EmergencyIconFilled";
import axios from "axios-config";

const Sorts = ({ searchInFields, setSearchInFields, searchFieldsBucket }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const statusesSort = useSelector((state) => state.dataTable.statuses);
  const urgenciesToggle = useSelector(
    (state) => state.dataTable.urgenciesToggle
  );

  const setStatusesSort = (statuses) =>
    dispatch(dataTableActions.setStatuses(statuses));
  const setStatusesLoaded = (value) =>
    dispatch(dataTableActions.setStatusesLoaded(value));
  // const hideAllStatuses = () => dispatch(dataTableActions.hideAllStatuses());
  // const showAllStatuses = () => dispatch(dataTableActions.showAllStatuses());
  const toggleUrgency = () => dispatch(dataTableActions.toggleUrgency());

  const selectRef = useRef();
  const [open, setOpen] = useState(false);

  const [value, setValue] = useState(1);

  const handleSelection = (newValue) => {
    setValue(newValue);
    setOpen(false);
  };

  const [selectedSort, setSelectedSort] = useState("status");

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const statuses = reorder(
      statusesSort,
      result.source.index,
      result.destination.index
    );

    setStatusesSort(statuses);
    updateDefaultStatusesFilter(statuses);
  };

  const updateDefaultStatusesFilter = async (statuses) => {
    try {
      const response = await axios.patch(`/repair-filters/default`, {
        statuses,
      });
      // const data = response.data;
      // if (data.statuses) {
      //     setStatusesSort(data.statuses);
      // }
      // setStatusesSort(data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleHideButtonClick = (index) => {
    const newStatuses = statusesSort.map((status, i) =>
      i === index ? { ...status, isHidden: !status.isHidden } : status
    );
    setStatusesSort(newStatuses);
    updateDefaultStatusesFilter(newStatuses);
  };

  const statusParentCheck = () => {
    let hiddenAmount = 0;
    let statusesLength = statusesSort.length;
    for (let i = 0; i < statusesLength; i++) {
      if (statusesSort[i].isHidden) {
        hiddenAmount++;
      }
    }

    let isUnchecked = hiddenAmount === statusesLength;
    if (isUnchecked) {
      return false;
    }
    return true;
  };
  const statusIsIntermediate = () => {
    let hiddenAmount = 0;
    let statusesLength = statusesSort.length;
    for (let i = 0; i < statusesLength; i++) {
      if (statusesSort[i].isHidden) {
        hiddenAmount++;
      }
    }
    let isItermediate = hiddenAmount !== 0 && hiddenAmount !== statusesLength;
    if (isItermediate) {
      return true;
    }
    return false;
  };
  const handleCheckAll = () => {
    const isChecked = statusParentCheck();
    if (isChecked) {
      hideAllStatuses();
    } else {
      showAllStatuses();
    }
  };

  const hideAllStatuses = () => {
    const newStatuses = statusesSort.map((status) => ({
      ...status,
      isHidden: true,
    }));
    setStatusesSort(newStatuses);
    updateDefaultStatusesFilter(newStatuses);
  };

  const showAllStatuses = () => {
    const newStatuses = statusesSort.map((status) => ({
      ...status,
      isHidden: false,
    }));
    setStatusesSort(newStatuses);
    updateDefaultStatusesFilter(newStatuses);
  };

  const getDefaultRepairsFilter = async () => {
    try {
      const response = await axios.get(`/repair-filters/default`);
      const data = response.data;
      if (Array.isArray(data.statuses)) {
        setStatusesSort(data.statuses);
        return;
      }
      try {
        const statusesArray = JSON.parse(data.statuses);
        if (Array.isArray(statusesArray)) {
          setStatusesSort(statusesArray);
        } else {
          console.error("data.statuses is not an array");
        }
      } catch (err) {
        console.error("Failed to parse data.statuses:", err);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setStatusesLoaded(true);
    }
  };

  useEffect(() => {
    getDefaultRepairsFilter();
  }, []);

  return (
    <>
      <>
        <Button
          variant="outlined"
          color="black"
          ref={selectRef}
          onClick={() => setOpen(true)}
          startIcon={<SortIcon />}
        >
          {t("Status")}
        </Button>
        <PartnersFilter />
        <Button
          variant="outlined"
          color={urgenciesToggle ? "red" : "black"}
          startIcon={
            urgenciesToggle ? <EmergencyIconFilled /> : <WhatshotIcon />
          }
          onClick={toggleUrgency}
        >
          {t("Emergencies")}
        </Button>

        {/* <Button variant='outlined' color='black' startIcon={<AttachMoneyOutlinedIcon/>} onClick={() => setOpen(true)}>{t("Payments filter")}</Button> */}
        <SearchBar
          searchInFields={searchInFields}
          setSearchInFields={setSearchInFields}
          searchFieldsBucket={searchFieldsBucket}
        />
        <DueFromServices />
        <DueToPartner />
      </>

      <Popover
        id="status-sort"
        open={open}
        anchorEl={selectRef.current}
        onClose={() => handleSelection(value)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box p={"15px"}>
          <Stack>
            <FormControl>
              {/* <FormLabel id="demo-radio-buttons-group-label">{t('Quick filters')}</FormLabel> */}
              {/* <Stack alignItems={'start'}> */}
              {/* <Button>Active orders</Button> */}
              {/* <Button>Order parts</Button> */}
              {/* <Button>Reset to default</Button> */}
              {/* </Stack> */}
            </FormControl>
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">
                {t("Status priority")}
              </FormLabel>
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <FormControlLabel
                  label={t("Statuses")}
                  control={
                    <Checkbox
                      checked={statusParentCheck()}
                      indeterminate={statusIsIntermediate()}
                      onChange={handleCheckAll}
                    />
                  }
                />
                {/* <Button>Reset to default</Button> */}
              </Stack>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                value={selectedSort}
                onChange={(e) => setSelectedSort(e.target.value)}
              >
                {/* <FormControlLabel value="status" control={<Radio />} label={t("Status")} /> */}
                {selectedSort == "status" ? (
                  <>
                    <DragDropContext onDragEnd={onDragEnd}>
                      <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                          <List
                            sx={{ pt: "0px" }}
                            // {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            {statusesSort?.map((status, i) => (
                              <Draggable
                                key={status.id}
                                draggableId={status.id}
                                index={i}
                              >
                                {(provided) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <ListItem
                                      key={status.id}
                                      sx={{ py: "0px" }}
                                      secondaryAction={
                                        <IconButton
                                          color={
                                            status.isHidden
                                              ? "secondary"
                                              : "primary"
                                          }
                                          size="small"
                                          onClick={() =>
                                            handleHideButtonClick(i)
                                          }
                                        >
                                          {status.isHidden ? (
                                            <VisibilityOffIcon fontSize="inherit" />
                                          ) : (
                                            <VisibilityIcon fontSize="inherit" />
                                          )}
                                        </IconButton>
                                      }
                                    >
                                      <ListItemIcon sx={{ minWidth: "30px" }}>
                                        <DragIndicatorIcon />
                                      </ListItemIcon>
                                      <ListItemText primary={t(status.value)} />
                                    </ListItem>
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </List>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </>
                ) : null}
              </RadioGroup>
            </FormControl>
          </Stack>
        </Box>
      </Popover>
    </>
  );
};

export default Sorts;

import { useState, useEffect } from "react";
import axios from "../axios-config";
export function usePrintSettings() {
  const [loading, setLoading] = useState(true);
  const [settings, setSettings] = useState({
    paperType: "0",
    title: "Ticket",
    titleEnabled: true,
    dateEnabled: true,
    organizationName: "",
    organizationNameEnabled: true,
    organizationAddress: "",
    organizationAddressEnabled: true,
    organizationLogoEnabled: true,
    customerNameEnabled: true,
    customerPhoneEnabled: true,
    malfunctionEnabled: true,
    estimatedServicesEnabled: true,
    customerSignatureEnabled: true,
    customerSignatureLabel: "",
    firstNoteEnabled: true,
    firstNote: "",
    secondNoteEnabled: true,
    secondNote: "",
    repairPaymentsDepositsEnabled: true,
    repairOrderNoteEnabled: true,
  });
  const [organization, setOrganization] = useState(null);
  const [logo, setLogo] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const [printSettingsResp, organizationResp] = await Promise.all([
          axios.get(`/organizations/printSettings`),
          axios.get("organizations"),
        ]);

        if (
          organizationResp.status === 200 ||
          organizationResp.status === 201
        ) {
          setOrganization(organizationResp.data);
        }
        if (printSettingsResp.status === 200) {
          if (printSettingsResp.data) {
            setSettings(printSettingsResp.data);

            if (printSettingsResp.data.file) {
              setLogo(printSettingsResp.data.file);
            }
          }
        }
      } catch (error) {
        console.error(
          "Error fetching print settings or organization data:",
          error
        );
        // Optionally, set an error state here
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return { settings, setSettings, organization, logo, setLogo, loading };
}

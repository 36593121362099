import * as React from "react";
import { TextField } from "@mui/material";
import { useState } from "react";

const ReactiveTextField = (props) => {
  const { initialState, onBlurCallback, ...other } = props;
  const onInputFocus = () => {
    if (editMode === 1 || editMode === 2) {
      setEditMode(1);
      setValue(value);
      return;
    }
    setEditMode(1);
    setValue(initialState);
  };
  const handleBuffer = (key) => (event) => {
    // console.log(event.target, key);
    setValue(event.target.value);
    // setEditMode({ ...editMode, [key]: { ...editMode[key], value: event.target.value } });
  };

  const onBlur = () => {
    if (
      initialState != null &&
      typeof initialState == "string" &&
      initialState.trim() == value.trim()
    ) {
      setEditMode(0);
      return;
    }

    if (typeof value !== "number") {
      onBlurCallback(value.trim());
    }
  };

  const [editMode, setEditMode] = useState(0);
  const [value, setValue] = useState("");

  return (
    <TextField
      onFocus={onInputFocus}
      onBlur={onBlur}
      value={
        editMode === 0 ? (initialState ? initialState : "") : value ? value : ""
      }
      onChange={handleBuffer()}
      variant={props.variant ? props.variant : "filled"}
      {...other}
    />
  );
};

export default ReactiveTextField;

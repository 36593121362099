import { useState, useCallback } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Stack,
} from "@mui/material";

// import debounce from "lodash/debounce";

const AddDevice = ({
  addDeviceOpen,
  handleAddDeviceClose,
  handleAddNewDevice,
  handleDeviceClick,
}) => {
  const [serviceName, setServiceName] = useState("");
  const [price, setPrice] = useState("");
  // const [model, setModel] = useState("");
  // const [matchedResults, setMachedResults] = useState([]);
  // const [anchorEl, setAnchorEl] = useState(null);
  // const [deviceInput, setDeviceInput] = useState("");

  // const debouncedSave = useCallback(
  //   debounce((nextValue) => setModel(nextValue), 350),
  //   [] // will be created only once initially
  // );

  // const handleDeviceChange = (val) => {
  //   const nextValue = val;
  //   setDeviceInput(nextValue);
  //   debouncedSave(nextValue);
  // };

  const handleSubmit = () => {};

  return (
    <Dialog
      open={addDeviceOpen}
      onClose={handleAddDeviceClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Add a new device</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <TextField
            size="small"
            variant="filled"
            label="Brand"
            placeholder="Apple"
            value={serviceName}
            onChange={(e) => setServiceName(e.target.value)}
          />
          <TextField
            size="small"
            variant="filled"
            label="Device Name"
            placeholder="iPhone 12 Pro Max"
            value={serviceName}
            onChange={(e) => setServiceName(e.target.value)}
          />
          <TextField
            size="small"
            variant="filled"
            label="Models"
            placeholder="A2411, A2342, A2410,"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
          />
          <TextField
            size="small"
            variant="filled"
            label="Description"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
          />
        </Stack>
      </DialogContent>
      <DialogActions sx={{ bottom: "0" }}>
        <Button onClick={handleAddDeviceClose}>Cancel</Button>
        <Button onClick={handleSubmit}>Add Device</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddDevice;

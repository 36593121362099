import React, { useRef } from "react";
import { useState, useEffect } from "react";

import {
  Card,
  CardContent,
  Typography,
  Button,
  Paper,
  Grid,
  Divider,
  InputAdornment,
  OutlinedInput,
  TableCell,
  TableRow,
  Stack,
  Box,
  Table,
  TableHead,
  TableContainer,
  TableBody,
  TextField,
  ToggleButton,
  Popover,
} from "@mui/material";
import { useReactToPrint } from "react-to-print";
import { useSelector } from "react-redux";
import "./Print.css";
import "./invoice-a4.css";

import axios from "../../axios-config";

//ICONS
import { useNavigate } from "react-router-dom";
import ReactiveTextField from "../ReactiveTextField/ReactiveTextField";
import StyledToggleButtonGroup from "../StyledToggleButtonGroup/StyledToggleButtonGroup";
import { Calendar } from "react-date-range";
import UploadFilesDialog from "../UploadFilesDialog/UploadFilesDialog";
import LogoButton from "../Buttons/LogoButton";
import { useTranslation } from "react-i18next";

import { formatCurrency, getCurrencySymbol } from "../../utils/currency";

const InvoiceA4 = React.forwardRef(
  ({ invoice, onUpdateRefId, setInvoice }, ref) => {
    const { t } = useTranslation();
    const [isEditingField, setIsEditingField] = useState(null);
    const [editingValue, setEditingValue] = useState("");
    const [billingFrom, setBillingFrom] = useState(invoice.billingFrom);

    const dayjs = require("dayjs");
    const navigate = useNavigate();

    // const [taxIncluded, setTaxIncluded] = React.useState(false);
    const [mediaDialogOpen, setMediaDialogOpen] = useState(false);

    //Calendar Popover

    const [anchorEl, setAnchorEl] = React.useState(null);
    const calendarOpen = Boolean(anchorEl);
    const calendarId = calendarOpen ? "calendar-popover" : undefined;

    const handleCalendarOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleCalendarClose = () => {
      setAnchorEl(null);
    };

    const handleCalendarSelect = async (e) => {
      const value = dayjs(e).toISOString();
      const body = { date: value };

      try {
        const resp = await axios.patch(`invoices/date/${invoice.id}`, body);

        if (resp.status !== 200) {
          throw resp.status;
        }
        const newInvoice = { ...invoice };
        newInvoice.date = value;
        setInvoice(newInvoice);
        // handleCalendarClose();
      } catch (err) {
        console.log(err);
        // enqueueSnackbar(`Something went wrong!`, { variant: "error" });
      }
    };

    const setInvoiceLogo = async (file) => {
      const body = { fileId: file.id };
      try {
        const resp = await axios.patch(`invoices/logo/${invoice.id}`, body);

        if (resp.status !== 200) {
          throw resp.status;
        }

        const newInvoice = { ...invoice };
        newInvoice.file = file;
        setInvoice(newInvoice);
      } catch (err) {
        console.log(err);
        // enqueueSnackbar(`Something went wrong!`, { variant: "error" });
      }
    };

    const paymentTypographyRef = useRef();

    const currency = useSelector((state) => state.auth.currency);
    if (!invoice || !invoice.invoiceItems) {
      return "no invoice";
    }
    const base = invoice.invoiceItems.reduce(
      (prev, current) =>
        prev +
        parseFloat(current.unitPrice * current.quantity - current.discount),
      0
    );
    let invoiceSubTotal = 0;
    let invoiceTax = 0;
    let invoiceTotal = 0;

    const handleNavigationToOrder = (orderId) => {
      if (!orderId) return;
      navigate(`/editEntry/${orderId}`);
    };

    const handleTaxIncludeToggle = async () => {
      try {
        const newInvoice = { ...invoice };
        const newValue = !newInvoice.taxIncluded;
        newInvoice.taxIncluded = newValue;

        const body = { taxIncluded: newValue };
        const resp = await axios.patch(
          `invoices/taxIncluded/${invoice.id}`,
          body
        );
        if (resp.status !== 200) {
          throw resp.status;
        }

        setInvoice(newInvoice);
      } catch (err) {
        console.log(err);
        // enqueueSnackbar(`Something went wrong!`, { variant: "error" });
      }
    };

    const onBlur = async (key, newValue) => {
      const value = newValue;
      const body = { [key]: value };

      try {
        const resp = await axios.patch(`invoices/${key}/${invoice.id}`, body);

        const newInvoice = { ...invoice };
        newInvoice[key] = newValue;
        setInvoice(newInvoice);

        if (resp.status !== 200) {
          throw resp.status;
        }
      } catch (err) {
        console.log(err);
        // enqueueSnackbar(`Something went wrong!`, { variant: "error" });
      }
    };
    const tableRowsRender = () => {
      return invoice.invoiceItems.map((invoiceItem, index) => {
        let taxPercentage = 1.0 + invoice.taxAmount / 100;
        // Tax included or excluded?

        let discount = invoiceItem.discount
          ? parseFloat(invoiceItem.discount)
          : 0;

        let unitPrice = invoiceItem.unitPrice
          ? parseFloat(invoiceItem.unitPrice)
          : 0;
        const quantity = invoiceItem.quantity
          ? parseInt(invoiceItem.quantity)
          : 1;
        let subTotal = 0;
        let tax = 0;
        let total = 0;
        if (!invoice.taxIncluded) {
          subTotal = unitPrice * quantity - discount;
          tax = subTotal * taxPercentage - subTotal;
          total = subTotal + tax;
        } else {
          subTotal = (unitPrice * quantity - discount) / taxPercentage;
          total = unitPrice * quantity - discount;
          unitPrice = unitPrice / taxPercentage;
          discount = discount / taxPercentage;
          tax = total - total / taxPercentage;
        }

        invoiceSubTotal += subTotal;
        invoiceTax += tax;
        invoiceTotal += total;

        return (
          <tr key={index}>
            <td>{index + 1}</td>
            <td>{invoiceItem.name}</td>
            <td>{unitPrice.toFixed(2)}</td>
            <td>x{quantity}</td>
            <td>{discount.toFixed(2)}</td>
            <td>{tax.toFixed(2)}</td>
            <td>{total.toFixed(2)}</td>
          </tr>
        );
      });
    };

    const handleOnBlur = async (key, newValue) => {
      console.log("key", key);
      const value = newValue;
      setIsEditingField(null);
      onBlur(key, value);
    };

    const editRefId = () => {
      return (
        <Stack direction={"row"} alignItems={"center"} spacing={2}>
          <h2>{t("Invoice")} #</h2>
          <EditableField
            className="no-print"
            value={editingValue}
            onBlurCallback={(newValue) => {
              onBlur("refId", newValue);
              setIsEditingField(null);
            }}
            placeholder={"#123"}
            isEditing={isEditingField === "refId"}
            setIsEditing={setIsEditingField}
            variant={"h2"}
            params={{ sx: { width: "80px" } }}
          />
        </Stack>
      );
    };

    const editBillingFrom = () => {
      return (
        <EditableField
          className="no-print"
          value={editingValue}
          onBlurCallback={(newValue) => {
            onBlur("billingFrom", newValue);
            setIsEditingField(null);
          }}
          placeholder={"Your billing details"}
          isEditing={isEditingField === "billingFrom"}
          setIsEditing={setIsEditingField}
          variant={"p"}
        />
      );
    };
    const editPaymentInfo = () => {
      return (
        <EditableField
          value={editingValue}
          onBlurCallback={(newValue) => {
            onBlur("paymentInfo", newValue);
            setIsEditingField(null);
          }}
          placeholder={"Add payment info"}
          isEditing={isEditingField === "paymentInfo"}
          setIsEditing={setIsEditingField}
          variant={"h6"}
          params={{ sx: { width: "100%" } }}
        />
      );
    };
    const editBillingTo = () => {
      return (
        <EditableField
          value={editingValue}
          onBlurCallback={(newValue) => {
            onBlur("billingTo", newValue);
            setIsEditingField(null);
          }}
          placeholder={t("Add billing details")}
          isEditing={isEditingField === "billingTo"}
          setIsEditing={setIsEditingField}
          variant={"p"}
        />
      );
    };
    const editTerms = () => {
      return (
        <EditableField
          value={editingValue}
          onBlurCallback={(newValue) => {
            onBlur("terms", newValue);
            setIsEditingField(null);
          }}
          placeholder={"Add payment info"}
          isEditing={isEditingField === "terms"}
          setIsEditing={setIsEditingField}
          variant={"p"}
          params={{ sx: { width: "100%" } }}
        />
      );
    };

    return (
      <>
        <div ref={ref}>
          <div id="invoice-a4" className="invoice">
            <div className="header">
              <div className="logo">
                {invoice.file ? (
                  <Box
                    className="editableRow clickable"
                    height={"100px"}
                    p={"10px"}
                    borderRadius={"10px"}
                    width={"190px"}
                    display={"block"}
                    textAlign={"center"}
                    onClick={() => setMediaDialogOpen(true)}
                  >
                    <img
                      className="logo"
                      src={`${process.env.REACT_APP_IMAGES_URL}${invoice.file.fileName}`}
                      alt="logo"
                    />
                  </Box>
                ) : (
                  <LogoButton
                    onClick={() => setMediaDialogOpen(true)}
                    className={"no-print"}
                  />
                )}
              </div>
              <div className="invoice-info">
                {isEditingField === "refId" ? (
                  editRefId()
                ) : (
                  <div className="invoice-row">
                    <h2>{t("Invoice")}</h2>
                    <h2
                      className="editable-text"
                      onClick={() => {
                        setEditingValue(invoice.refId);
                        setIsEditingField("refId");
                      }}
                    >
                      {" "}
                      # {invoice.refId}
                    </h2>
                  </div>
                )}
                <p
                  className={"editableRow clickable"}
                  onClick={handleCalendarOpen}
                >
                  {t("Date")}: {dayjs(invoice.date).format("DD/MM/YYYY")}
                </p>
              </div>
            </div>

            <div className="details">
              <div>
                <h4> {t("Bill from")}:</h4>
                {/* <p>{invoice.billingFrom}</p> */}
                {isEditingField === "billingFrom" ? (
                  editBillingFrom()
                ) : !invoice.billingFrom ||
                  invoice.billingFrom?.length === 0 ? (
                  <p
                    className="editable-text pre-wrap placeholder no-print"
                    onClick={() => {
                      setIsEditingField("billingFrom");
                      setEditingValue(invoice.billingFrom);
                    }}
                  >
                    {t("Your billing details")}
                  </p>
                ) : (
                  <p
                    className="editable-text pre-wrap"
                    onClick={() => {
                      setIsEditingField("billingFrom");
                      setEditingValue(invoice.billingFrom);
                    }}
                  >
                    {invoice.billingFrom}
                  </p>
                )}
              </div>

              <div>
                <h4> {t("Bill to")}:</h4>
                {isEditingField === "billingTo" ? (
                  editBillingTo()
                ) : !invoice.billingTo || invoice.billingTo.length === 0 ? (
                  <p
                    className="editable-text pre-wrap placeholder no-print"
                    onClick={() => {
                      setIsEditingField("billingTo");
                      setEditingValue(invoice.billingTo);
                    }}
                  >
                    {t("Add billing details")}
                  </p>
                ) : (
                  <p
                    className="editable-text pre-wrap"
                    onClick={() => {
                      setIsEditingField("billingTo");
                      setEditingValue(invoice.billingTo);
                    }}
                  >
                    {invoice.billingTo}
                  </p>
                )}
              </div>
            </div>

            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>{t("Item")}</th>
                  <th>
                    {t("Price")} ({getCurrencySymbol(currency)})
                  </th>
                  <th>{t("Quantity")}</th>
                  <th>
                    {t("Discount")} ({getCurrencySymbol(currency)})
                  </th>
                  <th>
                    {t("Tax")} ({getCurrencySymbol(currency)})
                  </th>
                  <th>
                    {t("Total")} ({getCurrencySymbol(currency)})
                  </th>
                </tr>
              </thead>
              <tbody>{tableRowsRender()}</tbody>
            </table>

            <div className="invoice-container">
              <div className="note">
                {isEditingField === "paymentInfo" ? (
                  editPaymentInfo()
                ) : !invoice.paymentInfo ||
                  invoice.paymentInfo?.length === 0 ? (
                  <h6
                    className="editable-text pre-wrap placeholder no-print"
                    onClick={() => {
                      setIsEditingField("paymentInfo");
                      setEditingValue(invoice.paymentInfo);
                    }}
                  >
                    {t("Add payment info")}
                  </h6>
                ) : (
                  <h6
                    className="editable-text pre-wrap"
                    onClick={() => {
                      setIsEditingField("paymentInfo");
                      setEditingValue(invoice.paymentInfo);
                    }}
                  >
                    {invoice.paymentInfo}
                  </h6>
                )}
                {isEditingField === "terms" ? (
                  editTerms()
                ) : !invoice.terms || invoice.terms?.length === 0 ? (
                  <p
                    className="editable-text pre-wrap placeholder no-print"
                    onClick={() => {
                      setIsEditingField("terms");
                      setEditingValue(invoice.terms);
                    }}
                  >
                    {t("Add terms or note")}
                  </p>
                ) : (
                  <p
                    className="editable-text pre-wrap"
                    onClick={() => {
                      setIsEditingField("terms");
                      setEditingValue(invoice.terms);
                    }}
                  >
                    {invoice.terms}
                  </p>
                )}
              </div>

              <div className="totals">
                <div className="total-row">
                  <p>{t("invoices.subtotal")}:</p>
                  <p>{formatCurrency(invoiceSubTotal, currency)}</p>
                </div>
                <div className="total-row">
                  <p>
                    {t("Tax")} ({invoice.taxAmount}%):
                  </p>
                  <p>{formatCurrency(invoiceTax, currency)}</p>
                </div>
                <div className="total-row">
                  <p>
                    <strong>{t("Total tax included")}:</strong>
                  </p>
                  <p>
                    <strong>{formatCurrency(invoiceTotal, currency)}</strong>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Popover
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          anchorEl={anchorEl}
          open={calendarOpen}
          id={calendarId}
          onClose={handleCalendarClose}
        >
          <Calendar
            date={dayjs(invoice.date).toDate()}
            onChange={handleCalendarSelect}
          />
        </Popover>
        <UploadFilesDialog
          open={mediaDialogOpen}
          onClose={() => setMediaDialogOpen(false)}
          onSelect={setInvoiceLogo}
        />
      </>
    );
  }
);

const EditableField = ({
  value,
  onBlurCallback,
  placeholder,
  isEditing,
  setIsEditing,
  variant,
  params,
}) => {
  const [editingValue, setEditingValue] = useState(value);
  const inputRef = useRef(null);

  const handleBlur = () => {
    setIsEditing(false);
    onBlurCallback(editingValue);
  };

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  const getClassName = () => {
    switch (variant) {
      case "h1":
        return "invoice-h1";
      case "h2":
        return "invoice-h2";
      case "h3":
        return "invoice-h3";
      case "h4":
        return "invoice-h4";
      case "h5":
        return "invoice-h5";
      case "h6":
        return "invoice-h6";
      default:
        return "invoice-p";
    }
  };

  return isEditing ? (
    <TextField
      {...params}
      multiline
      value={editingValue}
      variant={"standard"}
      onBlur={handleBlur}
      onChange={(e) => setEditingValue(e.target.value)}
      placeholder={placeholder}
      className={`textareaAsTypography`}
      InputProps={{
        disableUnderline: true,
        className: `common-text-style ${getClassName()}`,
      }}
      inputProps={{ style: { backgroundColor: "#e1efff", padding: "0px" } }}
      inputRef={inputRef}
      autoFocus
    />
  ) : null;
};

export default React.memo(InvoiceA4);

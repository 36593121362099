import { useTranslation } from "react-i18next";

export function useCurrencyFormatter() {
  const { i18n } = useTranslation();
  // TODO: Make this work at organization level
  function formatCurrency(amount, currency) {
    const localeMap = {
      en: "en-US",
      es: "es-ES",
      // Add more mappings as needed
    };

    const locale = localeMap[i18n.language] || "en-US";

    const format = new Intl.NumberFormat(locale, {
      style: "currency",
      currency: currency,
    });

    return format.format(amount);
  }

  return { formatCurrency };
}

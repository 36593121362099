import * as React from "react";
import { useEffect, useState } from "react";
import { Typography, Box, Stack, Tabs, Tab } from "@mui/material";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import RepairsDoneTable from "./RepairsDone";
import RepairsRevenuePerUser from "./RepairsRevenuePerUser";

const ChartsPageV2 = () => {
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <>
      <Box
        className={"container mainContainer docsContainer "}
        sx={{ px: "20px", py: "10px" }}
      >
        <div className="headerPush"></div>
        <Stack direction={"row"} alignItems={"flex-start"} spacing={1}>
          <Typography variant="h3">{t("charts.title")}</Typography>
        </Stack>

        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          sx={{ marginBottom: "15px" }}
        >
          <Tab label={t("charts.tab.repairsDone")} />
          <Tab label={t("charts.tab.revenuePerUser")} />
        </Tabs>
        {/* <Divider sx={{ my: '15px' }} /> */}
        {tabValue === 0 && <RepairsDoneTable />}
        {tabValue === 1 && <RepairsRevenuePerUser />}
        {/* <RepairsDoneTable /> */}
      </Box>
    </>
  );
};

export default ChartsPageV2;

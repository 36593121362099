import * as React from "react";
import "./App.css";
import Main from "./containers/Main/Main";
import AuthPage from "./containers/LoginPage/AuthPage";
import AuthCard from "./containers/LoginPage/AuthCard";
import {
  Box,
  IconButton,
  Snackbar,
  Button,
  Alert,
  Typography,
  Stack,
  CardContent,
  AlertTitle,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
// TEST 3
import { useSelector } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material/styles";
// import { makeStyles, useTheme } from "@material-ui/core/styles";
import { SnackbarProvider } from "notistack";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { borderRadius } from "@mui/system";
import { RefreshOutlined } from "@mui/icons-material";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import { useTranslation } from "react-i18next";
import ConsoleHelper from "./utils/ConsoleHelper";
import theme from "./theme";
import AcceptOrganizationInvitation from "./containers/LoginPage/components/AcceptOrganizationInvitation";
import LoginForm from "./containers/LoginPage/components/LoginForm";
import RegisterForm from "./containers/LoginPage/components/RegisterForm";
import TokenGuard from "./containers/LoginPage/components/TokenGuard";
import { red, green, blue } from "@mui/material/colors";

import { useDispatch } from "react-redux";
import { is } from "react-date-range/dist/locale";
import { has } from "lodash";
import PasswordRecovery from "./containers/LoginPage/components/PasswordRecovery";

import ProtectedRoute from "ProtectedRoute";
import SubscriptionPage from "containers/Subscription/SubscriptionPage";
import VerifyUser from "containers/VerifyUser/VerifyUser";
import { QueryClient, QueryClientProvider } from "react-query";
const queryClient = new QueryClient();

const App = (props) => {
  require("dayjs/locale/es");
  const serverDown = useSelector((state) => state.app.serverDown);
  const updateAvailable = useSelector((state) => state.app.updateAvailable);
  const minimumVersionNotMet = useSelector(
    (state) => state.app.minimumVersionNotMet
  );
  // server is down
  const { t, i18n } = useTranslation();
  let dayjs = require("dayjs");
  dayjs.locale(i18n.language);
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth?.isAuth);
  const verified = useSelector((state) => state.auth?.verified);
  const activeSubscription = useSelector(
    (state) => state.auth?.isActiveSubscription
  );

  const reloadPage = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    //broadcast to all tabs
    const channel = new BroadcastChannel("app-version-channel");
    channel.postMessage({ action: "reload" });

    // window.location.reload(); // refresh the browser
  };


  return (
    <QueryClientProvider client={queryClient}>
      {process.env.NODE_ENV === "production" ? null : (
        <>
          <div className="developer-border">
            <div className="top"></div>
            <div className="bottom"></div>
          </div>
        </>
      )}
      <Box className={"sideNavVisible separateOnPageNav"}>
        <ThemeProvider theme={theme}>
          {" "}
          <SnackbarProvider maxSnack={3}>
            {serverDown ? (
              <Snackbar
                open={serverDown}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
              >
                <Stack
                  direction={"row"}
                  justifyContent={"center"}
                  sx={{
                    backgroundColor: red[500],
                    py: "5px",
                    px: "15px",
                    borderRadius: "10px",
                  }}
                  flex={1}
                >
                  <Typography color={"white"} variant="body">
                    {t("Service temporarily unavailable. Check back shortly.")}{" "}
                  </Typography>
                </Stack>
              </Snackbar>
            ) : null}

            <Snackbar open={updateAvailable && !minimumVersionNotMet}>
              <Alert
                severity="success"
                sx={{ width: "100%", border: "1px solid rgba(0,0,0,0.25)" }}
                icon={<WarningAmberRoundedIcon />}
                action={
                  <Button
                    onClick={reloadPage}
                    color="success"
                    variant="outlined"
                    size="small"
                    startIcon={<RefreshOutlined />}
                  >
                    {t("app.alert.updateAvailable.action")}
                  </Button>
                }
              >
                {t("app.alert.updateAvailable.message")}
              </Alert>
            </Snackbar>
            <Routes>
              <Route path="/auth" element={<AuthPage />}>
                <Route index element={<Navigate to="/auth/login" />} />
                <Route path="login" element={<LoginForm />} />
                <Route path="register" element={<RegisterForm />} />
                <Route
                  path="accept-organization-invitation/:token"
                  element={<AcceptOrganizationInvitation />}
                />
                <Route path="recover-password" element={<PasswordRecovery />} />
              </Route>
              <Route
                path="/verify"
                element={
                  <ProtectedRoute
                    isAllowed={auth && !verified}
                    redirectTo="/auth/login"
                  >
                    <AuthCard>
                      <CardContent sx={{ minWidth: "xs", maxWidth: "xs" }}>
                        <VerifyUser />
                      </CardContent>
                    </AuthCard>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/subscription"
                element={
                  <ProtectedRoute
                    isAllowed={auth && verified && !activeSubscription}
                    redirectTo="/verify"
                  >
                    <SubscriptionPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/*"
                element={
                  <ProtectedRoute
                    isAllowed={auth && verified}
                    redirectTo="/verify"
                  >
                    <ProtectedRoute
                      isAllowed={activeSubscription}
                      redirectTo="/subscription"
                    >
                      <Main />
                    </ProtectedRoute>
                  </ProtectedRoute>
                }
              />
            </Routes>

            <Dialog open={minimumVersionNotMet}>
              <DialogTitle>
                {t("app.dialogs.minimumVersionNotMet.title")}
              </DialogTitle>
              <DialogContent>
                <Alert severity="warning">
                  <AlertTitle>
                    {t("app.dialogs.minimumVersionNotMet.message")}
                  </AlertTitle>
                  {/* {warningText} */}
                  {t("app.dialogs.minimumVersionNotMet.secondaryMessage")}
                </Alert>
                {/* <DialogContentText>{t("app.dialogs.minimumVersionNotMet.message")}</DialogContentText> */}
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={reloadPage}
                  color="success"
                  variant="outlined"
                  size="small"
                  startIcon={<RefreshOutlined />}
                >
                  {t("app.dialogs.minimumVersionNotMet.action")}
                </Button>
              </DialogActions>
            </Dialog>
          </SnackbarProvider>
        </ThemeProvider>
      </Box>
    </QueryClientProvider>
  );
};

export default App;

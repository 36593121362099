import {
  AddOutlined,
  ArrowForwardIosOutlined,
  ArrowForwardOutlined,
  KeyboardArrowRightRounded,
  LocationOnOutlined,
  Close,
  SearchOutlined,
  Image,
  Add,
  Remove,
} from "@mui/icons-material";
import {
  Typography,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Stack,
  Button,
  TextField,
  Select,
  Popover,
  ToggleButton,
  ToggleButtonGroup,
  MenuItem,
  FormControl,
  Box,
  TablePagination,
  IconButton,
  InputAdornment,
  LinearProgress,
} from "@mui/material";

import { useTheme } from "@mui/material/styles";

import axios from "axios-config";

import { t } from "i18next";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { string, z } from "zod";
import { set } from "lodash";
import LocationsDialog from "../AllProducts/LocationsDialog";
import ConsoleHelper from "utils/ConsoleHelper";
import AddNewProductDialog from "../AllProducts/AddNewProductDialog";
import EditProductDialog from "../AllProducts/EditProductDialog";
import theme from "theme";
import { useSnackbar } from "notistack";
import Fuse from "fuse.js";

const Inventory = ({ warehouse }) => {
  const [products, setProducts] = useState([]);
  const [newProductDialogOpen, setNewProductDialogOpen] = useState(false);
  const [locationsDialogOpen, setLocationsDialogOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null); //

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [searchTerm, setSearchTerm] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const fetchProducts = async () => {
    try {
      // Make API call or database query to fetch inventory data
      setIsFetching(true);
      const response = await axios.get(
        `/inventory/warehouse/${warehouse.id}/products`
      );
      if (response.status === 200) {
        const data = await response.data;
        ConsoleHelper(data);
        setProducts(data);
      }
    } catch (error) {
      console.error("Error fetching inventory data:", error);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    // Fetch inventory data from API
    fetchProducts();
  }, [warehouse]);

  const handleNewProductDialogClose = () => {
    setNewProductDialogOpen(false);
  };
  const handleLocationsDialogClose = () => {
    setLocationsDialogOpen(false);
  };

  const handleLocationsDialogOpen = () => {
    setLocationsDialogOpen(true);
  };

  const handleNewProductDialogOpen = () => {
    setNewProductDialogOpen(true);
  };

  const openProductEditDialog = (product) => {
    setSelectedProduct(product);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    //set page to 0
    setPage(0);
  };

  const options = {
    keys: ["name", "sku", "barcode"],
    threshold: 0.1,
  };
  const fuse = new Fuse(products, options);

  const filteredProducts =
    searchTerm.length > 0
      ? fuse.search(searchTerm).map((result) => result.item)
      : products;

  // const filteredProducts = products.filter((item) => {
  //   const terms = searchTerm.toLowerCase().split(" ");
  //   return terms.every(
  //     (term) =>
  //       item.name.toLowerCase().includes(term) ||
  //       item.sku.toLowerCase().includes(term) ||
  //       (item.barcode && item.barcode.toLowerCase().includes(term))
  //   );
  // });

  const handleAddQuantity = async (product, quantity) => {
    try {
      const response = await axios.patch(
        `/inventory/warehouse/${warehouse.id}/products/${product.id}/add-quantity`,
        { quantity: parseInt(quantity) }
      );
      if (response.status === 200) {
        enqueueSnackbar("Quantity added!", { variant: "success" });
        const data = await response.data;
        ConsoleHelper(data);
        fetchProducts();
      }
    } catch (error) {
      console.error("Error adding quantity:", error);
    }
  };

  const handleSetQuantity = async (product, quantity) => {
    try {
      const response = await axios.patch(
        `/inventory/warehouse/${warehouse.id}/products/${product.id}/set-quantity`,
        { quantity: parseInt(quantity) }
      );
      if (response.status === 200) {
        const data = await response.data;
        enqueueSnackbar("Quantity set!", { variant: "success" });
        ConsoleHelper(data);
        fetchProducts();
      }
    } catch (error) {
      console.error("Error adding quantity:", error);
    }
  };
  return (
    <>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Typography variant="body">{t("Manage warehouse stock")}</Typography>
        {/* <Select>
                <option value="all">All</option>
                <option value="active">Active</option>
            </Select> */}
        <Stack direction={"row"} spacing={1} alignItems={"center"}>
          <TextField
            // label="Search"
            hiddenLabel
            variant="filled"
            placeholder={t("Search")}
            value={searchTerm}
            onChange={handleSearchChange}
            size="small"
            InputProps={{
              startAdornment: <SearchOutlined />,
              endAdornment:
                searchTerm.length > 0 ? (
                  <IconButton onClick={() => setSearchTerm("")}>
                    <Close />
                  </IconButton>
                ) : (
                  <IconButton disabled style={{ color: "transparent" }}>
                    <Close />
                  </IconButton>
                ),
            }}
          />
          <FormControl>
            {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
            <Select
              size="small"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={warehouse.name}
              // label="Age"
              // onChange={handleChange}
            >
              <MenuItem value={warehouse.name}>{warehouse.name}</MenuItem>
            </Select>
          </FormControl>

          <Button
            variant="outlined"
            color="primary"
            startIcon={<LocationOnOutlined />}
            onClick={handleLocationsDialogOpen}
          >
            {t("Manage locations")}
          </Button>
          {/* <Button variant='contained' color='primary' startIcon={<AddOutlined />} onClick={handleNewProductDialogOpen} >{t("New Product")}</Button> */}
        </Stack>
      </Stack>
      <TablePagination
        rowsPerPageOptions={[15, 30, 60]}
        component="div"
        count={filteredProducts.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={t("Rows per page")}
      />
      {isFetching ? (
        <LinearProgress sx={{ width: "100%", zIndex: 1000 }} />
      ) : (
        <LinearProgress sx={{ width: "0%" }} />
      )}
      <TableContainer
        component={Paper}
        elevation={0}
        sx={{ border: "1px solid lightGrey", my: "15px" }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {/* <TableCell style={{ width: '2%' }}></TableCell> */}
              <TableCell style={{ width: "5%" }}>{t("Photo")}</TableCell>
              <TableCell style={{ width: "33%" }} align="left">
                {t("Name")}
              </TableCell>
              <TableCell align="left">SKU</TableCell>
              <TableCell align="left">{t("Location in Warehouse")}</TableCell>
              {/* <TableCell align="right">Incoming</TableCell> */}
              <TableCell align="right">{t("Available")}</TableCell>
              <TableCell align="right">
                {t("Edit quantity Available")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!isFetching && products.length == 0 && (
              <TableRow>
                <TableCell colSpan={8} align="center">
                  <Typography variant="body2">
                    {t("No products found")}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            {filteredProducts
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((item, index) => (
                <ProductTableRow
                  key={item.id}
                  item={item}
                  openProductEditDialog={openProductEditDialog}
                  warehouse={warehouse}
                  addQuantity={handleAddQuantity}
                  setQuantity={handleSetQuantity}
                  striped={index % 2 !== 0}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[15, 30, 60]}
        component="div"
        count={filteredProducts.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={t("Rows per page")}
      />

      <AddNewProductDialog
        open={newProductDialogOpen}
        onClose={handleNewProductDialogClose}
        onNewProductAdded={fetchProducts}
        warehouse={warehouse}
      />
      <LocationsDialog
        open={locationsDialogOpen}
        onClose={handleLocationsDialogClose}
        warehouse={warehouse}
        onLocationChange={fetchProducts}
      />
      <EditProductDialog
        open={!!selectedProduct}
        onClose={() => setSelectedProduct(null)}
        onProductUpdated={fetchProducts}
        product={selectedProduct}
        warehouse={warehouse}
        setProduct={setSelectedProduct}
      />
    </>
  );
};

const ProductTableRow = ({
  item,
  openProductEditDialog,
  warehouse,
  addQuantity,
  setQuantity,
  striped,
}) => {
  const [hover, setHover] = useState(false);
  const [open, setOpen] = useState(false);
  const [imageAnchorEl, setImageAnchorEl] = useState(null);
  const [quantityInput, setQuantityInput] = useState("");
  const theme = useTheme();

  const [operation, setOperation] = useState("add");
  const imagePopover = Boolean(imageAnchorEl);

  const handleImagePopoverOpen = (event) => {
    setImageAnchorEl(event.currentTarget);
  };

  const handleImagePopoverClose = () => {
    setImageAnchorEl(null);
  };
  const handleSetQuantityInput = (e) => {
    let inputValue = e.target.value;
    let minValue = 0;
    let maxValue = 999999;
    let integersOnly = true;
    // Allow only digits and optionally a decimal point
    const isValid = /^[\d.]*$/.test(inputValue);
    if (!isValid) return;

    // Check minimum value for numeric input
    if (minValue && parseFloat(inputValue) < minValue) return;
    if (
      (maxValue && parseFloat(inputValue) > maxValue) ||
      inputValue.length > 6
    ) {
      // inputValue = maxValue.toString();
      return;
    }

    // Check if integersOnly and the value contains a decimal point
    if (integersOnly && inputValue.includes(".")) return;

    setQuantityInput(inputValue); // Use the setValue passed from parent
  };

  const handleOperationSelect = (event, newOperation) => {
    if (newOperation == null) return;
    setOperation(newOperation);
  };

  const handleSave = () => {
    if (quantityInput == "") return;
    if (operation == "add") {
      addQuantity(item, parseInt(quantityInput));
      setQuantityInput("");
    }
    if (operation == "set") {
      // set quantity
      setQuantity(item, parseInt(quantityInput));
      setQuantityInput("");
    }
  };

  const handleAddQuantity = () => {
    if (quantityInput == "") {
      setQuantityInput(1);
      return;
    }
    setQuantityInput(parseInt(quantityInput) + 1);
  };

  const handleSubstractQuantity = () => {
    if (quantityInput == "") {
      setQuantityInput(0);
      return;
    }
    if (parseInt(quantityInput) > 0) {
      setQuantityInput(parseInt(quantityInput) - 1);
    } else {
      setQuantityInput(0);
    }
  };

  if (!warehouse) return null;
  const firstImage = item.productImages[0];
  const imageUrl = `${process.env.REACT_APP_API_URL}${firstImage?.imageUrl}`;
  return (
    <>
      <TableRow
        key={item.id}
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
          backgroundColor: striped ? "action.hover" : "inherit",
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        {/* <TableCell component="th" scope="row">
                    {item.id}
                </TableCell> */}
        <TableCell align="left">
          {/* On hover show a popup */}
          {firstImage?.imageUrl && (
            <>
              <Box
                aria-owns={imagePopover ? "mouse-over-popover" : undefined}
                aria-haspopup="true"
                onMouseEnter={handleImagePopoverOpen}
                onMouseLeave={handleImagePopoverClose}
              >
                <img
                  src={imageUrl}
                  alt="Product image"
                  style={{
                    width: "50px",
                    height: "50px",
                    borderRadius: "5px",
                    border: "1px solid",
                    borderColor: "gray",
                  }}
                />
              </Box>
            </>
          )}
          {!firstImage?.imageUrl && (
            <Box
              sx={{
                height: "50px",
                width: "50px",
                borderRadius: "5px",
                border: "1px solid",
                borderColor: "lightGray",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Image sx={{ color: "lightGray", fontSize: "35px" }} />
            </Box>
          )}
        </TableCell>
        <TableCell align="left">
          <Typography
            variant="body2"
            sx={{
              cursor: "pointer",
              color: "primary.main",
              "&:hover": {
                textDecoration: "underline",
                color: "primary.dark",
              },
            }}
            onClick={() => openProductEditDialog(item)}
          >
            {item.name}
          </Typography>
        </TableCell>
        <TableCell align="left">{item.sku}</TableCell>
        {/* <TableCell align="left">Magazin / Atelier / Billy 1 / A1</TableCell> */}
        <TableCell align="left">
          <Stack direction={"row"} alignItems={"center"}>
            {item.inventoryLocation?.fullPath}{" "}
            {item.inventoryLocation.isCellHolder ? (
              <>
                {" "}
                / R{item.rowInCell} C{item.columnInCell}
              </>
            ) : null}
          </Stack>
        </TableCell>
        {/* <TableCell align="right">0</TableCell> */}
        <TableCell align="right">
          <Stack
            direction={"row"}
            justifyContent={"flex-end"}
            alignItems={"center"}
          >
            {item.quantityInStock}
            {quantityInput !== "" && (
              <Stack direction={"row"} alignItems={"center"}>
                <KeyboardArrowRightRounded />
                <Typography
                  variant="body2"
                  sx={{
                    backgroundColor: theme.palette.highlightYellow.main,
                  }}
                >
                  {operation == "add" && (
                    <>
                      {parseInt(item.quantityInStock) + parseInt(quantityInput)}
                    </>
                  )}
                  {operation == "set" && <>{quantityInput}</>}
                </Typography>
              </Stack>
            )}
          </Stack>
        </TableCell>
        <TableCell align="right">
          <Stack direction={"row"} spacing={1} justifyContent={"flex-end"}>
            <ToggleButtonGroup
              size="small"
              value={operation}
              exclusive
              onChange={handleOperationSelect}
              aria-label="text alignment"
            >
              <ToggleButton
                value="add"
                aria-label="left aligned"
                sx={{ minWidth: "50px" }}
              >
                {t("Add")}
              </ToggleButton>
              <ToggleButton
                value="set"
                aria-label="left aligned"
                sx={{ minWidth: "50px" }}
              >
                {t("Set")}
              </ToggleButton>
            </ToggleButtonGroup>
            <TextField
              size="small"
              variant="outlined"
              value={quantityInput}
              onChange={handleSetQuantityInput}
              sx={{
                minWidth: "150px",
                maxWidth: "150px",
                backgroundColor: "white",
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton size="small" onClick={handleSubstractQuantity}>
                      <Remove sx={{ fontSize: "15px" }} />
                    </IconButton>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton size="small" onClick={handleAddQuantity}>
                      <Add sx={{ fontSize: "15px" }} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              inputProps={{ style: { textAlign: "center" } }}
            />
            <Button
              disabled={quantityInput === ""}
              variant="contained"
              color="primary"
              size="small"
              onClick={handleSave}
            >
              {t("Save")}
            </Button>
          </Stack>
          {/* <ArrowForwardIosOutlined sx={{ fontSize: '15px', color: hover ? 'inherit' : 'transparent' }} /> */}
        </TableCell>
      </TableRow>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={imagePopover}
        anchorEl={imageAnchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handleImagePopoverClose}
        disableRestoreFocus
      >
        <Box
          sx={{
            width: "250px",
            height: "250px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
          }}
        >
          <img
            src={imageUrl}
            style={{
              maxWidth: "100%",
              maxHeight: "100%",
              objectFit: "contain",
            }}
          />
        </Box>
      </Popover>
    </>
  );
};
export default Inventory;

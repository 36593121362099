import { useState, useEffect } from "react";
import {
  Paper,
  Stack,
  Box,
  Typography,
  Popover,
  TextField,
} from "@mui/material";
import LogoButton from "../../components/Buttons/LogoButton";
//Date Picker
import { Calendar } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

import { organizationToInvoiceData } from "../../utils/InvoiceHelpers";

const SimplifiedInvoiceCreator = ({
  organization,
  invoiceLogo,
  setMediaDialogOpen,
  checkedOrders,
  billingFrom,
  setBillingFrom,
  refId,
  setRefId,
  terms,
  setTerms,
}) => {
  const [taxField, setTaxField] = useState(21);
  const [taxIncluded, setTaxIncluded] = useState(true);

  //Calendar Popover
  const [anchorEl, setAnchorEl] = useState(null);
  const calendarOpen = Boolean(anchorEl);
  const calendarId = calendarOpen ? "calendar-popover" : undefined;
  const [calendarDate, setCalendarDate] = useState(new Date());

  useEffect(() => {
    if (organization) {
      const data = organizationToInvoiceData(organization, true);
      setBillingFrom(data);
    }
  }, []);

  const dayjs = require("dayjs");
  const ticketLogo = () => {
    if (invoiceLogo) {
      return (
        <Box
          className="editableRow clickable"
          height={"100px"}
          p={"10px"}
          borderRadius={"10px"}
          width={"190px"}
          display={"block"}
          textAlign={"center"}
          onClick={() => setMediaDialogOpen(true)}
        >
          <img
            className="logo"
            src={`${process.env.REACT_APP_IMAGES_URL}${invoiceLogo.fileName}`}
            alt="logo"
          />
        </Box>
      );
    }
    return <LogoButton onClick={() => setMediaDialogOpen(true)} />;
  };
  const handleRefIdInput = (e) => {
    setRefId(e.target.value.replace(/\D/g, ""));
  };
  const handleCalendarOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCalendarClose = () => {
    setAnchorEl(null);
  };

  const handleCalendarSelect = (e) => {
    setCalendarDate(e);
  };

  let invoiceSubTotal = 0;
  let invoiceTax = 0;
  let invoiceTotal = 0;
  let rowsCounter = 0;

  return (
    <Paper sx={{ width: "80mm" }}>
      <Stack
        direction={"column"}
        sx={{
          display: "flex",
          paddingX: "5px",
          paddingY: "25px",
        }}
        spacing={1}
      >
        <Stack direction={"row"} justifyContent={"center"}>
          {ticketLogo()}
        </Stack>
        <Stack direction={"column"} spacing={0} alignItems={"center"}>
          <Stack direction={"row"} alignItems={"center"} px={"5px"}>
            <Typography>Simplified invoice #</Typography>
            <TextField
              sx={{
                width: refId.length > 0 ? `${refId.length + 1.5}ch` : "3ch",
              }}
              InputProps={{ disableUnderline: true }}
              variant={"standard"}
              className="textareaAsTypography"
              placeholder="RefID"
              value={refId}
              onChange={handleRefIdInput}
            />
          </Stack>

          <TextField
            multiline
            InputProps={{
              disableUnderline: true,
              min: 0,
              style: { textAlign: "center" },
            }}
            variant={"standard"}
            className="textareaAsTypography centerInput"
            value={billingFrom}
            onChange={(e) => setBillingFrom(e.target.value)}
          />

          <Stack
            direction={"row"}
            px={"5px"}
            className={"editableRow clickable"}
            onClick={handleCalendarOpen}
          >
            <Typography>
              Date: {dayjs(calendarDate).format("DD/MM/YYYY")}
            </Typography>
          </Stack>
        </Stack>
        <Popover
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          anchorEl={anchorEl}
          open={calendarOpen}
          id={calendarId}
          onClose={handleCalendarClose}
        >
          <Calendar date={calendarDate} onChange={handleCalendarSelect} />
        </Popover>
        <table className="simplifiedInvoiceTable">
          <thead>
            <tr>
              <td>
                <Typography>Item name</Typography>
              </td>
              <td>
                <Typography>Total</Typography>
              </td>
            </tr>
          </thead>
          <tbody>
            {checkedOrders.map((order, index) => {
              return order.services.map((service) => {
                const taxFieldValue =
                  parseFloat(taxField) >= 0 ? parseFloat(taxField) : 0;
                let taxPercentage = 1.0 + taxFieldValue / 100;
                // Tax included or excluded?

                let discount = service.discount
                  ? parseFloat(service.discount)
                  : 0;

                let unitPrice = service.unitPrice
                  ? parseFloat(service.unitPrice)
                  : 0;
                const quantity = service.quantity
                  ? parseInt(service.quantity)
                  : 1;
                let subTotal = 0;
                let tax = 0;
                let total = 0;
                if (!taxIncluded) {
                  subTotal = unitPrice * quantity - discount;
                  tax = subTotal * taxPercentage - subTotal;
                  total = subTotal + tax;
                } else {
                  subTotal = (unitPrice * quantity - discount) / taxPercentage;
                  total = unitPrice * quantity;
                  unitPrice = unitPrice;
                  discount = discount;
                  tax = total - total / taxPercentage;
                }

                invoiceSubTotal += subTotal;
                invoiceTax += tax;
                invoiceTotal += total - discount;
                rowsCounter++;

                return (
                  <tr key={rowsCounter}>
                    <td>
                      <Typography>
                        {order.brand} {order.model} - {service.name}
                      </Typography>
                      <Typography pl={2}>
                        {unitPrice.toFixed(2)} x{quantity}
                      </Typography>
                    </td>
                    <td>
                      <Typography>{total.toFixed(2)}</Typography>
                    </td>
                  </tr>
                );
              });
            })}
            {checkedOrders.map((order, index) => {
              return order.services.map((service) => {
                const taxFieldValue =
                  parseFloat(taxField) >= 0 ? parseFloat(taxField) : 0;
                let taxPercentage = 1.0 + taxFieldValue / 100;
                // Tax included or excluded?

                let discount = service.discount
                  ? parseFloat(service.discount)
                  : 0;
                if (discount <= 0) return null;
                rowsCounter++;
                return (
                  <tr key={rowsCounter}>
                    <td>
                      <Typography>
                        {order.brand} {order.model} - {service.name} (Discount)
                      </Typography>
                    </td>
                    <td>
                      <Typography>
                        -{discount > 0 ? discount.toFixed(2) : "asd"}
                      </Typography>
                    </td>
                  </tr>
                );
              });
            })}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={1}>
                <Typography>Total</Typography>
              </td>
              <td>
                <Typography>{invoiceTotal.toFixed(2)}</Typography>
              </td>
            </tr>
            <tr>
              <td colSpan={1}>
                <Typography>Base</Typography>
              </td>
              <td>
                <Typography> {invoiceSubTotal.toFixed(2)}</Typography>
              </td>
            </tr>
            <tr>
              <td colSpan={1}>
                <Typography>Tax {taxField.toFixed(2)}%</Typography>
              </td>
              <td>
                <Typography>
                  {(invoiceTotal - invoiceSubTotal).toFixed(2)}
                </Typography>
              </td>
            </tr>
          </tfoot>
        </table>
        <Stack
          direction={"row"}
          justifyContent={"center"}
          spacing={"3px"}
        ></Stack>

        <Stack direction={"row"} justifyContent={"center"} spacing={"3px"}>
          <TextField
            fullWidth
            multiline
            InputProps={{
              disableUnderline: true,
              min: 0,
              style: { textAlign: "center" },
            }}
            variant={"standard"}
            className="textareaAsTypography centerInput"
            value={terms}
            onChange={(e) => setTerms(e.target.value)}
            placeholder={"Add terms or note"}
          />{" "}
        </Stack>
      </Stack>
    </Paper>
  );
};

export default SimplifiedInvoiceCreator;

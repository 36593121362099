import React from "react";
import { Typography, Stack, Divider, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";

function DeviceSpecs({ deviceData }) {
  const theme = useTheme();
  const deviceSpecDetails = deviceData?.deviceSpecDetails;

  if (!deviceSpecDetails) return null;
  //Group specifications based on category.name
  const groupedSpecs = {};
  deviceSpecDetails.forEach(({ name, value, deviceSpecCategory }) => {
    const categoryName = deviceSpecCategory.name;
    if (!groupedSpecs[categoryName]) {
      groupedSpecs[categoryName] = [];
    }
    groupedSpecs[categoryName].push({ name, value });
  });
  return Object.entries(groupedSpecs).map(([categoryName, specs]) => (
    <Stack
      key={categoryName}
      direction="row"
      gap={3}
      sx={{
        backgroundColor: "white",
        margin: "6px 0",
        padding: "20px 10px",
        borderRadius: "10px",
        border: "1px solid #BBC2CD",
      }}
    >
      <Box minWidth={"100px"} maxWidth={"100px"}>
        <Typography
          variant="h5"
          sx={{
            color: theme.palette.black.main,
            padding: "8px 0",
          }}
        >
          {categoryName}
        </Typography>
      </Box>

      <Box flex={1}>
        {specs.map((spec, index) => (
          <Stack key={index}>
            <Stack
              direction={"row"}
              sx={{ padding: "10px 0 6px 0" }}
              spacing={2}
            >
              <Box minWidth={"150px"}>
                <Typography
                  variant="body"
                  sx={{ fontWeight: 600, color: "#5B5B5B" }}
                >
                  {spec.name}
                </Typography>
              </Box>
              <Box>
                <Typography variant="body">{spec.value}</Typography>
              </Box>
            </Stack>
            <Divider />
          </Stack>
        ))}
      </Box>
    </Stack>
  ));
}

export default DeviceSpecs;

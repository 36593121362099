import { useMutation } from "react-query";
import { downloadAllInvoices } from "api/documents";
import { useSnackbar } from "notistack"; // Correct import for useSnackbar
import { useTranslation } from "react-i18next";

const useDownloadInvoices = () => {
  const { enqueueSnackbar } = useSnackbar(); // Use the useSnackbar hook to get enqueueSnackbar
  const { t } = useTranslation();
  return useMutation(downloadAllInvoices, {
    onMutate: async ({ selectedInvoices }) => {
      enqueueSnackbar(t("invoices.downloadingAlert"), { variant: "info" });
    },
    onSuccess: (data) => {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "AllInvoice.zip");
      document.body.appendChild(link); // This line is required for Firefox
      link.click();

      // Cleanup
      link.remove();
      window.URL.revokeObjectURL(url);
    },
    onError: (error) => {
      console.error(error);
      enqueueSnackbar("Failed to download invoices", { variant: "error" });
    },
  });
};

export default useDownloadInvoices;

export function formatCurrency(amount, currency) {
  // console.log('amount', amount);
  // console.log('currency', currency);
  if (!currency || currency === "undefined") {
    // don't format if currency is undefined
    return amount;
  }
  const format = new Intl.NumberFormat("en", {
    style: "currency",
    currency: currency,
  });

  return format.format(amount);
}

export function getCurrencySymbol(currency) {
  // ConsoleHelper(uniqueCurrencies[currency], 'currency')
  if (uniqueCurrencies[currency] === undefined) {
    return currency;
  }
  return uniqueCurrencies[currency];
}

var currencyAndSymbol = ["currency", "symbol"];
var allSearchParams = currencyAndSymbol.concat(["countryName", "dateFormat"]);

export const getAllISOCodes = function () {
  const keys = Object.keys(countries);

  return keys.map(function (key) {
    const ISOObject = countries[key];

    return {
      iso: key,
      currency: ISOObject.currency,
      symbol: ISOObject.symbol,
      countryName: ISOObject.countryName,
      dateFormat: ISOObject.dateFormat,
    };
  });
};

export const getAllInfoByISO = function (isoCode) {
  const iso = isoCode.toUpperCase();

  if (countries.hasOwnProperty(iso)) {
    const ISOObject = countries[iso];

    return {
      iso,
      currency: ISOObject.currency,
      symbol: ISOObject.symbol,
      countryName: ISOObject.countryName,
      dateFormat: ISOObject.dateFormat,
    };
  }
  throw new Error("ISO2 code wasn't found");
};

export const getParamByISO = function (iso, param) {
  checkParam(param, allSearchParams);

  if (countries.hasOwnProperty(iso.toUpperCase())) {
    return countries[iso.toUpperCase()][param];
  }
  throw new Error("ISO2 code wasn't found");
};

export const getISOByParam = function (param, value) {
  checkParam(param, allSearchParams);

  for (let key in countries) {
    if (countries.hasOwnProperty(key) && countries[key][param] === value) {
      return key;
    }
  }
  throw new Error(value + " wasn't found in " + param);
};

export const getParamByParam = function (
  givenParam,
  givenParamValue,
  searchParam
) {
  checkParam(givenParam, allSearchParams);
  checkParam(searchParam, allSearchParams);

  for (let key in countries) {
    if (
      countries.hasOwnProperty(key) &&
      countries[key][givenParam] === givenParamValue
    ) {
      return countries[key][searchParam];
    }
  }
  throw new Error(givenParam + " wasn't found in " + givenParamValue);
};

export const getAllCountriesByCurrencyOrSymbol = function (param, value) {
  var countriesArray = [];

  checkParam(param, currencyAndSymbol);

  for (let key in countries) {
    if (countries.hasOwnProperty(key) && countries[key][param] === value) {
      countriesArray.push(countries[key].countryName);
    }
  }

  if (countriesArray.length === 0) {
    throw new Error(value + " wasn't found in" + param);
  }

  return countriesArray;
};

export const getAllISOByCurrencyOrSymbol = function (param, value) {
  var ISOArray = [];

  checkParam(param, currencyAndSymbol);

  for (let key in countries) {
    if (countries.hasOwnProperty(key) && countries[key][param] === value) {
      ISOArray.push(key);
    }
  }

  if (ISOArray.length === 0) {
    throw new Error(value + " wasn't found in" + param);
  }

  return ISOArray;
};

function checkParam(param, paramArray) {
  if (paramArray.indexOf(param) === -1) {
    throw new Error("Invalid search param");
  }
}

export const countries = {
  AD: {
    countryName: "Andorra",
    currency: "EUR",
    symbol: "€",
    flag: "<img src='PNG/AD@2x.png?raw=true' width='21' height='15' />",
  },
  AE: {
    countryName: "United Arab Emirates",
    currency: "AED",
    symbol: "AED",
    dateFormat: "dd/MM/yyyy",
    flag: "<img src='PNG/AE@2x.png?raw=true' width='21' height='15'>",
  },
  AF: {
    countryName: "Afghanistan",
    currency: "AFN",
    symbol: "Af",
    flag: "<img src='PNG/AF@2x.png?raw=true' width='21' height='15'>",
  },
  AG: {
    countryName: "Antigua and Barbuda",
    currency: "XCD",
    symbol: "XCD",
    flag: "<img src='PNG/AG@2x.png?raw=true' width='21' height='15'>",
  },
  AI: {
    countryName: "Anguilla",
    currency: "XCD",
    symbol: "XCD",
    flag: "<img src='PNG/AI@2x.png?raw=true' width='21' height='15'>",
  },
  AL: {
    countryName: "Albania",
    currency: "ALL",
    symbol: "ALL",
    dateFormat: "yyyy-MM-dd",
    flag: "<img src='PNG/AL@2x.png?raw=true' width='21' height='15'>",
  },
  AM: {
    countryName: "Armenia",
    currency: "AMD",
    symbol: "AMD",
    flag: "<img src='PNG/AM@2x.png?raw=true' width='21' height='15'>",
  },
  AO: {
    countryName: "Angola",
    currency: "AOA",
    symbol: "AOA",
    flag: "<img src='PNG/AO@2x.png?raw=true' width='21' height='15'>",
  },
  AR: {
    countryName: "Argentina",
    currency: "ARS",
    symbol: "AR$",
    dateFormat: "dd/MM/yyyy",
    flag: "<img src='PNG/AR@2x.png?raw=true' width='21' height='15'>",
  },
  AS: {
    countryName: "American Samoa",
    currency: "USD",
    symbol: "$",
    flag: "<img src='PNG/AS@2x.png?raw=true' width='21' height='15'>",
  },
  AT: {
    countryName: "Austria",
    currency: "EUR",
    symbol: "€",
    dateFormat: "dd.MM.yyyy",
    flag: "<img src='PNG/AT@2x.png?raw=true' width='21' height='15'>",
  },
  AU: {
    countryName: "Australia",
    currency: "AUD",
    symbol: "AU$",
    dateFormat: "d/MM/yyyy",
    flag: "<img src='PNG/AU@2x.png?raw=true' width='21' height='15'>",
  },
  AW: {
    countryName: "Aruba",
    currency: "AWG",
    symbol: "AWG",
    flag: "<img src='PNG/AW@2x.png?raw=true' width='21' height='15'>",
  },
  AX: {
    countryName: "Aland Islands",
    currency: "EUR",
    symbol: "€",
    flag: "<img src='PNG/AX@2x.png?raw=true' width='21' height='15'>",
  },
  AZ: {
    countryName: "Azerbaijan",
    currency: "AZN",
    symbol: "man.",
    flag: "<img src='PNG/AZ@2x.png?raw=true' width='21' height='15'>",
  },
  BA: {
    countryName: "Bosnia and Herzegovina",
    currency: "BAM",
    symbol: "KM",
    dateFormat: "yyyy-MM-dd",
    flag: "<img src='PNG/BA@2x.png?raw=true' width='21' height='15'>",
  },
  BB: {
    countryName: "Barbados",
    currency: "BBD",
    symbol: "BBD",
    flag: "<img src='PNG/BB@2x.png?raw=true' width='21' height='15'>",
  },
  BD: {
    countryName: "Bangladesh",
    currency: "BDT",
    symbol: "Tk",
    flag: "<img src='PNG/BD@2x.png?raw=true' width='21' height='15'>",
  },
  BE: {
    countryName: "Belgium",
    currency: "EUR",
    symbol: "€",
    dateFormat: "d/MM/yyyy",
    flag: "<img src='PNG/BE@2x.png?raw=true' width='21' height='15'>",
  },
  BF: {
    countryName: "Burkina Faso",
    currency: "XOF",
    symbol: "CFA",
    flag: "<img src='PNG/BF@2x.png?raw=true' width='21' height='15'>",
  },
  BG: {
    countryName: "Bulgaria",
    currency: "BGN",
    symbol: "BGN",
    dateFormat: "yyyy-M-d",
    flag: "<img src='PNG/BG@2x.png?raw=true' width='21' height='15'>",
  },
  BH: {
    countryName: "Bahrain",
    currency: "BHD",
    symbol: "BD",
    dateFormat: "dd/MM/yyyy",
    flag: "<img src='PNG/BH@2x.png?raw=true' width='21' height='15'>",
  },
  BI: {
    countryName: "Burundi",
    currency: "BIF",
    symbol: "FBu",
    flag: "<img src='PNG/BI@2x.png?raw=true' width='21' height='15'>",
  },
  BJ: {
    countryName: "Benin",
    currency: "XOF",
    symbol: "CFA",
    flag: "<img src='PNG/BJ@2x.png?raw=true' width='21' height='15'>",
  },
  BL: {
    countryName: "Saint Barthelemy",
    currency: "EUR",
    symbol: "€",
    flag: "<img src='PNG/BL@2x.png?raw=true' width='21' height='15'>",
  },
  BM: {
    countryName: "Bermuda",
    currency: "BMD",
    symbol: "BMD",
    flag: "<img src='PNG/BM@2x.png?raw=true' width='21' height='15'>",
  },
  BN: {
    countryName: "Brunei",
    currency: "BND",
    symbol: "BN$",
    flag: "<img src='PNG/BN@2x.png?raw=true' width='21' height='15'>",
  },
  BO: {
    countryName: "Bolivia",
    currency: "BOB",
    symbol: "Bs",
    dateFormat: "dd-MM-yyyy",
    flag: "<img src='PNG/BO@2x.png?raw=true' width='21' height='15'>",
  },
  BQ: {
    countryName: "Bonaire, Saint Eustatius and Saba ",
    currency: "USD",
    symbol: "$",
  },
  BR: {
    countryName: "Brazil",
    currency: "BRL",
    symbol: "R$",
    dateFormat: "dd/MM/yyyy",
    flag: "<img src='PNG/BR@2x.png?raw=true' width='21' height='15'>",
  },
  BS: {
    countryName: "Bahamas",
    currency: "BSD",
    symbol: "BSD",
    flag: "<img src='PNG/BS@2x.png?raw=true' width='21' height='15'>",
  },
  BT: {
    countryName: "Bhutan",
    currency: "BTN",
    symbol: "BTN",
    flag: "<img src='PNG/BT@2x.png?raw=true' width='21' height='15'>",
  },
  BV: {
    countryName: "Bouvet Island",
    currency: "NOK",
    symbol: "Nkr",
    flag: "<img src='PNG/BV@2x.png?raw=true' width='21' height='15'>",
  },
  BW: {
    countryName: "Botswana",
    currency: "BWP",
    symbol: "BWP",
    flag: "<img src='PNG/BW@2x.png?raw=true' width='21' height='15'>",
  },
  BY: {
    countryName: "Belarus",
    currency: "BYN",
    symbol: "BYN",
    dateFormat: "d.M.yyyy",
    flag: "<img src='PNG/BY@2x.png?raw=true' width='21' height='15'>",
  },
  BZ: {
    countryName: "Belize",
    currency: "BZD",
    symbol: "BZ$",
    flag: "<img src='PNG/BZ@2x.png?raw=true' width='21' height='15'>",
  },
  CA: {
    countryName: "Canada",
    currency: "CAD",
    symbol: "CA$",
    dateFormat: "dd/MM/yyyy",
    flag: "<img src='PNG/CA@2x.png?raw=true' width='21' height='15'>",
  },
  CC: {
    countryName: "Cocos Islands",
    currency: "AUD",
    symbol: "AU$",
    flag: "<img src='PNG/CC@2x.png?raw=true' width='21' height='15'>",
  },
  CD: {
    countryName: "Democratic Republic of the Congo",
    currency: "CDF",
    symbol: "CDF",
    flag: "<img src='PNG/CD@2x.png?raw=true' width='21' height='15'>",
  },
  CF: {
    countryName: "Central African Republic",
    currency: "XAF",
    symbol: "FCFA",
    flag: "<img src='PNG/CF@2x.png?raw=true' width='21' height='15'>",
  },
  CG: {
    countryName: "Republic of the Congo",
    currency: "XAF",
    symbol: "FCFA",
    flag: "<img src='PNG/CG@2x.png?raw=true' width='21' height='15'>",
  },
  CH: {
    countryName: "Switzerland",
    currency: "CHF",
    symbol: "CHF",
    dateFormat: "dd.MM.yyyy",
    flag: "<img src='PNG/CH@2x.png?raw=true' width='21' height='15'>",
  },
  CI: {
    countryName: "Ivory Coast",
    currency: "XOF",
    symbol: "CFA",
    flag: "<img src='PNG/CI@2x.png?raw=true' width='21' height='15'>",
  },
  CK: {
    countryName: "Cook Islands",
    currency: "NZD",
    symbol: "NZ$",
    flag: "<img src='PNG/CK@2x.png?raw=true' width='21' height='15'>",
  },
  CL: {
    countryName: "Chile",
    currency: "CLP",
    symbol: "CL$",
    dateFormat: "dd-MM-yyyy",
    flag: "<img src='PNG/CL@2x.png?raw=true' width='21' height='15'>",
  },
  CM: {
    countryName: "Cameroon",
    currency: "XAF",
    symbol: "FCFA",
    flag: "<img src='PNG/CM@2x.png?raw=true' width='21' height='15'>",
  },
  CN: {
    countryName: "China",
    currency: "CNY",
    symbol: "CN¥",
    dateFormat: "yyyy-M-d",
    flag: "<img src='PNG/CN@2x.png?raw=true' width='21' height='15'>",
  },
  CO: {
    countryName: "Colombia",
    currency: "COP",
    symbol: "CO$",
    dateFormat: "d/MM/yyyy",
    flag: "<img src='PNG/CO@2x.png?raw=true' width='21' height='15'>",
  },
  CR: {
    countryName: "Costa Rica",
    currency: "CRC",
    symbol: "₡",
    dateFormat: "dd/MM/yyyy",
    flag: "<img src='PNG/CR@2x.png?raw=true' width='21' height='15'>",
  },
  CU: {
    countryName: "Cuba",
    currency: "CUP",
    symbol: "CUP",
    flag: "<img src='PNG/CU@2x.png?raw=true' width='21' height='15'>",
  },
  CV: {
    countryName: "Cape Verde",
    currency: "CVE",
    symbol: "CV$",
    flag: "<img src='PNG/CV@2x.png?raw=true' width='21' height='15'>",
  },
  CW: {
    countryName: "Curacao",
    currency: "ANG",
    symbol: "ANG",
    flag: "<img src='PNG/CW@2x.png?raw=true' width='21' height='15'>",
  },
  CX: {
    countryName: "Christmas Island",
    currency: "AUD",
    symbol: "AU$",
    flag: "<img src='PNG/CX@2x.png?raw=true' width='21' height='15'>",
  },
  CY: {
    countryName: "Cyprus",
    currency: "EUR",
    symbol: "€",
    dateFormat: "dd/MM/yyyy",
    flag: "<img src='PNG/CY@2x.png?raw=true' width='21' height='15'>",
  },
  CZ: {
    countryName: "Czech Republic",
    currency: "CZK",
    symbol: "Kč",
    dateFormat: "d.M.yyyy",
    flag: "<img src='PNG/CZ@2x.png?raw=true' width='21' height='15'>",
  },
  DE: {
    countryName: "Germany",
    currency: "EUR",
    symbol: "€",
    dateFormat: "dd.MM.yyyy",
    flag: "<img src='PNG/DE@2x.png?raw=true' width='21' height='15'>",
  },
  DJ: {
    countryName: "Djibouti",
    currency: "DJF",
    symbol: "Fdj",
    flag: "<img src='PNG/DJ@2x.png?raw=true' width='21' height='15'>",
  },
  DK: {
    countryName: "Denmark",
    currency: "DKK",
    symbol: "Dkr",
    dateFormat: "dd-MM-yyyy",
    flag: "<img src='PNG/DK@2x.png?raw=true' width='21' height='15'>",
  },
  DM: {
    countryName: "Dominica",
    currency: "XCD",
    symbol: "XCD",
    flag: "<img src='PNG/DM@2x.png?raw=true' width='21' height='15'>",
  },
  DO: {
    countryName: "Dominican Republic",
    currency: "DOP",
    symbol: "RD$",
    dateFormat: "MM/dd/yyyy",
    flag: "<img src='PNG/DO@2x.png?raw=true' width='21' height='15'>",
  },
  DZ: {
    countryName: "Algeria",
    currency: "DZD",
    symbol: "DA",
    dateFormat: "dd/MM/yyyy",
    flag: "<img src='PNG/DZ@2x.png?raw=true' width='21' height='15'>",
  },
  EC: {
    countryName: "Ecuador",
    currency: "USD",
    symbol: "$",
    dateFormat: "dd/MM/yyyy",
    flag: "<img src='PNG/EC@2x.png?raw=true' width='21' height='15'>",
  },
  EE: {
    countryName: "Estonia",
    currency: "EUR",
    symbol: "€",
    dateFormat: "d.MM.yyyy",
    flag: "<img src='PNG/EE@2x.png?raw=true' width='21' height='15'>",
  },
  EG: {
    countryName: "Egypt",
    currency: "EGP",
    symbol: "EGP",
    dateFormat: "dd/MM/yyyy",
    flag: "<img src='PNG/EG@2x.png?raw=true' width='21' height='15'>",
  },
  EH: {
    countryName: "Western Sahara",
    currency: "MAD",
    symbol: "MAD",
  },
  ER: {
    countryName: "Eritrea",
    currency: "ERN",
    symbol: "Nfk",
    flag: "<img src='PNG/ER@2x.png?raw=true' width='21' height='15'>",
  },
  ES: {
    countryName: "Spain",
    currency: "EUR",
    symbol: "€",
    dateFormat: "dd/MM/yyyy",
    flag: "<img src='PNG/ES@2x.png?raw=true' width='21' height='15'>",
  },
  ET: {
    countryName: "Ethiopia",
    currency: "ETB",
    symbol: "Br",
    flag: "<img src='PNG/ET@2x.png?raw=true' width='21' height='15'>",
  },
  FI: {
    countryName: "Finland",
    currency: "EUR",
    symbol: "€",
    dateFormat: "d.M.yyyy",
    flag: "<img src='PNG/FI@2x.png?raw=true' width='21' height='15'>",
  },
  FJ: {
    countryName: "Fiji",
    currency: "FJD",
    symbol: "FJD",
    flag: "<img src='PNG/FJ@2x.png?raw=true' width='21' height='15'>",
  },
  FK: {
    countryName: "Falkland Islands",
    currency: "FKP",
    symbol: "FKP",
    flag: "<img src='PNG/FK@2x.png?raw=true' width='21' height='15'>",
  },
  FM: {
    countryName: "Micronesia",
    currency: "USD",
    symbol: "$",
    flag: "<img src='PNG/FM@2x.png?raw=true' width='21' height='15'>",
  },
  FO: {
    countryName: "Faroe Islands",
    currency: "DKK",
    symbol: "Dkr",
    flag: "<img src='PNG/FO@2x.png?raw=true' width='21' height='15'>",
  },
  FR: {
    countryName: "France",
    currency: "EUR",
    symbol: "€",
    dateFormat: "dd/MM/yyyy",
    flag: "<img src='PNG/FR@2x.png?raw=true' width='21' height='15'>",
  },
  GA: {
    countryName: "Gabon",
    currency: "XAF",
    symbol: "FCFA",
    flag: "<img src='PNG/GA@2x.png?raw=true' width='21' height='15'>",
  },
  GB: {
    countryName: "United Kingdom",
    currency: "GBP",
    symbol: "£",
    dateFormat: "dd/MM/yyyy",
    flag: "<img src='PNG/GB@2x.png?raw=true' width='21' height='15'>",
  },
  GD: {
    countryName: "Grenada",
    currency: "XCD",
    symbol: "XCD",
    flag: "<img src='PNG/GD@2x.png?raw=true' width='21' height='15'>",
  },
  GE: {
    countryName: "Georgia",
    currency: "GEL",
    symbol: "GEL",
    flag: "<img src='PNG/GE@2x.png?raw=true' width='21' height='15'>",
  },
  GF: {
    countryName: "French Guiana",
    currency: "EUR",
    symbol: "€",
    flag: "<img src='PNG/GF@2x.png?raw=true' width='21' height='15'>",
  },
  GG: {
    countryName: "Guernsey",
    currency: "GBP",
    symbol: "£",
    flag: "<img src='PNG/GG@2x.png?raw=true' width='21' height='15'>",
  },
  GH: {
    countryName: "Ghana",
    currency: "GHS",
    symbol: "GH₵",
    flag: "<img src='PNG/GH@2x.png?raw=true' width='21' height='15'>",
  },
  GI: {
    countryName: "Gibraltar",
    currency: "GIP",
    symbol: "GIP",
    flag: "<img src='PNG/GI@2x.png?raw=true' width='21' height='15'>",
  },
  GL: {
    countryName: "Greenland",
    currency: "DKK",
    symbol: "Dkr",
    flag: "<img src='PNG/GL@2x.png?raw=true' width='21' height='15'>",
  },
  GM: {
    countryName: "Gambia",
    currency: "GMD",
    symbol: "GMD",
    flag: "<img src='PNG/GM@2x.png?raw=true' width='21' height='15'>",
  },
  GN: {
    countryName: "Guinea",
    currency: "GNF",
    symbol: "FG",
    flag: "<img src='PNG/GN@2x.png?raw=true' width='21' height='15'>",
  },
  GP: {
    countryName: "Guadeloupe",
    currency: "EUR",
    symbol: "€",
    flag: "<img src='PNG/GP@2x.png?raw=true' width='21' height='15'>",
  },
  GQ: {
    countryName: "Equatorial Guinea",
    currency: "XAF",
    symbol: "FCFA",
    flag: "<img src='PNG/GQ@2x.png?raw=true' width='21' height='15'>",
  },
  GR: {
    countryName: "Greece",
    currency: "EUR",
    symbol: "€",
    dateFormat: "d/M/yyyy",
    flag: "<img src='PNG/GR@2x.png?raw=true' width='21' height='15'>",
  },
  GS: {
    countryName: "South Georgia and the South Sandwich Islands",
    currency: "GBP",
    symbol: "£",
    flag: "<img src='PNG/GS@2x.png?raw=true' width='21' height='15'>",
  },
  GT: {
    countryName: "Guatemala",
    currency: "GTQ",
    symbol: "GTQ",
    dateFormat: "d/MM/yyyy",
    flag: "<img src='PNG/GT@2x.png?raw=true' width='21' height='15'>",
  },
  GU: {
    countryName: "Guam",
    currency: "USD",
    symbol: "$",
    flag: "<img src='PNG/GU@2x.png?raw=true' width='21' height='15'>",
  },
  GW: {
    countryName: "Guinea-Bissau",
    currency: "XOF",
    symbol: "CFA",
    flag: "<img src='PNG/GW@2x.png?raw=true' width='21' height='15'>",
  },
  GY: {
    countryName: "Guyana",
    currency: "GYD",
    symbol: "GYD",
    flag: "<img src='PNG/GY@2x.png?raw=true' width='21' height='15'>",
  },
  HK: {
    countryName: "Hong Kong",
    currency: "HKD",
    symbol: "HK$",
    dateFormat: "yyyy年M月d日",
    flag: "<img src='PNG/HK@2x.png?raw=true' width='21' height='15'>",
  },
  HM: {
    countryName: "Heard Island and McDonald Islands",
    currency: "AUD",
    symbol: "AU$",
    flag: "<img src='PNG/HM@2x.png?raw=true' width='21' height='15'>",
  },
  HN: {
    countryName: "Honduras",
    currency: "HNL",
    symbol: "HNL",
    dateFormat: "MM-dd-yyyy",
    flag: "<img src='PNG/HN@2x.png?raw=true' width='21' height='15'>",
  },
  HR: {
    countryName: "Croatia",
    currency: "HRK",
    symbol: "kn",
    dateFormat: "dd.MM.yyyy.",
    flag: "<img src='PNG/HR@2x.png?raw=true' width='21' height='15'>",
  },
  HT: {
    countryName: "Haiti",
    currency: "HTG",
    symbol: "HTG",
    flag: "<img src='PNG/HT@2x.png?raw=true' width='21' height='15'>",
  },
  HU: {
    countryName: "Hungary",
    currency: "HUF",
    symbol: "Ft",
    dateFormat: "yyyy.MM.dd.",
    flag: "<img src='PNG/HU@2x.png?raw=true' width='21' height='15'>",
  },
  ID: {
    countryName: "Indonesia",
    currency: "IDR",
    symbol: "Rp",
    dateFormat: "dd/MM/yyyy",
    flag: "<img src='PNG/ID@2x.png?raw=true' width='21' height='15'>",
  },
  IE: {
    countryName: "Ireland",
    currency: "EUR",
    symbol: "€",
    dateFormat: "dd/MM/yyyy",
    flag: "<img src='PNG/IE@2x.png?raw=true' width='21' height='15'>",
  },
  IL: {
    countryName: "Israel",
    currency: "ILS",
    symbol: "₪",
    dateFormat: "dd/MM/yyyy",
    flag: "<img src='PNG/IL@2x.png?raw=true' width='21' height='15'>",
  },
  IM: {
    countryName: "Isle of Man",
    currency: "GBP",
    symbol: "£",
    flag: "<img src='PNG/IM@2x.png?raw=true' width='21' height='15'>",
  },
  IN: {
    countryName: "India",
    currency: "INR",
    symbol: "Rs",
    dateFormat: "d/M/yyyy",
    flag: "<img src='PNG/IN@2x.png?raw=true' width='21' height='15'>",
  },
  IO: {
    countryName: "British Indian Ocean Territory",
    currency: "USD",
    symbol: "$",
    flag: "<img src='PNG/IO@2x.png?raw=true' width='21' height='15'>",
  },
  IQ: {
    countryName: "Iraq",
    currency: "IQD",
    symbol: "IQD",
    dateFormat: "dd/MM/yyyy",
    flag: "<img src='PNG/IQ@2x.png?raw=true' width='21' height='15'>",
  },
  IR: {
    countryName: "Iran",
    currency: "IRR",
    symbol: "IRR",
    flag: "<img src='PNG/IR@2x.png?raw=true' width='21' height='15'>",
  },
  IS: {
    countryName: "Iceland",
    currency: "ISK",
    symbol: "Ikr",
    dateFormat: "d.M.yyyy",
    flag: "<img src='PNG/IS@2x.png?raw=true' width='21' height='15'>",
  },
  IT: {
    countryName: "Italy",
    currency: "EUR",
    symbol: "€",
    dateFormat: "dd/MM/yyyy",
    flag: "<img src='PNG/IT@2x.png?raw=true' width='21' height='15'>",
  },
  JE: {
    countryName: "Jersey",
    currency: "GBP",
    symbol: "£",
    flag: "<img src='PNG/JE@2x.png?raw=true' width='21' height='15'>",
  },
  JM: {
    countryName: "Jamaica",
    currency: "JMD",
    symbol: "J$",
    flag: "<img src='PNG/JM@2x.png?raw=true' width='21' height='15'>",
  },
  JO: {
    countryName: "Jordan",
    currency: "JOD",
    symbol: "JD",
    dateFormat: "dd/MM/yyyy",
    flag: "<img src='PNG/JO@2x.png?raw=true' width='21' height='15'>",
  },
  JP: {
    countryName: "Japan",
    currency: "JPY",
    symbol: "¥",
    dateFormat: "H24.MM.dd",
    flag: "<img src='PNG/JP@2x.png?raw=true' width='21' height='15'>",
  },
  KE: {
    countryName: "Kenya",
    currency: "KES",
    symbol: "Ksh",
    flag: "<img src='PNG/KE@2x.png?raw=true' width='21' height='15'>",
  },
  KG: {
    countryName: "Kyrgyzstan",
    currency: "KGS",
    symbol: "KGS",
    flag: "<img src='PNG/KG@2x.png?raw=true' width='21' height='15'>",
  },
  KH: {
    countryName: "Cambodia",
    currency: "KHR",
    symbol: "KHR",
    flag: "<img src='PNG/KH@2x.png?raw=true' width='21' height='15'>",
  },
  KI: {
    countryName: "Kiribati",
    currency: "AUD",
    symbol: "AU$",
    flag: "<img src='PNG/KI@2x.png?raw=true' width='21' height='15'>",
  },
  KM: {
    countryName: "Comoros",
    currency: "KMF",
    symbol: "CF",
    flag: "<img src='PNG/KM@2x.png?raw=true' width='21' height='15'>",
  },
  KN: {
    countryName: "Saint Kitts and Nevis",
    currency: "XCD",
    symbol: "XCD",
    flag: "<img src='PNG/KN@2x.png?raw=true' width='21' height='15'>",
  },
  KP: {
    countryName: "North Korea",
    currency: "KPW",
    symbol: "KPW",
    flag: "<img src='PNG/KP@2x.png?raw=true' width='21' height='15'>",
  },
  KR: {
    countryName: "South Korea",
    currency: "KRW",
    symbol: "₩",
    dateFormat: "yyyy. M. d",
    flag: "<img src='PNG/KR@2x.png?raw=true' width='21' height='15'>",
  },
  KW: {
    countryName: "Kuwait",
    currency: "KWD",
    symbol: "KD",
    dateFormat: "dd/MM/yyyy",
    flag: "<img src='PNG/KW@2x.png?raw=true' width='21' height='15'>",
  },
  KY: {
    countryName: "Cayman Islands",
    currency: "KYD",
    symbol: "KYD",
    flag: "<img src='PNG/KY@2x.png?raw=true' width='21' height='15'>",
  },
  KZ: {
    countryName: "Kazakhstan",
    currency: "KZT",
    symbol: "KZT",
    flag: "<img src='PNG/KZ@2x.png?raw=true' width='21' height='15'>",
  },
  LA: {
    countryName: "Laos",
    currency: "LAK",
    symbol: "LAK",
    flag: "<img src='PNG/LA@2x.png?raw=true' width='21' height='15'>",
  },
  LB: {
    countryName: "Lebanon",
    currency: "LBP",
    symbol: "LB£",
    dateFormat: "dd/MM/yyyy",
    flag: "<img src='PNG/LB@2x.png?raw=true' width='21' height='15'>",
  },
  LC: {
    countryName: "Saint Lucia",
    currency: "XCD",
    symbol: "XCD",
    flag: "<img src='PNG/LC@2x.png?raw=true' width='21' height='15'>",
  },
  LI: {
    countryName: "Liechtenstein",
    currency: "CHF",
    symbol: "CHF",
    flag: "<img src='PNG/LI@2x.png?raw=true' width='21' height='15'>",
  },
  LK: {
    countryName: "Sri Lanka",
    currency: "LKR",
    symbol: "SLRs",
    flag: "<img src='PNG/LK@2x.png?raw=true' width='21' height='15'>",
  },
  LR: {
    countryName: "Liberia",
    currency: "LRD",
    symbol: "LRD",
    flag: "<img src='PNG/LR@2x.png?raw=true' width='21' height='15'>",
  },
  LS: {
    countryName: "Lesotho",
    currency: "LSL",
    symbol: "LSL",
    flag: "<img src='PNG/LS@2x.png?raw=true' width='21' height='15'>",
  },
  LT: {
    countryName: "Lithuania",
    currency: "EUR",
    symbol: "€",
    dateFormat: "yyyy.M.d",
    flag: "<img src='PNG/LT@2x.png?raw=true' width='21' height='15'>",
  },
  LU: {
    countryName: "Luxembourg",
    currency: "EUR",
    symbol: "€",
    dateFormat: "dd.MM.yyyy",
    flag: "<img src='PNG/LU@2x.png?raw=true' width='21' height='15'>",
  },
  LV: {
    countryName: "Latvia",
    currency: "EUR",
    symbol: "€",
    dateFormat: "yyyy.d.M",
    flag: "<img src='PNG/LV@2x.png?raw=true' width='21' height='15'>",
  },
  LY: {
    countryName: "Libya",
    currency: "LYD",
    symbol: "LD",
    dateFormat: "dd/MM/yyyy",
    flag: "<img src='PNG/LY@2x.png?raw=true' width='21' height='15'>",
  },
  MA: {
    countryName: "Morocco",
    currency: "MAD",
    symbol: "MAD",
    dateFormat: "dd/MM/yyyy",
    flag: "<img src='PNG/MA@2x.png?raw=true' width='21' height='15'>",
  },
  MC: {
    countryName: "Monaco",
    currency: "EUR",
    symbol: "€",
    flag: "<img src='PNG/MC@2x.png?raw=true' width='21' height='15'>",
  },
  MD: {
    countryName: "Moldova",
    currency: "MDL",
    symbol: "MDL",
    flag: "<img src='PNG/MD@2x.png?raw=true' width='21' height='15'>",
  },
  ME: {
    countryName: "Montenegro",
    currency: "EUR",
    symbol: "€",
    dateFormat: "d.M.yyyy.",
    flag: "<img src='PNG/ME@2x.png?raw=true' width='21' height='15'>",
  },
  MF: {
    countryName: "Saint Martin",
    currency: "EUR",
    symbol: "€",
    flag: "<img src='PNG/MF@2x.png?raw=true' width='21' height='15'>",
  },
  MG: {
    countryName: "Madagascar",
    currency: "MGA",
    symbol: "MGA",
    flag: "<img src='PNG/MG@2x.png?raw=true' width='21' height='15'>",
  },
  MH: {
    countryName: "Marshall Islands",
    currency: "USD",
    symbol: "$",
    flag: "<img src='PNG/MH@2x.png?raw=true' width='21' height='15'>",
  },
  MK: {
    countryName: "Macedonia",
    currency: "MKD",
    symbol: "MKD",
    dateFormat: "d.M.yyyy",
    flag: "<img src='PNG/MK@2x.png?raw=true' width='21' height='15'>",
  },
  ML: {
    countryName: "Mali",
    currency: "XOF",
    symbol: "CFA",
    flag: "<img src='PNG/ML@2x.png?raw=true' width='21' height='15'>",
  },
  MM: {
    countryName: "Myanmar",
    currency: "MMK",
    symbol: "MMK",
    flag: "<img src='PNG/MM@2x.png?raw=true' width='21' height='15'>",
  },
  MN: {
    countryName: "Mongolia",
    currency: "MNT",
    symbol: "MNT",
    flag: "<img src='PNG/MN@2x.png?raw=true' width='21' height='15'>",
  },
  MO: {
    countryName: "Macao",
    currency: "MOP",
    symbol: "MOP$",
    flag: "<img src='PNG/MO@2x.png?raw=true' width='21' height='15'>",
  },
  MP: {
    countryName: "Northern Mariana Islands",
    currency: "USD",
    symbol: "$",
    flag: "<img src='PNG/MP@2x.png?raw=true' width='21' height='15'>",
  },
  MQ: {
    countryName: "Martinique",
    currency: "EUR",
    symbol: "€",
    flag: "<img src='PNG/MQ@2x.png?raw=true' width='21' height='15'>",
  },
  MR: {
    countryName: "Mauritania",
    currency: "MRU",
    symbol: "MRU",
    flag: "<img src='PNG/MR@2x.png?raw=true' width='21' height='15'>",
  },
  MS: {
    countryName: "Montserrat",
    currency: "XCD",
    symbol: "XCD",
    flag: "<img src='PNG/MS@2x.png?raw=true' width='21' height='15'>",
  },
  MT: {
    countryName: "Malta",
    currency: "EUR",
    symbol: "€",
    dateFormat: "dd/MM/yyyy",
    flag: "<img src='PNG/MT@2x.png?raw=true' width='21' height='15'>",
  },
  MU: {
    countryName: "Mauritius",
    currency: "MUR",
    symbol: "MURs",
    flag: "<img src='PNG/MU@2x.png?raw=true' width='21' height='15'>",
  },
  MV: {
    countryName: "Maldives",
    currency: "MVR",
    symbol: "MVR",
    flag: "<img src='PNG/MV@2x.png?raw=true' width='21' height='15'>",
  },
  MW: {
    countryName: "Malawi",
    currency: "MWK",
    symbol: "MWK",
    flag: "<img src='PNG/MW@2x.png?raw=true' width='21' height='15'>",
  },
  MX: {
    countryName: "Mexico",
    currency: "MXN",
    symbol: "MX$",
    dateFormat: "d/MM/yyyy",
    flag: "<img src='PNG/MX@2x.png?raw=true' width='21' height='15'>",
  },
  MY: {
    countryName: "Malaysia",
    currency: "MYR",
    symbol: "RM",
    dateFormat: "dd/MM/yyyy",
    flag: "<img src='PNG/MY@2x.png?raw=true' width='21' height='15'>",
  },
  MZ: {
    countryName: "Mozambique",
    currency: "MZN",
    symbol: "MTn",
    flag: "<img src='PNG/MZ@2x.png?raw=true' width='21' height='15'>",
  },
  NA: {
    countryName: "Namibia",
    currency: "NAD",
    symbol: "N$",
    flag: "<img src='PNG/NA@2x.png?raw=true' width='21' height='15'>",
  },
  NC: {
    countryName: "New Caledonia",
    currency: "XPF",
    symbol: "XPF",
    flag: "<img src='PNG/NC@2x.png?raw=true' width='21' height='15'>",
  },
  NE: {
    countryName: "Niger",
    currency: "XOF",
    symbol: "CFA",
    flag: "<img src='PNG/NE@2x.png?raw=true' width='21' height='15'>",
  },
  NF: {
    countryName: "Norfolk Island",
    currency: "AUD",
    symbol: "AU$",
    flag: "<img src='PNG/NF@2x.png?raw=true' width='21' height='15'>",
  },
  NG: {
    countryName: "Nigeria",
    currency: "NGN",
    symbol: "₦",
    flag: "<img src='PNG/NG@2x.png?raw=true' width='21' height='15'>",
  },
  NI: {
    countryName: "Nicaragua",
    currency: "NIO",
    symbol: "C$",
    dateFormat: "MM-dd-yyyy",
    flag: "<img src='PNG/NI@2x.png?raw=true' width='21' height='15'>",
  },
  NL: {
    countryName: "Netherlands",
    currency: "EUR",
    symbol: "€",
    dateFormat: "d-M-yyyy",
    flag: "<img src='PNG/NL@2x.png?raw=true' width='21' height='15'>",
  },
  NO: {
    countryName: "Norway",
    currency: "NOK",
    symbol: "Nkr",
    dateFormat: "dd.MM.yyyy",
    flag: "<img src='PNG/NO@2x.png?raw=true' width='21' height='15'>",
  },
  NP: {
    countryName: "Nepal",
    currency: "NPR",
    symbol: "NPRs",
    flag: "<img src='PNG/NP@2x.png?raw=true' width='21' height='15'>",
  },
  NR: {
    countryName: "Nauru",
    currency: "AUD",
    symbol: "AU$",
    flag: "<img src='PNG/NR@2x.png?raw=true' width='21' height='15'>",
  },
  NU: {
    countryName: "Niue",
    currency: "NZD",
    symbol: "NZ$",
    flag: "<img src='PNG/NU@2x.png?raw=true' width='21' height='15'>",
  },
  NZ: {
    countryName: "New Zealand",
    currency: "NZD",
    symbol: "NZ$",
    dateFormat: "d/MM/yyyy",
    flag: "<img src='PNG/NZ@2x.png?raw=true' width='21' height='15'>",
  },
  OM: {
    countryName: "Oman",
    currency: "OMR",
    symbol: "OMR",
    dateFormat: "dd/MM/yyyy",
    flag: "<img src='PNG/OM@2x.png?raw=true' width='21' height='15'>",
  },
  PA: {
    countryName: "Panama",
    currency: "PAB",
    symbol: "B/.",
    dateFormat: "MM/dd/yyyy",
    flag: "<img src='PNG/PA@2x.png?raw=true' width='21' height='15'>",
  },
  PE: {
    countryName: "Peru",
    currency: "PEN",
    symbol: "S/.",
    dateFormat: "dd/MM/yyyy",
    flag: "<img src='PNG/PE@2x.png?raw=true' width='21' height='15'>",
  },
  PF: {
    countryName: "French Polynesia",
    currency: "XPF",
    symbol: "XPF",
    flag: "<img src='PNG/PF@2x.png?raw=true' width='21' height='15'>",
  },
  PG: {
    countryName: "Papua New Guinea",
    currency: "PGK",
    symbol: "PGK",
    flag: "<img src='PNG/PG@2x.png?raw=true' width='21' height='15'>",
  },
  PH: {
    countryName: "Philippines",
    currency: "PHP",
    symbol: "₱",
    dateFormat: "M/d/yyyy",
    flag: "<img src='PNG/PH@2x.png?raw=true' width='21' height='15'>",
  },
  PK: {
    countryName: "Pakistan",
    currency: "PKR",
    symbol: "PKRs",
    flag: "<img src='PNG/PK@2x.png?raw=true' width='21' height='15'>",
  },
  PL: {
    countryName: "Poland",
    currency: "PLN",
    symbol: "zł",
    dateFormat: "dd.MM.yyyy",
    flag: "<img src='PNG/PL@2x.png?raw=true' width='21' height='15'>",
  },
  PM: {
    countryName: "Saint Pierre and Miquelon",
    currency: "EUR",
    symbol: "€",
    flag: "<img src='PNG/PM@2x.png?raw=true' width='21' height='15'>",
  },
  PN: {
    countryName: "Pitcairn",
    currency: "NZD",
    symbol: "NZ$",
    flag: "<img src='PNG/PN@2x.png?raw=true' width='21' height='15'>",
  },
  PR: {
    countryName: "Puerto Rico",
    currency: "USD",
    symbol: "$",
    dateFormat: "MM-dd-yyyy",
    flag: "<img src='PNG/PR@2x.png?raw=true' width='21' height='15'>",
  },
  PS: {
    countryName: "Palestinian Territory",
    currency: "ILS",
    symbol: "₪",
    flag: "<img src='PNG/PS@2x.png?raw=true' width='21' height='15'>",
  },
  PT: {
    countryName: "Portugal",
    currency: "EUR",
    symbol: "€",
    dateFormat: "dd-MM-yyyy",
    flag: "<img src='PNG/PT@2x.png?raw=true' width='21' height='15'>",
  },
  PW: {
    countryName: "Palau",
    currency: "USD",
    symbol: "$",
    flag: "<img src='PNG/PW@2x.png?raw=true' width='21' height='15'>",
  },
  PY: {
    countryName: "Paraguay",
    currency: "PYG",
    symbol: "₲",
    dateFormat: "dd/MM/yyyy",
    flag: "<img src='PNG/PY@2x.png?raw=true' width='21' height='15'>",
  },
  QA: {
    countryName: "Qatar",
    currency: "QAR",
    symbol: "QR",
    dateFormat: "dd/MM/yyyy",
    flag: "<img src='PNG/QA@2x.png?raw=true' width='21' height='15'>",
  },
  RE: {
    countryName: "Reunion",
    currency: "EUR",
    symbol: "€",
    flag: "<img src='PNG/RE@2x.png?raw=true' width='21' height='15'>",
  },
  RO: {
    countryName: "Romania",
    currency: "RON",
    symbol: "RON",
    dateFormat: "dd.MM.yyyy",
    flag: "<img src='PNG/RO@2x.png?raw=true' width='21' height='15'>",
  },
  RS: {
    countryName: "Serbia",
    currency: "RSD",
    symbol: "din.",
    dateFormat: "d.M.yyyy.",
    flag: "<img src='PNG/RS@2x.png?raw=true' width='21' height='15'>",
  },
  RU: {
    countryName: "Russia",
    currency: "RUB",
    symbol: "RUB",
    dateFormat: "dd.MM.yyyy",
    flag: "<img src='PNG/RU@2x.png?raw=true' width='21' height='15'>",
  },
  RW: {
    countryName: "Rwanda",
    currency: "RWF",
    symbol: "RWF",
    flag: "<img src='PNG/RW@2x.png?raw=true' width='21' height='15'>",
  },
  SA: {
    countryName: "Saudi Arabia",
    currency: "SAR",
    symbol: "SR",
    dateFormat: "dd/MM/yyyy",
    flag: "<img src='PNG/SA@2x.png?raw=true' width='21' height='15'>",
  },
  SB: {
    countryName: "Solomon Islands",
    currency: "SBD",
    symbol: "SBD",
    flag: "<img src='PNG/SB@2x.png?raw=true' width='21' height='15'>",
  },
  SC: {
    countryName: "Seychelles",
    currency: "SCR",
    symbol: "SCR",
    flag: "<img src='PNG/SC@2x.png?raw=true' width='21' height='15'>",
  },
  SD: {
    countryName: "Sudan",
    currency: "SDG",
    symbol: "SDG",
    dateFormat: "dd/MM/yyyy",
    flag: "<img src='PNG/SD@2x.png?raw=true' width='21' height='15'>",
  },
  SE: {
    countryName: "Sweden",
    currency: "SEK",
    symbol: "Skr",
    dateFormat: "yyyy-MM-dd",
    flag: "<img src='PNG/SE@2x.png?raw=true' width='21' height='15'>",
  },
  SG: {
    countryName: "Singapore",
    currency: "SGD",
    symbol: "S$",
    dateFormat: "M/d/yyyy",
    flag: "<img src='PNG/SG@2x.png?raw=true' width='21' height='15'>",
  },
  SH: {
    countryName: "Saint Helena",
    currency: "SHP",
    symbol: "SHP",
    flag: "<img src='PNG/SH@2x.png?raw=true' width='21' height='15'>",
  },
  SI: {
    countryName: "Slovenia",
    currency: "EUR",
    symbol: "€",
    dateFormat: "d.M.yyyy",
    flag: "<img src='PNG/SI@2x.png?raw=true' width='21' height='15'>",
  },
  SJ: {
    countryName: "Svalbard and Jan Mayen",
    currency: "NOK",
    symbol: "Nkr",
    flag: "<img src='PNG/SJ@2x.png?raw=true' width='21' height='15'>",
  },
  SK: {
    countryName: "Slovakia",
    currency: "EUR",
    symbol: "€",
    dateFormat: "d.M.yyyy",
    flag: "<img src='PNG/SK@2x.png?raw=true' width='21' height='15'>",
  },
  SL: {
    countryName: "Sierra Leone",
    currency: "SLL",
    symbol: "SLL",
    flag: "<img src='PNG/SL@2x.png?raw=true' width='21' height='15'>",
  },
  SM: {
    countryName: "San Marino",
    currency: "EUR",
    symbol: "€",
    flag: "<img src='PNG/SM@2x.png?raw=true' width='21' height='15'>",
  },
  SN: {
    countryName: "Senegal",
    currency: "XOF",
    symbol: "CFA",
    flag: "<img src='PNG/SN@2x.png?raw=true' width='21' height='15'>",
  },
  SO: {
    countryName: "Somalia",
    currency: "SOS",
    symbol: "Ssh",
    flag: "<img src='PNG/SO@2x.png?raw=true' width='21' height='15'>",
  },
  SR: {
    countryName: "Suriname",
    currency: "SRD",
    symbol: "SRD",
    flag: "<img src='PNG/SR@2x.png?raw=true' width='21' height='15'>",
  },
  SS: {
    countryName: "South Sudan",
    currency: "SSP",
    symbol: "SSP",
    flag: "<img src='PNG/SS@2x.png?raw=true' width='21' height='15'>",
  },
  ST: {
    countryName: "Sao Tome and Principe",
    currency: "STD",
    symbol: "STD",
    flag: "<img src='PNG/ST@2x.png?raw=true' width='21' height='15'>",
  },
  SV: {
    countryName: "El Salvador",
    currency: "USD",
    symbol: "$",
    dateFormat: "MM-dd-yyyy",
    flag: "<img src='PNG/SV@2x.png?raw=true' width='21' height='15'>",
  },
  SX: {
    countryName: "Sint Maarten",
    currency: "ANG",
    symbol: "ANG",
    flag: "<img src='PNG/SX@2x.png?raw=true' width='21' height='15'>",
  },
  SY: {
    countryName: "Syria",
    currency: "SYP",
    symbol: "SY£",
    dateFormat: "dd/MM/yyyy",
    flag: "<img src='PNG/SY@2x.png?raw=true' width='21' height='15'>",
  },
  SZ: {
    countryName: "Swaziland",
    currency: "SZL",
    symbol: "SZL",
    flag: "<img src='PNG/SZ@2x.png?raw=true' width='21' height='15'>",
  },
  TC: {
    countryName: "Turks and Caicos Islands",
    currency: "USD",
    symbol: "$",
    flag: "<img src='PNG/TC@2x.png?raw=true' width='21' height='15'>",
  },
  TD: {
    countryName: "Chad",
    currency: "XAF",
    symbol: "FCFA",
    flag: "<img src='PNG/TD@2x.png?raw=true' width='21' height='15'>",
  },
  TF: {
    countryName: "French Southern Territories",
    currency: "EUR",
    symbol: "€",
    flag: "<img src='PNG/TF@2x.png?raw=true' width='21' height='15'>",
  },
  TG: {
    countryName: "Togo",
    currency: "XOF",
    symbol: "CFA",
    flag: "<img src='PNG/TG@2x.png?raw=true' width='21' height='15'>",
  },
  TH: {
    countryName: "Thailand",
    currency: "THB",
    symbol: "฿",
    dateFormat: "๓/๖/๒๕๕๕",
    flag: "<img src='PNG/TH@2x.png?raw=true' width='21' height='15'>",
  },
  TJ: {
    countryName: "Tajikistan",
    currency: "TJS",
    symbol: "TJS",
    flag: "<img src='PNG/TJ@2x.png?raw=true' width='21' height='15'>",
  },
  TK: {
    countryName: "Tokelau",
    currency: "NZD",
    symbol: "NZ$",
    flag: "<img src='PNG/TK@2x.png?raw=true' width='21' height='15'>",
  },
  TL: {
    countryName: "East Timor",
    currency: "USD",
    symbol: "$",
    flag: "<img src='PNG/TL@2x.png?raw=true' width='21' height='15'>",
  },
  TM: {
    countryName: "Turkmenistan",
    currency: "TMT",
    symbol: "TMT",
    flag: "<img src='PNG/TM@2x.png?raw=true' width='21' height='15'>",
  },
  TN: {
    countryName: "Tunisia",
    currency: "TND",
    symbol: "DT",
    dateFormat: "dd/MM/yyyy",
    flag: "<img src='PNG/TN@2x.png?raw=true' width='21' height='15'>",
  },
  TO: {
    countryName: "Tonga",
    currency: "TOP",
    symbol: "T$",
    flag: "<img src='PNG/TO@2x.png?raw=true' width='21' height='15'>",
  },
  TR: {
    countryName: "Turkey",
    currency: "TRY",
    symbol: "TL",
    dateFormat: "dd.MM.yyyy",
    flag: "<img src='PNG/TR@2x.png?raw=true' width='21' height='15'>",
  },
  TT: {
    countryName: "Trinidad and Tobago",
    currency: "TTD",
    symbol: "TT$",
    flag: "<img src='PNG/TT@2x.png?raw=true' width='21' height='15'>",
  },
  TV: {
    countryName: "Tuvalu",
    currency: "AUD",
    symbol: "AU$",
    flag: "<img src='PNG/TV@2x.png?raw=true' width='21' height='15'>",
  },
  TW: {
    countryName: "Taiwan",
    currency: "TWD",
    symbol: "NT$",
    dateFormat: "yyyy/M/d",
    flag: "<img src='PNG/TW@2x.png?raw=true' width='21' height='15'>",
  },
  TZ: {
    countryName: "Tanzania",
    currency: "TZS",
    symbol: "TSh",
    flag: "<img src='PNG/TZ@2x.png?raw=true' width='21' height='15'>",
  },
  UA: {
    countryName: "Ukraine",
    currency: "UAH",
    symbol: "₴",
    dateFormat: "dd.MM.yyyy",
    flag: "<img src='PNG/UA@2x.png?raw=true' width='21' height='15'>",
  },
  UG: {
    countryName: "Uganda",
    currency: "UGX",
    symbol: "USh",
    flag: "<img src='PNG/UG@2x.png?raw=true' width='21' height='15'>",
  },
  UM: {
    countryName: "United States Minor Outlying Islands",
    currency: "USD",
    symbol: "$",
    flag: "<img src='PNG/UM@2x.png?raw=true' width='21' height='15'>",
  },
  US: {
    countryName: "United States",
    currency: "USD",
    symbol: "$",
    dateFormat: "M/d/yyyy",
    flag: "<img src='PNG/US@2x.png?raw=true' width='21' height='15'>",
  },
  UY: {
    countryName: "Uruguay",
    currency: "UYU",
    symbol: "$U",
    dateFormat: "dd/MM/yyyy",
    flag: "<img src='PNG/UY@2x.png?raw=true' width='21' height='15'>",
  },
  UZ: {
    countryName: "Uzbekistan",
    currency: "UZS",
    symbol: "UZS",
    flag: "<img src='PNG/UZ@2x.png?raw=true' width='21' height='15'>",
  },
  VA: {
    countryName: "Vatican",
    currency: "EUR",
    symbol: "€",
    flag: "<img src='PNG/VA@2x.png?raw=true' width='21' height='15'>",
  },
  VC: {
    countryName: "Saint Vincent and the Grenadines",
    currency: "XCD",
    symbol: "XCD",
    flag: "<img src='PNG/VC@2x.png?raw=true' width='21' height='15'>",
  },
  VE: {
    countryName: "Venezuela",
    currency: "VEF",
    symbol: "Bs.F.",
    dateFormat: "dd/MM/yyyy",
    flag: "<img src='PNG/VE@2x.png?raw=true' width='21' height='15'>",
  },
  VG: {
    countryName: "British Virgin Islands",
    currency: "USD",
    symbol: "$",
    flag: "<img src='PNG/VG@2x.png?raw=true' width='21' height='15'>",
  },
  VI: {
    countryName: "U.S. Virgin Islands",
    currency: "USD",
    symbol: "$",
    flag: "<img src='PNG/VI@2x.png?raw=true' width='21' height='15'>",
  },
  VN: {
    countryName: "Vietnam",
    currency: "VND",
    symbol: "₫",
    dateFormat: "dd/MM/yyyy",
    flag: "<img src='PNG/VN@2x.png?raw=true' width='21' height='15'>",
  },
  VU: {
    countryName: "Vanuatu",
    currency: "VUV",
    symbol: "VUV",
    flag: "<img src='PNG/VU@2x.png?raw=true' width='21' height='15'>",
  },
  WF: {
    countryName: "Wallis and Futuna",
    currency: "XPF",
    symbol: "XPF",
    flag: "<img src='PNG/WF@2x.png?raw=true' width='21' height='15'>",
  },
  WS: {
    countryName: "Samoa",
    currency: "WST",
    symbol: "WST",
    flag: "<img src='PNG/WS@2x.png?raw=true' width='21' height='15'>",
  },
  XK: {
    countryName: "Kosovo",
    currency: "EUR",
    symbol: "€",
    flag: "<img src='PNG/XK@2x.png?raw=true' width='21' height='15'>",
  },
  YE: {
    countryName: "Yemen",
    currency: "YER",
    symbol: "YR",
    dateFormat: "dd/MM/yyyy",
    flag: "<img src='PNG/YE@2x.png?raw=true' width='21' height='15'>",
  },
  YT: {
    countryName: "Mayotte",
    currency: "EUR",
    symbol: "€",
    flag: "<img src='PNG/YT@2x.png?raw=true' width='21' height='15'>",
  },
  ZA: {
    countryName: "South Africa",
    currency: "ZAR",
    symbol: "R",
    dateFormat: "yyyy/MM/dd",
    flag: "<img src='PNG/ZA@2x.png?raw=true' width='21' height='15'>",
  },
  ZM: {
    countryName: "Zambia",
    currency: "ZMW",
    symbol: "ZK",
    flag: "<img src='PNG/ZM@2x.png?raw=true' width='21' height='15'>",
  },
  ZW: {
    countryName: "Zimbabwe",
    currency: "ZWL",
    symbol: "ZWL",
    flag: "<img src='PNG/ZW@2x.png?raw=true' width='21' height='15'>",
  },
};

export const uniqueCurrencies = {
  EUR: "€",
  AED: "AED",
  AFN: "Af",
  XCD: "XCD",
  ALL: "ALL",
  AMD: "AMD",
  AOA: "AOA",
  ARS: "AR$",
  USD: "$",
  AUD: "AU$",
  AWG: "AWG",
  AZN: "man.",
  BAM: "KM",
  BBD: "BBD",
  BDT: "Tk",
  XOF: "CFA",
  BGN: "BGN",
  BHD: "BD",
  BIF: "FBu",
  BMD: "BMD",
  BND: "BN$",
  BOB: "Bs",
  BRL: "R$",
  BSD: "BSD",
  BTN: "BTN",
  NOK: "Nkr",
  BWP: "BWP",
  BYN: "BYN",
  BZD: "BZ$",
  CAD: "CA$",
  CDF: "CDF",
  XAF: "FCFA",
  CHF: "CHF",
  NZD: "NZ$",
  CLP: "CL$",
  CNY: "CN¥",
  COP: "CO$",
  CRC: "₡",
  CUP: "CUP",
  CVE: "CV$",
  ANG: "ANG",
  CZK: "Kč",
  DJF: "Fdj",
  DKK: "Dkr",
  DOP: "RD$",
  DZD: "DA",
  EGP: "EGP",
  MAD: "MAD",
  ERN: "Nfk",
  ETB: "Br",
  FJD: "FJD",
  FKP: "FKP",
  GBP: "£",
  GEL: "GEL",
  GHS: "GH₵",
  GIP: "GIP",
  GMD: "GMD",
  GNF: "FG",
  GTQ: "GTQ",
  GYD: "GYD",
  HKD: "HK$",
  HNL: "HNL",
  HRK: "kn",
  HTG: "HTG",
  HUF: "Ft",
  IDR: "Rp",
  ILS: "₪",
  INR: "Rs",
  IQD: "IQD",
  IRR: "IRR",
  ISK: "Ikr",
  JMD: "J$",
  JOD: "JD",
  JPY: "¥",
  KES: "Ksh",
  KGS: "KGS",
  KHR: "KHR",
  KMF: "CF",
  KPW: "KPW",
  KRW: "₩",
  KWD: "KD",
  KYD: "KYD",
  KZT: "KZT",
  LAK: "LAK",
  LBP: "LB£",
  LKR: "SLRs",
  LRD: "LRD",
  LSL: "LSL",
  LYD: "LD",
  MDL: "MDL",
  MGA: "MGA",
  MKD: "MKD",
  MMK: "MMK",
  MNT: "MNT",
  MOP: "MOP$",
  MRU: "MRU",
  MUR: "MURs",
  MVR: "MVR",
  MWK: "MWK",
  MXN: "MX$",
  MYR: "RM",
  MZN: "MTn",
  NAD: "N$",
  XPF: "XPF",
  NGN: "₦",
  NIO: "C$",
  NPR: "NPRs",
  OMR: "OMR",
  PAB: "B/.",
  PEN: "S/.",
  PGK: "PGK",
  PHP: "₱",
  PKR: "PKRs",
  PLN: "zł",
  PYG: "₲",
  QAR: "QR",
  RON: "RON",
  RSD: "din.",
  RUB: "RUB",
  RWF: "RWF",
  SAR: "SR",
  SBD: "SBD",
  SCR: "SCR",
  SDG: "SDG",
  SEK: "Skr",
  SGD: "S$",
  SHP: "SHP",
  SLL: "SLL",
  SOS: "Ssh",
  SRD: "SRD",
  SSP: "SSP",
  STD: "STD",
  SYP: "SY£",
  SZL: "SZL",
  THB: "฿",
  TJS: "TJS",
  TMT: "TMT",
  TND: "DT",
  TOP: "T$",
  TRY: "TL",
  TTD: "TT$",
  TWD: "NT$",
  TZS: "TSh",
  UAH: "₴",
  UGX: "USh",
  UYU: "$U",
  UZS: "UZS",
  VEF: "Bs.F.",
  VND: "₫",
  VUV: "VUV",
  WST: "WST",
  YER: "YR",
  ZAR: "R",
  ZMW: "ZK",
  ZWL: "ZWL",
};

// src/components/RepairOrderTable/BulkStatusUpdate/BulkStatusUpdate.jsx

import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Alert,
  AlertTitle,
} from "@mui/material";
import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";

// Dialog Components (Assuming these are implemented in their respective files)
// import AddResolutionDialog from './dialogs/AddResolutionDialog';
// import RemoveResolutionDialog from './dialogs/RemoveResolutionDialog';
// import ConfirmClosureDialog from './dialogs/ConfirmClosureDialog';

// Utility Function (Assuming this is implemented in a separate file)
import { categorizeOrders } from "./categorizeOrders";
import StatusMenu from "components/StatusChip/StatusMenu";
import ResolutionSelectDialog from "../ResolutionSelectDialog";
import { useTranslation } from "react-i18next";
import ConfirmationDialog from "common/ConfirmDialog";
import useBulkStatusUpdate from "hooks/mutations/orders/useBulkStatusUpdate";

const BulkStatusUpdate = ({ selectedOrders }) => {
  const { t } = useTranslation();

  const bulkStatusUpdate = useBulkStatusUpdate();

  const [anchorEl, setAnchorEl] = useState(null);

  // State to manage the dialog queue
  const [dialogQueue, setDialogQueue] = useState([]);
  const [currentDialog, setCurrentDialog] = useState(null);

  const mutationData = useRef({
    repairOrderIds: [],
    status: null,
    resolution: null,
  });

  /**
   * Initiates the bulk update process by categorizing orders and setting up the dialog queue
   */
  const initiateBulkUpdate = (status) => {
    // Categorize orders based on the selected new status
    const categorized = categorizeOrders(selectedOrders, status);
    const queue = [];

    // If the new status requires resolutions and there are orders needing resolutions
    if (
      categorized.isResolutionRequired &&
      categorized.ordersNeedingResolution.length > 0
    ) {
      queue.push({
        type: "ADD_RESOLUTION",
        data: categorized.ordersNeedingResolution,
        selectedOrders: selectedOrders,
      });
    }

    // If the new status does not require resolutions but there are orders with existing resolutions
    if (
      !categorized.requiresResolution &&
      categorized.ordersRequiringRemoval.length > 0
    ) {
      queue.push({
        type: "REMOVE_RESOLUTION",
        data: categorized.ordersRequiringRemoval,
        selectedOrders: selectedOrders,
      });
    }

    if (categorized.closedOrders.length > 0) {
      queue.push({
        type: "INFORM_LOCKED_ORDERS",
        data: categorized.closedOrders,
        selectedOrders: selectedOrders,
      });
    }

    // Specific case for "Delivered" status requiring closure confirmation
    if (status.name === "Delivered") {
      queue.push({
        type: "CONFIRM_CLOSURE",
        data: categorized.ordersWithResolution,
        selectedOrders: selectedOrders,
      });
    }

    mutationData.current.repairOrderIds = selectedOrders.map(
      (order) => order.id
    );
    mutationData.current.status = status;

    // // Set the dialog queue and start processing
    setDialogQueue(queue);
    processNextDialog(queue);
  };

  /**
   * Processes the next dialog in the queue
   * @param {Array} queue
   */
  const processNextDialog = (queue) => {
    if (queue.length === 0) {
      // If no more dialogs, show the final confirmation
      bulkStatusUpdate.mutate(mutationData.current);
      mutationData.current = {
        repairOrderIds: [],
        status: null,
        resolution: null,
      };
      return;
    }

    // Get the next dialog from the queue
    const nextDialog = queue.shift();
    setCurrentDialog(nextDialog);
    setDialogQueue(queue);
  };

  const handleDialogClose = (event, reason) => {
    if (
      reason === "backdropClick" ||
      reason === "escapeKeyDown" ||
      reason === "cancel"
    ) {
      setCurrentDialog(null);
      mutationData.current = {
        repairOrderIds: [],
        status: null,
        resolution: null,
      };
    }
  };

  /**
   * Renders the appropriate dialog based on the current dialog type
   * @returns {JSX.Element|null}
   */
  const renderDialog = () => {
    if (!currentDialog) return null;

    switch (currentDialog.type) {
      case "ADD_RESOLUTION":
        return (
          <>
            <ResolutionSelectDialog
              message={t("dialogs.updateStatusRequiresResolutionBulk.message", {
                repairssNeedingResolutionCount: currentDialog.data.length,
                selectedOrdersCount: currentDialog.selectedOrders.length,
              })}
              open={Boolean(currentDialog)}
              onClose={handleDialogClose}
              handleResolutionSelect={(selectedResolution) => {
                if (selectedResolution) {
                  mutationData.current.resolution = selectedResolution;
                }
                setCurrentDialog(null);
                processNextDialog(dialogQueue);
              }}
            />
          </>
        );
      case "REMOVE_RESOLUTION":
        return (
          <>
            <ConfirmationDialog
              title={t("dialogs.bulkEditRemoveResolution.title")}
              message={
                <Alert severity="warning">
                  <AlertTitle>{t("alert.warningTitle")}</AlertTitle>
                  <Typography variant="body1">
                    {t("dialogs.bulkEditRemoveResolution.message", {
                      repairsToRemoveResolutionsCount:
                        currentDialog.data.length,
                      selectedOrdersCount: currentDialog.selectedOrders.length,
                    })}
                  </Typography>
                </Alert>
              }
              open={currentDialog}
              onClose={handleDialogClose}
              onConfirm={() => {
                setCurrentDialog(null);
                processNextDialog(dialogQueue);
              }}
            />
          </>
        );
      case "CONFIRM_CLOSURE":
        return (
          <>
            <ConfirmationDialog
              title={t("dialogs.bulkEditSetDeliveryConfromation.title")}
              message={
                <Alert severity="warning">
                  <AlertTitle>{t("Warning!")}</AlertTitle>
                  <Typography variant="body1">
                    {t("dialogs.bulkEditSetDeliveryConfromation.message", {
                      selectedOrdersCount: currentDialog.selectedOrders.length,
                    })}
                  </Typography>
                </Alert>
              }
              open={currentDialog}
              onClose={handleDialogClose}
              onConfirm={() => {
                setCurrentDialog(null);
                processNextDialog(dialogQueue);
              }}
            />
          </>
        );
      default:
        return null;
    }
  };

  const handleStatusSelect = (status) => {
    initiateBulkUpdate(status);
  };

  const handleUpdateStatusClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      {/* Update Status Button */}
      <Button
        onClick={handleUpdateStatusClick}
        disabled={selectedOrders.length === 0}
        variant="contained"
        color="primary"
      >
        {t("actions.updateStatus")}
      </Button>
      <StatusMenu
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        onStatusSelect={handleStatusSelect}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      />

      {/* Render the current dialog */}
      {renderDialog()}
    </>
  );
};

// PropTypes for type checking and ensuring correct prop usage
BulkStatusUpdate.propTypes = {
  selectedOrders: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      repairOrderStatus: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string,
      }).isRequired,
      repairOrderStatusId: PropTypes.number.isRequired,
      resolutionType: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string,
      }),

      resolution: PropTypes.string,
      // Include other relevant fields as needed
    })
  ).isRequired,
};

export default BulkStatusUpdate;

import {
  Typography,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { NavigateNext, WarningTwoTone } from "@mui/icons-material";
import { useSnackbar } from "notistack";

import axios from "../../axios-config";
import { LoadingButton } from "@mui/lab";

// TODO: replace this with the generic delete dialog
const DeleteDialogWarning = ({
  dialogOpen,
  handleDialogClose,
  selectedServices,
  setSelectedServices,
  onDelete,
  services,
  setServices,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [isProcessing, setIsProcessing] = useState(false);

  const handleAccept = async () => {
    try {
      setIsProcessing(true);
      const response = await axios.delete(`/service-catalogs/bulk`, {
        data: { serviceIds: selectedServices },
      });
      setIsProcessing(false);
      if (response.status === 200) {
        const newServices = services.filter(
          (service) => !selectedServices.includes(service.id)
        );
        setServices(newServices);
        setSelectedServices([]);
        handleDialogClose();
        enqueueSnackbar(t("Services deleted successfully"), {
          variant: "success",
        });
      }
    } catch (err) {
      setIsProcessing(false);
      enqueueSnackbar(`Something went wrong!`, { variant: "error" });
    }
  };

  return (
    <Dialog
      open={dialogOpen}
      onClose={handleDialogClose}
      fullWidth
      maxWidth={"xs"}
    >
      <DialogTitle variant="h4">Delete services</DialogTitle>
      <DialogContent>
        <Stack
          backgroundColor={"#FBE9DB"}
          p={2}
          spacing={1}
          borderRadius={"10px"}
        >
          <Stack direction={"row"} spacing={1} alignItems={"center"}>
            {/* <Typography variant='h6' color='textSecondary'>{selectedServices.length} {selectedServices.length == 1 ? 'service' : 'services'} selected</Typography> */}
            <WarningTwoTone sx={{ color: "red.main" }} />
            <Typography variant="body" fontWeight={500}>
              Warning!
            </Typography>
          </Stack>
          <Typography variant="body" color="textSecondary">
            Are you sure you want to delete{" "}
            <strong>{selectedServices.length} services</strong>?
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialogClose} color="black">
          {t("Cancel")}
        </Button>
        <LoadingButton
          loading={isProcessing}
          onClick={handleAccept}
          loadingPosition="end"
          variant="contained"
          color={"red"}
          endIcon={<NavigateNext />}
        >
          {t("Delete")}
        </LoadingButton>
        {/* <Button onClick={handleDelete} variant='contained' color='red'>{t("Delete")}</Button> */}
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDialogWarning;

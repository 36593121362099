import {
  FormControl,
  CardContent,
  TextField,
  Link,
  Grid,
  Stack,
  FormControlLabel,
  Checkbox,
  Alert,
} from "@mui/material";
import Typography from "@mui/material/Typography";

import { useState } from "react";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import { string, z } from "zod";
import { useForm, Controller } from "react-hook-form";
import { Link as RouterLink } from "react-router-dom";
import { WarningAmberOutlined } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { authActions } from "../../../store/auth";
import axios from "axios-config";

const RegisterForm = (props) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const [isProcessing, setIsProcessing] = useState(false);
  const [accountCreatedSuccesfully, setAccountCreatedSuccesfully] =
    useState(false);
  const [emailCreated, setEmailCreated] = useState(null);
  const [invalidInvitationCode, setInvalidInvitationCode] = useState(false);
  const [tempLoginData, setTempLoginData] = useState(null);
  const schema = z.object({
    email: string().email({ message: "Required" }),
    password: string().min(8, { message: "At least 8 characters." }),
    name: string().min(1, { message: "Required" }),
    organizationName: string().min(1, { message: "Required" }),
    invitationCode: string().optional(),
    termsAndConditions: z
      .boolean()
      .refine(
        (value) => value === true,
        "You must accept the Terms and Conditions."
      ),
  });

  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    formState: { isSubmitting, isDirty, isValid, errors },
  } = useForm({ resolver: zodResolver(schema) });

  const { enqueueSnackbar } = useSnackbar();
  // const onInput = ({target:{value}}) => setValue(value),

  const onInput = (event) => {
    // setRegister({ ...register, [event.target.id]: event.target.value });
  };
  const [termsAndConditionsAccepted, setTermsAndConditionsAccepted] =
    useState(false);

  const handleRegister = async (data) => {
    try {
      setInvalidInvitationCode(false);
      data.language = i18n.language;
      setIsProcessing(true);

      const response = await axios.put("/auth/signup", data);
      if (response.status === 200 || response.status === 201) {
        dispatchLogin(response.data);
      }
    } catch (err) {
      // Form Validation Error
      if (err.response.data.data) {
        if (err.response.data.data[0].msg) {
          enqueueSnackbar(err.response.data.data[0].msg, { variant: "error" });
        }
      }
      // Default message
      if (err.response.data.message) {
        if (
          err.response.status === 400 &&
          err.response.data.message === "Invalid invitation code!"
        ) {
          setInvalidInvitationCode(true);
        } else {
          enqueueSnackbar(err.response.data.message, { variant: "error" });
        }
      }
    }
    setIsProcessing(false);
  };

  const dispatchLogin = (data) => {
    dispatch(authActions.login(data));
    localStorage.setItem("organizationName", data.organizationName);
    localStorage.setItem("userName", data.userName);
    localStorage.setItem("userId", data.userId);
    localStorage.setItem("token", `Bearer ${data.token}`);
    localStorage.setItem("isAuth", true);
    localStorage.setItem("subscriptionType", data.subscriptionType);
    localStorage.setItem("startDate", data.startDate);
    localStorage.setItem("endDate", data.endDate);
    localStorage.setItem("verified", data.verified);
  };

  //  TODO: move veryify user to a separate App.js file
  // TODO: get rid of tempLoginData. We'll handle routing by "verified" state in there.
  // TODO: if loged in, and not veirfied, redirect to verify user page, simple as that.

  return (
    <CardContent sx={{ minWidth: "xs", maxWidth: "xs" }}>
      <>
        {invalidInvitationCode && (
          <Alert
            severity="error"
            sx={{ border: "1px solid rgba(0,0,0,0.25)", mb: "15px" }}
            icon={<WarningAmberOutlined />}
          >
            <Stack alignItems={"flex-start"}>
              <Typography fontWeight={500} variant={"p"}>
                {t("Invalid invitation code!")}
              </Typography>
            </Stack>
          </Alert>
        )}
        <form onSubmit={handleSubmit(handleRegister)}>
          <FormControl fullWidth margin="normal" variant="filled">
            <Controller
              name="name"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t("Name")}
                  id="name"
                  variant="filled"
                  size="small"
                  type={"name"}
                  required
                  error={!!errors.name}
                />
              )}
            />
          </FormControl>
          <FormControl fullWidth margin="normal" variant="filled">
            <Controller
              name="email"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <TextField
                  {...field}
                  // value={register.email}
                  // onChange={onInput}
                  label="Email"
                  id="email"
                  variant="filled"
                  size="small"
                  type={"email"}
                  required
                  error={!!errors.email}
                  helperText={t(errors.email?.message)}
                />
              )}
            />
          </FormControl>
          <FormControl fullWidth margin="normal" variant="filled">
            <Controller
              name="password"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t("Password")}
                  id="password"
                  variant="filled"
                  size="small"
                  type="password"
                  required
                  error={!!errors.password}
                  helperText={t(errors.password?.message)}
                />
              )}
            />
          </FormControl>
          <FormControl fullWidth margin="normal" variant="filled">
            <Controller
              name="organizationName"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t("Organization name")}
                  id="organizationName"
                  variant="filled"
                  size="small"
                  type={"name"}
                  required
                  error={!!errors.organizationName}
                  helperText={t(errors.organizationName?.message)}
                />
              )}
            />
          </FormControl>
          <FormControl fullWidth margin="normal" variant="filled">
            <Controller
              name="invitationCode"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t("Invitation code")}
                  id="invitationCode"
                  variant="filled"
                  size="small"
                  type={"name"}
                  error={!!errors.invitationCode}
                  helperText={t(errors.invitationCode?.message)}
                />
              )}
            />
          </FormControl>
          <Controller
            name="termsAndConditions"
            control={control}
            defaultValue={false}
            rules={{ required: "You must accept the Terms and Conditions" }}
            render={({ field }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    {...field}
                    checked={field.value}
                    onChange={(e) => field.onChange(e.target.checked)}
                    style={{
                      color: errors.termsAndConditions ? "orange" : "inherit",
                    }}
                  />
                }
                label={
                  <>
                    {" "}
                    {t("I accept the")}{" "}
                    <a
                      href="https://dalvado.com/terms-policies/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t("Terms and Conditions")}
                    </a>
                  </>
                }
              />
            )}
          />
          {errors.termsAndConditions && (
            <Alert
              severity="error"
              sx={{ border: "1px solid rgba(0,0,0,0.25)", mb: "15px" }}
              icon={<WarningAmberOutlined />}
            >
              <Stack alignItems={"flex-start"}>
                <Typography fontWeight={500} variant={"p"}>
                  {t("You must accept the terms")}
                </Typography>
              </Stack>
            </Alert>
          )}

          <LoadingButton
            fullWidth
            type="submit"
            loading={isProcessing}
            variant="contained"
          >
            <span>{t("Create my account")}</span>
          </LoadingButton>
        </form>
        <Grid
          container
          item
          justifyContent="center"
          style={{ marginTop: "30px", marginBottom: "15px" }}
        >
          <span>{t("Already have an account?")}</span>
          <RouterLink to="/auth/login">
            <Link
              component="button"
              variant="body2"
              style={{ marginLeft: "10px" }}
              to={"/auth/login"}
            >
              <span>{t("Log in")}</span>
            </Link>
          </RouterLink>
        </Grid>
      </>
    </CardContent>
  );
};
export default RegisterForm;

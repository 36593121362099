// useUpdateOrderStatusMutation.js
import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack"; // Correct import for useSnackbar
import { declineOrderRequest } from "api/repair-orders"; // Adjust the path accordingly

const useDeclineOrderRequest = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar(); // Use the useSnackbar hook to get enqueueSnackbar

  return useMutation(declineOrderRequest, {
    onMutate: async ({ repairOrderId }) => {
      // Cancel any outgoing refetches
      await queryClient.cancelQueries({
        queryKey: ["repairOrders"],
        exact: false,
      });

      // Snapshot the previous value
      const previousOrders = queryClient
        .getQueriesData({ queryKey: ["repairOrders"], exact: false })
        .filter(([queryKey, oldData]) => oldData !== undefined);

      // Optimistically update all matching queries
      previousOrders.forEach(([queryKey, oldData]) => {
        if (!oldData) {
          console.warn(`No data for queryKey: ${queryKey}`);
          return;
        }

        const newData = oldData.filter((o) => o.id !== repairOrderId);

        queryClient.setQueryData(queryKey, newData);
      });

      // Return context with the previous orders for rollback
      return { previousOrders };
    },
    onSuccess: (data, variables) => {
      // Update all matching queries with the new data
      //   const updatedOrder = data;

      const queries = queryClient
        .getQueriesData({ queryKey: ["repairOrders"], exact: false })
        .filter(([queryKey, oldData]) => oldData !== undefined);

      queries.forEach(([queryKey, oldData]) => {
        if (!oldData) {
          console.warn(`No data for queryKey: ${queryKey}`);
          return;
        }

        const newData = oldData.filter((o) => o.id !== variables.repairOrderId);

        queryClient.setQueryData(queryKey, newData);
      });

      enqueueSnackbar("Order declined", { variant: "success" });
    },
    onError: (err, variables, context) => {
      // Rollback to previous orders
      context.previousOrders.forEach(([queryKey, oldData]) => {
        queryClient.setQueryData(queryKey, oldData);
      });

      console.error(err?.response?.data?.message || "Error updating status");

      enqueueSnackbar("Failed to decline order", { variant: "error" });
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["repairOrders"],
        exact: false,
      });
    },
  });
};

export default useDeclineOrderRequest;

import { Typography, Button, Grid, Stack, Box, Container } from "@mui/material";
import BoxContainer from "common/BoxContainer/BoxContainer";
import NavigationBar from "components/Navbar/NavigationBar";
import React, { useCallback, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import axios from "axios-config";

import { useStore, useDispatch } from "react-redux";
import { authActions } from "store/auth";
import { NavigateNext, OpenInNew } from "@mui/icons-material";
const SubscriptionPage = () => {
  const { t } = useTranslation();
  const store = useStore();
  const dispatch = useDispatch();

  // use effect to check subscription status every 5 seconds

  // Function to fetch subscription status
  const fetchSubscriptionStatus = useCallback(async () => {
    try {
      const response = await axios.get(`/organizations/subscription-status`);
      if (response.status === 200) {
        // console.log("DISPATCH");
        dispatch(authActions.updateActiveSubscription(true));
      }
    } catch (error) {
      console.error("Failed to fetch subscription status:", error);
    }
  }, [dispatch]);

  useEffect(() => {
    fetchSubscriptionStatus();
    const interval = setInterval(() => {
      fetchSubscriptionStatus();
    }, 5000);
    return () => clearInterval(interval);
  }, [fetchSubscriptionStatus]);

  return (
    <NavigationBar activeSubscription={false}>
      <Container>
        <div className="headerPush"></div>
        <Grid container spacing={4} mt={8}>
          <Grid item xs={12} md={8}>
            <Stack spacing={3}>
              <BoxContainer
                style={{
                  padding: "24px",
                  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                  borderRadius: "12px",
                  backgroundColor: "#fff",
                }}
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={4}
                >
                  <Typography
                    variant="h6"
                    color="darkGrey.main"
                    textTransform="uppercase"
                    style={{ fontWeight: 700 }}
                  >
                    {t("Subscription expired!")}
                  </Typography>
                </Stack>
                {!process.env.REACT_APP_COSTI_LOCK && (
                  <>
                    <Typography
                      variant="body1"
                      mb={3}
                      style={{ lineHeight: 1.8, color: "#555" }}
                    >
                      {t("subscriptionPage.subscriptionExpiredMessage")}
                    </Typography>
                    <Stack direction="row" spacing={2}>
                      <Button
                        variant="contained"
                        color="primary"
                        href="https://dalvado.com/support"
                        target="_blank"
                        endIcon={<OpenInNew />}
                        style={{
                          borderRadius: "8px",
                          padding: "8px 20px",
                          fontWeight: 600,
                        }}
                      >
                        {t("subscriptionPage.support")}
                      </Button>
                    </Stack>
                  </>
                )}
                {process.env.REACT_APP_COSTI_LOCK && (
                  <>
                    <Typography
                      variant="body1"
                      mb={3}
                      style={{ lineHeight: 1.8, color: "#555" }}
                    >
                      {t("subscriptionPage.subscriptionExpiredMessageCosti")}
                    </Typography>
                  </>
                )}
              </BoxContainer>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </NavigationBar>
  );
};

export default SubscriptionPage;

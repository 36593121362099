import * as React from "react";
import { Box } from "@mui/system";
import {
  Typography,
  Stack,
  TextField,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import axios from "../../axios-config";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import "../../App.css";
import { lightBlue } from "@mui/material/colors";

import { useTranslation } from "react-i18next";
import LoadingButton from "@mui/lab/LoadingButton";

const lightBlueBorder = lightBlue[500];

const PartnerRequests = ({ currentOrganization }) => {
  const [partnerInput, setPartnerInput] = useState("");
  const [partnerRequestReceived, setPartnerRequestReceived] = useState([]);
  const [partnerRequestSent, setPartnerRequestSent] = useState([]);
  const [sendingRequest, setSendingRequest] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchPartnerRequests = async () => {
      try {
        const partnerRequestResp = await axios.get(
          `/organizations/partners/requests`
        );
        if (partnerRequestResp.status === 200) {
          if (partnerRequestResp.data?.received) {
            setPartnerRequestReceived(partnerRequestResp.data.received);
          }
          if (partnerRequestResp.data?.sent) {
            setPartnerRequestSent(partnerRequestResp.data.sent);
          }
        }
      } catch (err) {
        console.log(err);
      }
    };

    fetchPartnerRequests();
  }, []);

  const handlePartnerSendRequest = async () => {
    try {
      setSendingRequest(true);
      const resp = await axios.put(`organizations/partner/${partnerInput}`);
      if (resp.status === 200) {
        setPartnerRequestSent((prevState) => [...prevState, resp.data]);
        setPartnerInput("");
        enqueueSnackbar("Request sent succesfully!", { variant: "success" });
      }
      setSendingRequest(false);
    } catch (err) {
      setSendingRequest(false);
      handleFail(err);
    }
  };

  const handleFail = (err) => {
    const message = err.response?.data?.message
      ? err.response.data.message
      : "Something went wrong!";
    enqueueSnackbar(message, { variant: "error" });
    console.log(err);
  };

  const handleCancelSentRequest = async (requestee) => {
    setPartnerRequestSent((prevState) =>
      prevState.filter((request) => request.id !== requestee.id)
    );
    enqueueSnackbar("Request canceled.", { variant: "success" });
  };
  const handleAcceptRequest = async (requester) => {
    setPartnerRequestReceived((prevState) =>
      prevState.filter((request) => request.id !== requester.id)
    );
    enqueueSnackbar("Request accepted.", { variant: "success" });
  };
  const handleRejectRequest = async (requester) => {
    setPartnerRequestReceived((prevState) =>
      prevState.filter((request) => request.id !== requester.id)
    );
    enqueueSnackbar("Request rejected.", { variant: "success" });
  };

  return (
    <Stack alignItems={"start"} spacing={2}>
      <Typography
        variant={"overline"}
        color={"darkGrey.main"}
        textTransform={"uppercase"}
      >
        {t("Partners")}
      </Typography>
      {currentOrganization ? (
        <Stack direction={"column"} alignItems={"start"}>
          <Typography variant="body2">
            <Box component={"span"} color={"darkGrey.main"}>
              {t("Organization id")}
            </Box>
          </Typography>
          <Typography variant="h6">
            <Box component={"span"}>
              {currentOrganization.id}#{currentOrganization.name}
            </Box>
          </Typography>
        </Stack>
      ) : null}
      <Stack direction={"row"} alignItems={"start"} spacing={2}>
        <TextField
          value={partnerInput}
          onChange={(e) => setPartnerInput(e.target.value)}
          variant="filled"
          label={t("Partner id")}
          size={"small"}
        />
        <LoadingButton
          loading={sendingRequest}
          variant="outlined"
          color={"primary"}
          onClick={handlePartnerSendRequest}
        >
          {t("Send Request")}
        </LoadingButton>
      </Stack>

      {partnerRequestSent.length > 0 ? (
        <Typography variant="h5">Requests sent</Typography>
      ) : null}
      <List sx={{ width: "100%", maxWidth: 360 }}>
        {partnerRequestSent.map((request) => (
          <RequestSent
            key={request.id}
            request={request}
            onCancel={handleCancelSentRequest}
            onFail={handleFail}
          />
        ))}
      </List>

      {partnerRequestReceived.length > 0 ? (
        <Typography variant="h5">Requests received</Typography>
      ) : null}
      <List sx={{ width: "100%", maxWidth: 360 }}>
        {partnerRequestReceived.map((request) => (
          <RequestReceived
            key={request.id}
            request={request}
            onAccept={handleAcceptRequest}
            onReject={handleRejectRequest}
            onFail={handleFail}
          />
        ))}
      </List>
    </Stack>
  );
};

const RequestSent = ({ request, onCancel, onFail }) => {
  const [canceling, setCanceling] = useState(false);

  const handleCancelSentRequest = async (requestee) => {
    try {
      setCanceling(true);
      const resp = await axios.put(
        `organizations/partner/cancelPartnerRequest/${requestee.id}`
      );
      if (resp.status === 200) {
        onCancel(requestee);
      }
      setCanceling(false);
    } catch (err) {
      setCanceling(false);
      onFail(err);
    }
  };

  return (
    <>
      <ListItem
        sx={{
          backgroundColor: "lightBlue.main",
          border: `1px ${lightBlueBorder} solid`,
          borderRadius: "5px",
        }}
        alignItems="flex-start"
      >
        <ListItemText
          primary={request.name}
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: "inline" }}
                component="span"
                variant="body2"
                color="text.primary"
              ></Typography>
            </React.Fragment>
          }
        />
        <Stack direction={"row"} alignItems={"start"} spacing={2}>
          <LoadingButton
            loading={canceling}
            variant="outlined"
            color={"red"}
            onClick={() => handleCancelSentRequest(request)}
          >
            Cancel
          </LoadingButton>
        </Stack>
      </ListItem>
    </>
  );
};

const RequestReceived = ({ request, onAccept, onReject, onFail }) => {
  const [accepting, setAccepting] = useState(false);
  const [rejecting, setRejecting] = useState(false);

  const handleAcceptRequest = async (request) => {
    try {
      setAccepting(true);
      const resp = await axios.put(
        `/organizations/partner/acceptPartnerRequest/${request.id}`
      );
      if (resp.status === 200) {
        onAccept(request);
      }
      setAccepting(false);
    } catch (err) {
      setAccepting(false);
      onFail(err);
    }
  };

  const handleRejectRequest = async (requester) => {
    try {
      setRejecting(true);
      const resp = await axios.put(
        `organizations/partner/rejectPartnerRequest/${requester.id}`
      );
      if (resp.status === 200) {
        onReject(requester);
      }
      setRejecting(false);
    } catch (err) {
      setRejecting(false);
      console.log(err);
    }
  };

  return (
    <ListItem
      sx={{
        backgroundColor: "lightBlue.main",
        border: `1px ${lightBlueBorder} solid`,
        borderRadius: "5px",
      }}
      alignItems="center"
    >
      <ListItemText
        primary={request.name}
        secondary={
          <>
            <Typography
              sx={{ display: "inline" }}
              component="span"
              variant="body2"
              color="text.primary"
            ></Typography>
          </>
        }
      />
      <Stack direction={"row"} alignItems={"start"} spacing={2}>
        <LoadingButton
          loading={accepting}
          disabled={rejecting}
          variant="outlined"
          color={"green"}
          onClick={() => handleAcceptRequest(request)}
        >
          Accept
        </LoadingButton>
        <LoadingButton
          loading={rejecting}
          disabled={accepting}
          variant="outlined"
          color={"red"}
          onClick={() => handleRejectRequest(request)}
        >
          Reject
        </LoadingButton>
      </Stack>
    </ListItem>
  );
};

export default PartnerRequests;

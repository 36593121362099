import * as React from "react";
import { useEffect, useState } from "react";

import { useTheme, styled } from "@mui/material/styles";
import {
  Box,
  Paper,
  Typography,
  Table,
  Button,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  OutlinedInput,
  Grid,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Divider,
  Dialog,
  Stack,
  IconButton,
  InputAdornment,
  InputLabel,
  ToggleButton,
  ToggleButtonGroup,
  CircularProgress,
  List,
  ListSubheader,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  Checkbox,
  ListItem,
  Popover,
  Switch,
} from "@mui/material";

import { servicesToItems } from "../../utils/InvoiceHelpers";

import ToolBarHelper from "../../components/ToolBarHelper/ToolBarHelper";
//ICONS
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import HandymanOutlined from "@mui/icons-material/HandymanOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

// import Preview from "./Preview";
import { useDropzone } from "react-dropzone";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import axios from "../../axios-config";

//OUR COMPONENTS
import CustomersSearch from "../../components/NewEntryStepper/CustomersSearch";
import Section from "../../components/Section/Section";
import StyledToggleButtonGroup from "../../components/StyledToggleButtonGroup/StyledToggleButtonGroup";
import UploadFilesDialog from "../../components/UploadFilesDialog/UploadFilesDialog";
import LogoButton from "../../components/Buttons/LogoButton";
import SimplifiedInvoiceCreator from "./SimplifiedInvoiceCreator";
import EditCustomerDialog from "../../containers/EditOrder/Dialogs/EditCustomerDialog";
import A4Creator from "./A4Creator";
import SideMenuCustomers from "./SideMenuCustomers";
import { useTranslation } from "react-i18next";
import { formatCurrency } from "../../utils/currency";
import {
  getAcceptedAndRefundedServices,
  processService,
} from "../../utils/service-helpers";

const InvoiceCreator = () => {
  const { t } = useTranslation();

  const theme = useTheme();
  const [lastInvoiceData, setLastInvoiceData] = useState({});
  const [partners, setPartners] = useState([]);
  const [partnersSelect, setPartnersSelect] = useState(0);
  const [partnersSelectData, setPartnersSelectData] = useState(null);

  const [partnersLoading, setPartnersLoading] = useState(false);
  const [checkedOrders, setCheckedOrders] = React.useState([]); // checked from partner or client
  const [customItems, setCustomItems] = useState([]); // the ones you add manually
  const [items, setItems] = useState([]); // checkedOrders + customItems
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [paymentInfo, setPaymentInfo] = useState("");
  const [terms, setTerms] = useState("");
  const [refId, setRefId] = useState("");
  const [estimateTo, setEstimateTo] = useState("");

  const [billingTo, setBillingTo] = useState("");
  const [billingFrom, setBillingFrom] = useState("");
  const [invoiceTo, setInvoiceTo] = useState("customer");
  const [invoice, setInvoice] = useState({});
  const [mediaDialogOpen, setMediaDialogOpen] = useState(false);
  const [invoiceLogo, setInvoiceLogo] = useState({});
  const [editCustomer, setEditCustomer] = useState(false);
  const [organization, setOrganization] = useState(null);

  const [customerSelect, setCustomerSelect] = useState(null);
  const refIdFieldRef = React.useRef();

  //Calendar Popover

  const [anchorEl, setAnchorEl] = React.useState(null);
  const calendarOpen = Boolean(anchorEl);
  const calendarId = calendarOpen ? "calendar-popover" : undefined;
  const [calendarDate, setCalendarDate] = useState(new Date());

  // Taxes
  const [percentTax, setPercentTax] = React.useState(false);
  const [taxIncluded, setTaxIncluded] = React.useState(true);
  const [taxField, setTaxField] = React.useState(21);

  useEffect(async () => {
    try {
      setPartnersLoading(true);
      const partnersResponse = await axios.get(`organizations/partners`);
      if (partnersResponse.status == 200) {
        setPartners(
          partnersResponse.data.sort((a, b) => a.name.localeCompare(b.name))
        );
      }
      setPartnersLoading(false);
      const lastInvoiceResponse = await axios.get(`invoices/last`);
      if (lastInvoiceResponse.data) {
        setRefId(lastInvoiceResponse.data.refId + 1);
        setInvoiceLogo(lastInvoiceResponse.data.file);
        setLastInvoiceData(lastInvoiceResponse.data);
      }
      const organizationResponse = await axios.get(`organizations/`);
      const orgData = organizationResponse.data;
      setOrganization(orgData);
      const fullAddress = `${orgData.address} ${orgData.city} ${
        orgData.state ? `(${orgData.state})` : ""
      }`;
      const taxIdentifier = orgData.taxIdentifier
        ? `${t("Tax identifier")}: ${orgData.taxIdentifier}`
        : "";
      setBillingFrom(`${orgData.legalName}\n${fullAddress}\n${taxIdentifier}`);
    } catch (err) {
      console.log(err);
      setPartnersLoading(false);
    }
  }, []);
  useEffect(() => {
    setCheckedOrders([]);
  }, [partnersSelect, invoiceTo, customerSelect]);

  useEffect(() => {
    // setItems( [checked])
    if (checkedOrders.length > 0) {
      let checkedItemsFromOrders = [];
      checkedOrders.forEach((order) => {
        let items = servicesToItems(order, order.services);
        checkedItemsFromOrders.push(...items);
      });
      setItems([...checkedItemsFromOrders, ...customItems]);
      const picked = (({ brand, model, services }) => ({
        brand,
        model,
        services,
      }))(checkedOrders[0]);
    } else {
      setItems([]);
    }

    // console.log(checkedItemsFromOrders);
  }, [checkedOrders, customItems]);

  const handleAlignment = (e) => {
    setInvoiceTo(e.target.value);
  };

  const generate = async () => {
    try {
      if (items.length === 0) {
        enqueueSnackbar("There are no items in invoice", { variant: "error" });
        return;
      }
      if (!refId) {
        enqueueSnackbar("Reference Number is empty. ", { variant: "error" });
        refIdFieldRef.current.focus();
        return;
      }
      const isSimpleInvoice =
        invoiceTo === "customer" && !customerSelect.taxIdentifier;
      const isA4Invoice =
        (invoiceTo === "customer" && customerSelect.taxIdentifier) ||
        (invoiceTo === "partner" && partnersSelect);
      if (isA4Invoice) {
        if (billingFrom.length < 1) {
          return;
        }
      }
      const invoiceData = {
        refId: refId,
        fileId: invoiceLogo?.id,
        billingFrom: billingFrom,
        billingTo: billingTo,
        invoiceTo: invoiceTo,
        customerSelect: customerSelect,
        partnersSelect: partnersSelect,
        paymentInfo: paymentInfo,
        terms: terms,
        items: items,
        date: calendarDate.toISOString(),
        taxIncluded: taxIncluded,
        taxAmount: parseFloat(taxField),
      };
      setInvoice({ ...invoiceData });

      // const obj = {
      //     estimate: {
      //         refId: refId,
      //         estimateTo: estimateTo,
      //         services: customItems,
      //         paymentInfo: paymentInfo,
      //         termsOrNote: terms,
      //     },
      // };
      const newInvoiceResp = await axios.post(
        `/invoices/${invoiceTo}`,
        invoiceData
      );
      if (newInvoiceResp?.status === 201) {
        enqueueSnackbar(`Invoice created succesfully!`, { variant: "success" });
        setRefId(parseInt(newInvoiceResp.data.refId) + 1);
        setItems([]);
        setCheckedOrders([]);

        // To force a refetch on the side menu
        const currentPartnerSelect = partnersSelect;
        setPartnersSelect(0);
        setPartnersSelect(currentPartnerSelect);
        const currentCustomerSelect = customerSelect;
        setCustomerSelect(null);
        setCustomerSelect(currentCustomerSelect);

        //Reset the form
        setPaymentInfo("");
        setTerms("");
        setBillingTo("");
        setBillingFrom("");
        setTaxField(21);
        setTaxIncluded(true);
        setCalendarDate(new Date());
      }
    } catch (err) {
      if (err.response.data.message) {
        enqueueSnackbar(err.response.data.message, { variant: "error" });
      } else {
        enqueueSnackbar("Something went wrong!", { variant: "error" });
      }
    }
  };

  const partnersSelectHandler = async (e) => {
    const value = e.target.value;
    const prevState = partnersSelect;
    try {
      const partnerSelected = partners.find((partner) => partner.id == value);
      setPartnersSelectData(partnerSelected);
      if (partnerSelected) {
        const data = `${partnerSelected.name},\n${partnerSelected.address},\n NIE | NIF:${partnerSelected.taxIdentifier}`;
      }
      setPartnersSelect(null);
      setPartnersSelect(value);
    } catch (err) {
      console.log(err);
    }
  };

  const creator = () => {
    if (invoiceTo === "partner" && partnersSelect) {
      return (
        <A4Creator
          invoiceTo={invoiceTo}
          customer={partnersSelectData}
          lastInvoiceData={lastInvoiceData}
          setBillingFrom={setBillingFrom}
          billingFrom={billingFrom}
          setBillingTo={setBillingTo}
          billingTo={billingTo}
          invoiceLogo={invoiceLogo}
          setMediaDialogOpen={setMediaDialogOpen}
          checkedOrders={checkedOrders}
          setRefId={setRefId}
          refId={refId}
          setPaymentInfo={setPaymentInfo}
          paymentInfo={paymentInfo}
          terms={terms}
          setTerms={setTerms}
          calendarDate={calendarDate}
          setCalendarDate={setCalendarDate}
          setTaxIncluded={setTaxIncluded}
          taxIncluded={taxIncluded}
        />
      );
    }
    if (
      invoiceTo === "customer" &&
      customerSelect &&
      customerSelect.taxIdentifier
    ) {
      return (
        <A4Creator
          invoiceTo={invoiceTo}
          customer={customerSelect}
          lastInvoiceData={lastInvoiceData}
          setBillingFrom={setBillingFrom}
          billingFrom={billingFrom}
          setBillingTo={setBillingTo}
          billingTo={billingTo}
          invoiceLogo={invoiceLogo}
          setMediaDialogOpen={setMediaDialogOpen}
          checkedOrders={checkedOrders}
          setRefId={setRefId}
          refId={refId}
          setPaymentInfo={setPaymentInfo}
          paymentInfo={paymentInfo}
          terms={terms}
          setTerms={setTerms}
          calendarDate={calendarDate}
          setCalendarDate={setCalendarDate}
          setTaxIncluded={setTaxIncluded}
          taxIncluded={taxIncluded}
        />
      );
    }
    if (
      invoiceTo === "customer" &&
      customerSelect &&
      !customerSelect.taxIdentifier
    ) {
      return (
        <SimplifiedInvoiceCreator
          invoiceTo={invoiceTo}
          organization={organization}
          invoiceLogo={invoiceLogo}
          setMediaDialogOpen={setMediaDialogOpen}
          checkedOrders={checkedOrders}
          setRefId={setRefId}
          refId={refId}
          terms={terms}
          setTerms={setTerms}
          setBillingFrom={setBillingFrom}
          billingFrom={billingFrom}
        />
      );
    }
    return null;
  };
  const validateGenreateButton = () => {
    const haveEnoughItems = items.length > 0;
    const isToCustomer = invoiceTo === "customer";
    const isToPartner = invoiceTo === "partner";
    const hasBillingFrom = billingFrom?.length > 1;
    const hasBillingTo = billingTo?.length > 1;

    const isSimpleInvoice = isToCustomer && !customerSelect?.taxIdentifier;
    const isA4Invoice =
      (invoiceTo === "customer" && customerSelect?.taxIdentifier) ||
      (invoiceTo === "partner" && partnersSelect);

    if (isA4Invoice && haveEnoughItems && hasBillingFrom && hasBillingTo) {
      return false;
    }
    if (isSimpleInvoice && haveEnoughItems) {
      return false;
    }

    return true;
  };

  useEffect(() => {
    // console.log(customerSelect);
  }, [customerSelect]);

  useEffect(() => {
    // console.log(invoice);
  }, [invoice]);

  return (
    <>
      <Box className={"container mainContainer docsContainer "}>
        <div className="headerPush"></div>
        <div className="content">
          <Box className="ticketContainer" flex={"1 0 auto"}>
            <Stack
              justifyContent={"center"}
              alignItems={"center"}
              py={5}
              spacing={5}
            >
              <Paper sx={{ width: "400px", p: "25px" }}>
                <Stack
                  direction={"column"}
                  spacing={2}
                  alignItems={"start"}
                  justifyContent={"start"}
                >
                  <StyledToggleButtonGroup
                    value={invoiceTo}
                    exclusive
                    onChange={handleAlignment}
                    aria-label="text alignment"
                    sx={{ backgroundColor: "#F1F9FD" }}
                  >
                    <ToggleButton value="customer" aria-label="left aligned">
                      {t("Customer")}
                    </ToggleButton>
                    <ToggleButton value="partner" aria-label="centered">
                      {t("Partner")}
                    </ToggleButton>
                  </StyledToggleButtonGroup>
                  {invoiceTo == "customer" ? (
                    <>
                      <CustomersSearch
                        state={customerSelect}
                        onOptionSelect={setCustomerSelect}
                      />
                      {customerSelect ? (
                        <Button onClick={() => setEditCustomer(true)}>
                          Edit Customer
                        </Button>
                      ) : null}
                    </>
                  ) : (
                    <FormControl fullWidth variant="filled" size="small">
                      <InputLabel>Partner</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Payment method"
                        value={partnersSelect}
                        onChange={partnersSelectHandler}
                        endAdornment={
                          partnersLoading ? (
                            <Box sx={{ display: "flex", marginRight: "25px" }}>
                              <CircularProgress color="inherit" />
                            </Box>
                          ) : null
                        }
                      >
                        <MenuItem value={0}>Select a partner</MenuItem>
                        {partners.map((partner) => (
                          <MenuItem value={partner.id} key={partner.id}>
                            {partner.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                  <Typography color={theme.palette.secondary.main}>
                    {t(
                      "Note: If the customer doesn't have tax information it will generate a simplified invoice."
                    )}
                  </Typography>
                  <Button
                    fullWidth
                    disabled={validateGenreateButton()}
                    variant="contained"
                    onClick={generate}
                  >
                    {t("Generate")}
                  </Button>
                </Stack>
              </Paper>
              {creator()}
            </Stack>
          </Box>
          {invoiceTo === "partner" && partnersSelect ? (
            <SideMenuPartners
              partnerId={partnersSelect}
              checkedOrders={checkedOrders}
              setCheckedOrders={setCheckedOrders}
            />
          ) : null}
          {invoiceTo === "customer" && customerSelect ? (
            <SideMenuCustomers
              customer={customerSelect}
              checkedOrders={checkedOrders}
              setCheckedOrders={setCheckedOrders}
            />
          ) : null}
        </div>
      </Box>
      <UploadFilesDialog
        open={mediaDialogOpen}
        onClose={() => setMediaDialogOpen(false)}
        onSelect={(link) => setInvoiceLogo(link)}
      />
      <EditCustomerDialog
        onEdit={(customer) => setCustomerSelect(customer)}
        dialogOpen={editCustomer}
        item={customerSelect}
        handleDialogClose={() => setEditCustomer(false)}
      />
    </>
  );
};

const SideMenuPartners = React.memo(
  ({ partnerId, checkedOrders, setCheckedOrders }) => {
    const { t } = useTranslation();
    const [partnerOrdersLoading, setPartnerOrdersLoading] = useState(false);
    const [partnerOrders, setPartnerOrders] = useState([]);
    const currency = useSelector((state) => state.auth.currency);

    useEffect(() => {
      getOrders();
    }, [partnerId]);

    const getOrders = async () => {
      try {
        setPartnerOrdersLoading(true);
        const resp = await axios.get(`repair-orders/fromPartner/${partnerId}`);
        console.log(resp.data);
        setPartnerOrders(resp.data);
        setPartnerOrdersLoading(false);
      } catch (err) {
        setPartnerOrdersLoading(false);
        console.log(err);
      }
    };

    if (checkedOrders === undefined || setCheckedOrders === undefined) {
      // console.log("Mandatory arguments missing");
      return null;
    }

    const handleToggle = (value) => () => {
      const currentIndex = checkedOrders.findIndex(
        (checkedOrder) => checkedOrder.id == value.id
      );
      const newChecked = [...checkedOrders];

      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }

      setCheckedOrders(newChecked);
    };
    const orders = partnerOrders.filter((order) => {
      let totalPayments = 0;

      // No resolution
      if (order.resolutionTypeId === null) {
        // console.log(order)
        return false;
      }
      // Orders we care about, acccepted, refunded, and not invoiced
      const acceptedAndRefundedServices = getAcceptedAndRefundedServices(
        order.services
      ).filter((service) => !service.invoiceItem);

      const acceptedAndRefundedServicesProcessed =
        acceptedAndRefundedServices.reduce((acc, service) => {
          const processed = processService(service);
          return (acc +=
            processed.unitPrice * processed.quantity - processed.discount);
        }, 0);

      if (acceptedAndRefundedServices.length == 0) {
        return false;
      }

      // Total Payments
      totalPayments = order.repairPayments?.reduce((acc, payment) => {
        return acc + parseFloat(payment.amount);
      }, 0);

      // Already paid
      if (acceptedAndRefundedServicesProcessed == totalPayments) {
        return false;
      }

      order.services = acceptedAndRefundedServices;

      return true;
    });

    return (
      <Box
        className="invoiceNavContainer sectionGroup"
        borderLeft={"1px solid darkGrey"}
        borderBottom={"1px solid darkGrey"}
        flex={"1 0 auto"}
        bgcolor={"white"}
      >
        <Box className=" sectionGroup">
          <Section sx={{ width: "100%" }}>
            <Typography variant="h4">{t("Partner entries")}</Typography>
            <Typography variant="p">
              {t(
                "Showing all accepted, refunded, unpaid and not invoiced services"
              )}
              .
            </Typography>
          </Section>
        </Box>
        <Box className="sectionGroup">
          <Section>
            {partnerOrdersLoading ? (
              <Typography>Loading...</Typography>
            ) : (
              <List
                sx={{ width: "100%", bgcolor: "background.paper" }}
                dense
                aria-labelledby="nested-list-subheader"
                subheader={
                  <ListSubheader component="div" id="nested-list-subheader">
                    {orders.length} Orders
                  </ListSubheader>
                }
              >
                {orders.map((order) => (
                  <div key={order.id}>
                    {/* <ListItemIcon>
                                                <InboxIcon />
                                            </ListItemIcon> */}

                    <ListItemButton
                      onClick={handleToggle(order)}
                      sx={{
                        backgroundColor: "lightBlue.main",
                        borderRadius: "5px",
                      }}
                    >
                      <ListItemIcon sx={{ minWidth: "20px" }}>
                        <Checkbox
                          edge="start"
                          tabIndex={-1}
                          disableRipple
                          checked={
                            checkedOrders.find(
                              (checkedOrder) => checkedOrder.id == order.id
                            ) !== undefined
                          }
                          inputProps={{ "aria-labelledby": "labelId" }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        secondary={t(order?.repairOrderStatus?.name)}
                        primary={
                          <Typography variant="h5">
                            <Box
                              sx={{ color: "primary.main" }}
                              component={"span"}
                            >
                              {order.outsourcedFrom.organization.id}#
                              {order.outsourcedFrom.organizationCounter}
                            </Box>{" "}
                            {order.brand} {order.model}
                          </Typography>
                        }
                      />
                    </ListItemButton>

                    {order.services.map((service) => (
                      // <Box pl={'50px'}>
                      //     <ServiceItemListShort service={service} />
                      // </Box>
                      <List dense sx={{ pl: "30px" }} key={service.id}>
                        <ListItem
                          sx={{
                            border: "1px solid lightGrey",
                            borderRadius: "15px",
                          }}
                        >
                          <ListItemIcon>
                            <HandymanOutlined />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Stack
                                direction={"row"}
                                justifyContent={"space-between"}
                              >
                                <div>{service.name}</div>
                                <Typography variant="h6">
                                  {formatCurrency(
                                    service.unitPrice * service.quantity -
                                      service.discount,
                                    currency
                                  )}
                                </Typography>
                              </Stack>
                            }
                            secondary={`(${service.unitPrice} x${
                              service.quantity
                            }) ${
                              service.discount > 0 ? `-${service.discount}` : ""
                            }`}
                          />
                        </ListItem>
                        <Divider component="li" />
                      </List>
                    ))}
                    <Divider component="li" />
                  </div>
                ))}
              </List>
            )}
          </Section>
        </Box>
      </Box>
    );
  }
);

export default InvoiceCreator;

import React, { createContext, useCallback, useEffect, useRef } from "react";

// Create the context
export const SoundContext = createContext();

// Create the provider component
export function SoundProvider({ children }) {
  const audioContext = useRef(null);
  const isActive = useRef(false);

  // Initialize the AudioContext when the component mounts
  useEffect(() => {
    audioContext.current = new AudioContext();
  }, []);

  const playSound = (soundFile) => {
    if (!audioContext.current) return;

    fetch(soundFile)
      .then((response) => response.arrayBuffer())
      .then((arrayBuffer) => audioContext.current.decodeAudioData(arrayBuffer))
      .then((decodedBuffer) => {
        const source = audioContext.current.createBufferSource();
        source.buffer = decodedBuffer;
        source.connect(audioContext.current.destination);
        source.start(0);
      })
      .catch((e) => console.error("Error loading audio file: ", e));
  };

  // Function to unlock the sound
  const unlockSound = useCallback(() => {
    if (!audioContext.current || isActive.current) return;
    const source = audioContext.current.createBufferSource();
    source.buffer = audioContext.current.createBuffer(1, 1, 22050);
    source.connect(audioContext.current.destination);
    source.start(0);
    isActive.current = true;
  }, []);

  useEffect(() => {
    const handleClick = () => {
      unlockSound();
    };

    window.addEventListener("click", handleClick);

    return () => {
      window.removeEventListener("click", handleClick);
    };
  }, [unlockSound]);

  return (
    <SoundContext.Provider value={{ playSound, unlockSound }}>
      {children}
    </SoundContext.Provider>
  );
}

import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import { bulkMarkAsPaid } from "api/repair-orders";

const useBulkMarkAsPaid = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(bulkMarkAsPaid, {
    onMutate: async ({ repairOrderIds, paymentMethod }) => {
      //   console.log(`Mutation: ${repairOrderIds}, ${paymentMethod}`);
      // Cancel any outgoing refetches
      await queryClient.cancelQueries({
        queryKey: ["repairOrders"],
        exact: false,
      });
      // const newPayment = {
      //     paymentMethod: paymentMethod,
      //     createdAt: dayjs().toISOString(),
      //     updatedAt: dayjs().toISOString(),

      // };

      // Snapshot the previous value
      const previousOrders = queryClient
        .getQueriesData({ queryKey: ["repairOrders"], exact: false })
        .filter(([queryKey, oldData]) => oldData !== undefined);

      // Optimistically update all matching queries

      previousOrders.forEach(([queryKey, oldData]) => {
        if (!oldData) {
          console.warn(`No data for queryKey: ${queryKey}`);
          return;
        }

        const newData = oldData.map((o) => {
          if (repairOrderIds.includes(o.id)) {
            return {
              ...o,
              isChangingStatus: true,
              paid: true,
            };
          } else {
            return o;
          }
        });

        queryClient.setQueryData(queryKey, newData);
      });
      return { previousOrders };
    },

    onSuccess: (data, variables) => {
      const updatedOrders = data;

      const queries = queryClient
        .getQueriesData({ queryKey: ["repairOrders"], exact: false })
        .filter(([_, oldData]) => oldData !== undefined);

      queries.forEach(([queryKey, oldData]) => {
        if (!oldData) {
          console.warn(`No data for queryKey: ${queryKey}`);
          return;
        }

        const newData = oldData.map((order) => {
          const updatedOrder = updatedOrders.find((uo) => uo.id === order.id);
          if (updatedOrder) {
            return {
              ...order,
              repairPayments: updatedOrder.repairPayments,
              isChangingStatus: false,
              paid: false,
            };
          }
          return order;
        });

        queryClient.setQueryData(queryKey, newData);
      });
    },
    onError: (err, variables, context) => {
      context.previousOrders.forEach(([queryKey, oldData]) => {
        queryClient.setQueryData(queryKey, oldData);
      });
      console.error(err?.response?.data?.message || "Error updating status");
      enqueueSnackbar(`Failed to mark as paid`, { variant: "error" });
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["repairOrders"],
        exact: false,
      });
    },
  });
};

export default useBulkMarkAsPaid;
